import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
import { ICollection } from 'app/store/DMSCollections/DMSCollections.models';
import { IDocument } from './DMSDocuments.models';
// Selectors
import { selectEntities as selectCollectionEntities } from 'app/store/DMSCollections/DMSCollections.selectors';
// Services
import PageService from 'app/services/PageService';
import { sortByName } from 'app/utilities/SortBy';

export const selectDocument = (state:RootState) => state[Reducers.DMSDocuments].document;
export const selectDocumentsIds = (state:RootState) => state[Reducers.DMSDocuments].documents.ids;
export const selectDocumentsEntities = (state:RootState) => state[Reducers.DMSDocuments].documents.entities;
export const selectDocumentsDates = (state:RootState) => state[Reducers.DMSDocuments].documents.dates;
export const selectLoading = (state:RootState) => state[Reducers.DMSDocuments].loading;

export const selectDocuments = createSelector(
  [
    selectDocumentsIds,
    selectDocumentsEntities
  ],
  ( ids:number[] | null, entities:Record<number, IDocument> ) => {
    if ( !ids ) return null;
    return ids.map((id:number) => entities[id]);
  }
);

export const selectDocumentEntity = createSelector(
  [
    selectDocumentsEntities,
    (_, props:{ documentId:number }) => props
  ],
  (entities:Record<number, IDocument>, { documentId }) => entities[documentId]
);

// `selectCustomDocumentIds`
// Used for `ReportPresetFormDialog` to build report inside case
export const selectCustomDocumentIds = createSelector(
  [ selectDocuments ],
  ( documents:IDocument[] | null ) => {
    if ( !documents ) return null;
    return documents
      .filter(document => document.fileFormat === 'custom')
      .map(document => document.id)
  }
);

export const selectDocumentsAsOptions = createSelector(
  [
    selectCollectionEntities,
    selectDocuments
  ],
  (collectionEntities:Record<number, ICollection>, documents:IDocument[] | null) => {
    if ( !documents ) return [];
    return documents
      .filter((document:IDocument) => !document.deleted && document.processing.status === 'ready')
      .map((document:IDocument) => ({
        id: document.id,
        name: document.name,
        props: {
          collection: collectionEntities[document.collectionId],
          numberOfPages: document.numberOfPages || 0
        }
      }))
      .sort((a, b) => {
        const aCollection = a.props.collection;
        const bCollection = b.props.collection;
        return sortByName(aCollection, bCollection, 'name');
      });
  }
);

export const selectPageDates = createSelector(
  [
    selectDocumentsDates,
    (_, props:{ documentId:number, pageNum:number }) => props
  ],
  ( dates:Record<string, string[]>, { documentId, pageNum } ) => {
    const pageId = PageService.toPageId(documentId, pageNum);
    return dates[pageId];
  }
);

export const selectDocumentsForEmbedding = createSelector(
  [ selectDocuments ],
  ( documents:IDocument[] | null ) => {
    if ( !documents ) return null;
    return documents
      .filter((document:IDocument) => !document.deleted && document.processing.status === 'ready')
      .filter((document:IDocument) => document.ocred && document.fileFormat === 'pdf')
  }
);

// AICHat
export const selectAIChatEligibleDocumentIds = createSelector(
  [ selectDocumentsForEmbedding ],
  ( documents:IDocument[] | null ) => {
    if ( !documents ) return null;
    const documentIds:number[] = []
    for ( let document of documents ){
      if ( !document.annotations || !document.annotations.length ) continue;
      for ( let annotation of document.annotations ){
        if ( annotation.name === 'aiChatEligible' && annotation.value === 'true' ){
          if ( !documentIds.includes(document.id) ){
            documentIds.push(document.id);
            break;
          }
        }
      }
    }
    return documentIds;
  }
);

export const selectAIChatEligibleDocumentsAsOptions = createSelector(
  [
    selectCollectionEntities,
    selectDocumentsEntities,
    selectAIChatEligibleDocumentIds
  ],
  ( collectionEntities:Record<number, ICollection>, entities:Record<number, IDocument>, documentIds:number[] | null ) => {
    if ( !documentIds ) return null;
    return documentIds
      .map((id:number) => entities[id])
      .map((document:IDocument) => ({
        id: document.id,
        name: document.name,
        props: {
          collection: collectionEntities[document.collectionId],
          numberOfPages: document.numberOfPages || 0
        }
      }))
      .sort((a, b) => {
        const aCollection = a.props.collection;
        const bCollection = b.props.collection;
        return sortByName(aCollection, bCollection, 'name');
      });
  }
);

export const selectHasAIChatEligibleDocuments = createSelector(
  [ selectAIChatEligibleDocumentIds ],
  ( documentIds:number[] | null ) => {
    return documentIds ? Boolean(documentIds.length) : false;
  }
);

export const selectHasAnyDocumentOcred = createSelector(
  [ selectDocuments ],
  ( documents:IDocument[] | null ) => {
    if ( !documents ) return null;
    return documents
      .filter((document:IDocument) => !document.deleted && document.processing.status === 'ready')
      .some((document:IDocument) => document.ocred)
  }
);
