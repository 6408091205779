import { FC, Fragment } from 'react';
// Models
import IManagementAccount from 'app/models/ManagementAccount';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { suspendAccount, disableAccount, activateAccount, deleteAccount } from 'app/store/AccountsManagement/AccountsManagement.async';
// Selectors
import { selectLoading } from 'app/store/AccountsManagement/AccountsManagement.selectors';
// Mui
import { Theme, Paper, Box, Typography, Divider } from '@mui/material';
// Icons
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Components
import { IconButton } from 'app/components/Mui';
import { InfoBlock, MailTo } from 'app/components/Utilities';
// Hooks
import useMenu from 'app/hooks/useMenu';
import useToggle from 'app/hooks/useToggle';
// 
import AccountEditFormDialog from './AccountEditFormDialog';
import AccountAnalyticsDialog from './AccountAnalyticsDialog';
// Utilities
import { getAccountStatusLabel } from 'app/utilities/Utilities';
// i18next
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  account: IManagementAccount
}

const AccountsListItem:FC<Props> = ({
  // Props
  account
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading:boolean = useAppSelector(selectLoading);

  const { Menu, MenuItem, openMenu } = useMenu();
  const { open:openEditDialog, toggle:toggleEditDialog } = useToggle();
  const { open:openAnalyticsDialog, toggle:toggleAnalyticsDialog } = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const handleSuspendAccount = () => {
    dispatch(suspendAccount(account.id));
  }
  const handleDisableAccount = () => {
    dispatch(disableAccount(account.id));
  }
  const handleActivateAccount = () => {
    dispatch(activateAccount(account.id));
  }
  const handleConfirm = () => {
    dispatch(deleteAccount(account.id));
  }

  const address = [];
  if ( account.address?.city ) address.push(account.address.city);
  if ( account.address?.line ) address.push(account.address.line);
  if ( account.address?.state ) address.push(account.address.state);
  if ( account.address?.zipCode ) address.push(account.address.zipCode);

  return (
    <Fragment>
      <Paper
        sx={{ display: 'flex', p: 4, '& + &': { mt: 4 } }}
        variant="outlined"
      >
        <Box sx={{ flexGrow: 1, pr: 2 }}>
          <Typography
            sx={{
              color: (theme:Theme) => account.status === 'disabled'
                    ? theme.palette.error.main
                    : account.status === 'suspended'
                      ? theme.palette.warning.main
                      : theme.palette.info.main
              ,
              textTransform: 'capitalize'
            }}
            variant="caption"
          >{getAccountStatusLabel(account.status)}</Typography>
          <Typography variant="subtitle1">{account.company}</Typography>
          {address.length ? (
            <Typography variant="body2" color="CaptionText">{address.join(', ')}</Typography>
          ) : null}
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: 'flex', gap: 4 }}>
            <InfoBlock label={t('labels.email')} value={<MailTo email={account.email} />} />
            <InfoBlock label={t('labels.phone')} value={account.phone} />
          </Box>
        </Box>
        <Box sx={{ mt: -2, mr: -2 }}>
          <IconButton
            name={`Account dropdown ${account.id}`}
            aria-label="Account item dropdown button"
            onClick={openMenu}
          ><MoreVertIcon /></IconButton>
          <Menu>
            <MenuItem
              name={`edit ${account.id}`}
              onClick={toggleEditDialog}
            >{t('labels.edit')}</MenuItem>
            <MenuItem
              name={`Usage report ${account.id}`}
              onClick={toggleAnalyticsDialog}
            >{t('labels.usageReport')}</MenuItem>
            {account.status === 'active' ? (
              [
                <MenuItem
                  key={`account-item-${account.id}-dropdown-suspend`}
                  name={`suspend-${account.id}`}
                  onClick={handleSuspendAccount}
                >{t('pages.accountManagerPages.companyAccountsPage.suspend')}</MenuItem>,
                <MenuItem
                  key={`account-item-${account.id}-dropdown-disable`}
                  name={`disable-${account.id}`}
                  onClick={handleDisableAccount}
                >{t('pages.accountManagerPages.companyAccountsPage.disable')}</MenuItem>
              ]
            ) : (
              <MenuItem
                name={`activate-${account.id}`}
                onClick={handleActivateAccount}
              >{t('pages.accountManagerPages.companyAccountsPage.activate')}</MenuItem>
            )}
            <MenuItem
              sx={{ color: (theme:Theme) => theme.palette.error.main }}
              name={`delete-${account.id}`}
              onClick={toggleConfirmationDialog}
            >{t('labels.delete')}</MenuItem>
          </Menu>
        </Box>
      </Paper>
      {/* Edit account form */}
      {openEditDialog ? (
        <AccountEditFormDialog
          open={openEditDialog}
          onClose={toggleEditDialog}
          account={account}
        />
      ) : null}
      {/* Account analytics */}
      {openAnalyticsDialog ? (
        <AccountAnalyticsDialog
          open={openAnalyticsDialog}
          onClose={toggleAnalyticsDialog}
          accountId={account.id}
        />
      ) : null}
      {/* Delete account */}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          onConfirm={handleConfirm}
          title={t('pages.accountManagerPages.companyAccountsPage.deleteCompanyAccount')}
          content={(
            <Trans
              t={t} i18nKey="pages.accountManagerPages.companyAccountsPage.deleteCompanyAccountConfirmation"
              components={{ strong: <strong /> }}
              values={{ companyAccount: account.company }}
            />
          )}
          loading={loading}
        />
      ) : null}
    </Fragment>
  )
}

export default AccountsListItem;
