enum BodyErrors {
  MfaRequired = 'mfaRequired',
  AccountRequired = 'accountRequired',
  PasswordChangeRequired = 'passwordChangeRequired',
  UsernamePasswordInvalid = 'usernamePasswordInvalid',
  ChallengeCodeInvalid = 'challengeCodeInvalid',
  UserLocked = 'userLocked',
  UserDisabled = 'userDisabled',
  AccountDisabled = 'accountDisabled'
};

export default BodyErrors;