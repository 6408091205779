import { FC, Fragment, useState, useEffect, useMemo, useCallback, ChangeEvent } from 'react';
import { debounce } from 'throttle-debounce';
// Types
import CaseStatuses from 'app/types/CaseStatuses';
// Models
import IOption from 'app/models/Option';
import IEpisodeAuthor from 'app/models/EpisodeAuthor';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getInsuranceCases } from 'app/store/Cases/Cases.async';
// Selectors
import { selectCaseOptions, selectCasesParams } from 'app/store/Cases/Cases.selectors';
import { selectParams } from 'app/store/Episodes/Episodes.selectors';
// Mui
import {
  Box,
  Autocomplete, TextField, CircularProgress,
} from '@mui/material';
// Components
import { Select } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';
import { EpisodesActions } from 'app/store/Episodes/Episodes.slice';

type Props = {
  author: IEpisodeAuthor;
}

const EpisodesFilter:FC<Props> = ({
  // Props
  author
}) => {
  const { t } = useTranslation('common');

  const dispatch = useAppDispatch();
  // State
  const caseOptions = useAppSelector(selectCaseOptions);
  const casesParams = useAppSelector(selectCasesParams);
  const episodesParams = useAppSelector(selectParams) as any;

  const [ insuranceCase, setInsuranceCase ] = useState(null);
  const [ type, setType ] = useState('');

  // const caseOptions = useMemo(() => {
  //   if ( !casesListOptions ) return [];
  //   return casesListOptions;
  // }, [casesListOptions]);

  const typeOptions = useMemo(() => {
    return author.episodeTypes || [];
  }, [author]);

  const workspaceOptions = [
    { id: 'all', name: t('labels.all') },
    { id: 'true', name: t('pages.adminPages.authorRegistryPage.isInWorkspace') },
    { id: 'false', name: t('labels.notInWorkspace') }
  ];

  const debounceInsuranceCaseValue = useCallback(debounce(500, (search:string) => {
    dispatch(getInsuranceCases({
      ...casesParams,
      search,
      status: `${CaseStatuses.Open}, ${CaseStatuses.Archived}`
    }));
  }), []);

  const debouncePrams = useCallback(debounce(500, (field:keyof typeof episodesParams, value:any) => {
    dispatch(EpisodesActions.setParams({ [field]: value, offset: 0 }))
  }), []);

  useEffect(() => {
    dispatch(getInsuranceCases({
      ...casesParams,
      status: `${CaseStatuses.Open}, ${CaseStatuses.Archived}`
    }));
    // eslint-disable-next-line
  }, []);

  const handleChange = (_:any, nextValue:any) => {
    setInsuranceCase(nextValue);

    console.log(nextValue);

    debouncePrams('insuranceCaseId', nextValue ? nextValue.id : null );
  }

  const handleChangeInput = (_:any, value:string) => {
    debounceInsuranceCaseValue(value);
  }

  const handleTypeChange = (_:any, nextValue:any) => {
    setType(nextValue);
    debouncePrams('type', nextValue);
  }

  const handleTypeInputChange = (_:any, nextValue:string) => {
    setType(nextValue);
    debouncePrams('type', nextValue);
  }

  const handleInWorkspaceChange = (event:ChangeEvent<HTMLInputElement>) => {
    debouncePrams('page.inWorkspace', event.target.value);
  }

  const initialCaseOptions = caseOptions || [];

  const casesLoading = !initialCaseOptions;

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
      <Autocomplete
        value={insuranceCase}
        onChange={handleChange}
        onInputChange={handleChangeInput}
        options={caseOptions || []}
        loading={casesLoading}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('pages.adminPages.authorRegistryPage.insuranceCase')}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {casesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
            helperText={t('pages.adminPages.authorRegistryPage.insuranceCaseHelperText')}
            size="small"
            margin="none"
            fullWidth
          />
        )}
        renderOption={(props, option:IOption) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
      <Autocomplete
        value={type}
        onChange={handleTypeChange}
        onInputChange={handleTypeInputChange}
        options={typeOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('labels.type')}
            size="small"
            margin="none"
            fullWidth
          />
        )}
        freeSolo
        fullWidth
      />
      <Select
        label={t('pages.adminPages.authorRegistryPage.inWorkspace')} name="inWorkspace" value={episodesParams['page.inWorkspace']}
        onChange={handleInWorkspaceChange}
        options={workspaceOptions}
        size="small"
        margin="none"
      />
    </Box>
  )
}

export default EpisodesFilter;
