import { FC, Fragment, memo, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List, areEqual } from 'react-window';
import { useTranslation } from 'react-i18next';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectAcceptMimeTypes } from 'app/store/DMSCollections/DMSCollections.selectors';
// Mui
import { Theme, Box, FormHelperText } from '@mui/material';
// Components
import { Message } from 'app/components/Utilities';
// 
import { useUploadFilesContext } from './UploadFiles.provider';
import FilesPicker from './FilesPicker';
import FilesUploadProgress from './FilesUploadProgress';
import FilesUploaderError from './FilesUploaderError';
import FieldItem from './FieldItem';

type Props = {
  loading: boolean;
  uploadedFilesLength: number;
}

const FilesUploader:FC<Props> = ({
  // Props
  loading, uploadedFilesLength
}) => {
  const { t } = useTranslation('common');

  // State
  const acceptMimeTypes = useAppSelector(selectAcceptMimeTypes);

  const { formState:{ errors }, setValue } = useFormContext();

  const { fileItems, onAddFileItem, sortedFileItems } = useUploadFilesContext();

  useEffect(() => {
    setValue('files', fileItems);
    // eslint-disable-next-line
  }, [fileItems]);

  const handleDrop = useCallback((files:File[]) => {
    for ( let file of files ){
      onAddFileItem(file);
    }
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: acceptMimeTypes as any,
    onDrop: handleDrop,
    noClick: true
  });

  return (
    <Fragment>
      {/* @ts-ignore */}
      <input {...getInputProps()} />

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FilesPicker onOpen={open} onDrop={handleDrop} />
        <Box sx={{ flexGrow: 1 }} />
        <FilesUploadProgress loading={loading} uploadedFilesLength={uploadedFilesLength} />
      </Box>

      <FilesUploaderError />

      <Box
        {...getRootProps()}
        sx={{
          flexGrow: 1,
          position: 'relative',
          width: '100%',
          border: (theme:Theme) => isDragActive
            ? `1px solid ${theme.palette.primary.main}`
            : `1px solid ${theme.palette.divider}`
          ,
          borderRadius: 1,
          p: 4,
          mb: 4
        }}
      >
        {sortedFileItems.length ? (
          <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
            <Box style={{ position: 'relative', width: '100%', height: '100%' }}>
              <AutoSizer>
                {( dimmensions:{ width:number; height:number }) => (
                  <List
                    {...dimmensions}
                    itemCount={sortedFileItems.length}
                    itemSize={112}
                    itemData={{
                      fileItems: sortedFileItems
                    }}
                  >{Row}</List>
                )}
              </AutoSizer>
            </Box>
          </Box>
        ) : (
          <Box sx={{ width: '100%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <Message text={t('dialogs.batchCreate.dragAndDrop')} />
          </Box>
        )}
      </Box>

      {Boolean(errors.files) && !fileItems.length ? (
        <FormHelperText sx={{ mt: -2, mb: 4 }} error>{t('dialogs.batchCreate.filesRequired')}</FormHelperText>
      ) : null}

    </Fragment>
  )
}

export default FilesUploader;

const Row = memo(({ index, style, data:{ fileItems } }:any) => {
  const fileItem = fileItems[index];
  return (
    <Box
      style={{...style}}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        borderBottom: (theme:Theme) => `1px solid ${theme.palette.divider}`,
        py: 2,
        px: 4,
        '&:last-child': {
          borderBottom: 0
        }
      }}
    >
      <FieldItem fileItem={fileItem} />
    </Box>
  );
}, areEqual);
