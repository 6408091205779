import { FC, useMemo } from 'react';
import { Link as RRLink } from 'react-router-dom';
// Models
import { IUsage } from 'app/store/BillingUsage/BillingUsage.models';
// Mui
import { Box, Link, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Theme } from '@mui/material';
// Icons
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
// Utils
import { toNumberWithCommas } from 'app/utilities/Utilities';
// 
import UsageInvoiceNumbers from './UsageInvoiceNumbers';
import DraftInvoiceIcon from './DraftInvoiceIcon';

type Props = {
  data: IUsage[];
};

const UsageGroupByCase:FC<Props> = ({
  // Props
  data
}) => {
  const totalAmount = useMemo(() => {
    return data.reduce((acc:number, cur) => acc + cur.amount, 0)
  }, [data]);

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Case Name</TableCell>
            <TableCell sx={{ maxWidth: '50%' }}>Invoices</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((usage, index) => (
            <TableRow key={`tableRow-${usage.reference?.value}-${index}`}>
              <TableCell>
                <Link
                  sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                  component={RRLink}
                  target="_blank"
                  to={`/admin/cases/${usage.reference?.value}/documents`}
                  underline="none"
                >
                  {usage.reference?.name}
                  <OpenInNewIcon fontSize="small" />
                </Link>
              </TableCell>
              <TableCell><UsageInvoiceNumbers invoices={usage.invoices || []} /></TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <span>{`$${toNumberWithCommas(usage.amount)}`}</span>
                  <DraftInvoiceIcon invoices={usage.invoices} />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1 }}>
          <TableRow sx={{
            bgcolor: (theme:Theme) => theme.palette.grey[50],
            '& > td': {
              borderBottom: 0
            }
          }}>
            <TableCell sx={{ fontWeight: 700, fontSize: '0.875rem' }} colSpan={2}>Total</TableCell>
            <TableCell sx={{ fontWeight: 700, fontSize: '0.875rem' }}>{`$${toNumberWithCommas(totalAmount)}`}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default UsageGroupByCase