import { FC, Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
// Models
import { IUsageInvoice } from 'app/store/BillingUsage/BillingUsage.models';
// Mui
import { Chip, Box, Typography } from '@mui/material';

type Props = {
  invoices: IUsageInvoice[];
}

const UsageInvoiceNumbers:FC<Props> = ({
  // Props
  invoices
}) => {
  const MAX_VISIBLE = 2;

  const [ showAll, setShowAll ] = useState(false);

  const history = useHistory();

  const handleChipClick = (invoiceNumber:string) => () => {
    const params = { tab: '1', invoiceNumber };
    const queryString = new URLSearchParams(params).toString();
    history.push(`/admin/billing-and-subscriptions?${queryString}`);
  };

  const visibleInvoices = showAll ? invoices : invoices.slice(0, MAX_VISIBLE);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  if ( !invoices.length ) return <Fragment>-</Fragment>;
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      {visibleInvoices.map((invoice) => (
        <Chip
          key={invoice.id}
          label={invoice.invoiceNumber}
          onClick={handleChipClick(invoice.invoiceNumber)}
          size="small"
        />
      ))}
      {invoices.length > MAX_VISIBLE && (
        <Typography
          sx={{
            alignSelf: 'center',
            cursor: 'pointer',
            color: 'primary.main'
          }}
          onClick={toggleShowAll}
          variant="caption"
        >{showAll ? 'Show Less' : 'Show More'}</Typography>
      )}
    </Box>
  )
}

export default UsageInvoiceNumbers