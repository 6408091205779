// Types
import Reducers from "app/types/Reducers";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put } from 'app/utilities/HttpClient';

const _url:string = '/management/subscriptions';

// Get subscriptions
export const getSubscriptions = asyncThunkHandler(
  `${Reducers.Subscriptions}/Get subscriptions`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

// Create subscription
export const createSubscription = asyncThunkHandler(
  `${Reducers.Subscriptions}/Create subscription`,
  async (data:any) => {
    const response:Response = await $post(_url, data);
    return response.json();
  }
);

// Update subscription
export const updateSubscription = asyncThunkHandler(
  `${Reducers.Subscriptions}/Update subscription`,
  async ({ id, data }:{ id:number; data:any }) => {
    const response:Response = await $put(`${_url}/${id}`, data);
    return response.json();
  }
);

// Cancel subscription
export const cancelSubscription = asyncThunkHandler(
  `${Reducers.Subscriptions}/Cancel subscription`,
  async (id:number) => {
    const response:Response = await $post(`${_url}/${id}/actions/cancel`);
    return response.json();
  }
);

