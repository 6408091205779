import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
import { RootState } from 'app/store';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import { Loader, Message } from './Utilities';

type Props<T, P, O> = {
  asyncThunkFn?: (params?:P) => any; // The async function with parameters
  params?: P;
  selector: (state:RootState, options?:O) => T | undefined;
  selectorOptions?: O;
  noData?: JSX.Element;
  noDataText?: string;
  children: (data:T) => ReactNode; // Function as child to render data
}

const DataLoader = <T,P,O>({
  asyncThunkFn, params, selector, selectorOptions, noDataText = 'No data', noData, children
}:Props<T,P,O>) => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const data = useAppSelector((state:RootState) => selector(state, selectorOptions));

  const [ loading, setLoading ] = useState<boolean>(false);

  useEffect(() => {
    if ( !asyncThunkFn ) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        await dispatch(asyncThunkFn(params));
      } catch (error:any) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [asyncThunkFn, params, dispatch]);

  if ( loading ) return <Loader />;
  if (
    !data ||
    (Array.isArray(data) && data.length === 0) ||
    Object.keys(data).length === 0
  ) return (
    noData ? <Fragment>{noData}</Fragment> : <Message text={noDataText} /> 
  )

  return <Fragment>{children(data)}</Fragment>;
};

export default DataLoader;
