import { ChangeEvent, FC, Fragment, useState } from 'react';
// Models
import { IMyUser } from 'app/models/User';
import IInsuranceCase from 'app/models/Case';
import { IDocument } from 'app/store/DMSDocuments/DMSDocuments.models';
import ILabel from 'app/models/Label';
// Types
import Colors from 'app/types/Colors';
import Labels from 'app/components/Labels';
import { AuditOperations } from 'app/store/Audit/Audit.types';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createAuditRecord } from 'app/store/Audit/Audit.async';
import { patchDocument } from 'app/store/DMSDocuments/DMSDocuments.async';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectLoading } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import ColorPicker from 'app/components/ColorPicker';
import { Input, Button, LoadingButton } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  documentEntity: IDocument;
};

const DocumentLabelsDialog:FC<Props> = ({
  // Props
  open, onClose, documentEntity
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser:IMyUser | null = useAppSelector(selectMyUser);
  const insuranceCase:IInsuranceCase | null = useAppSelector(selectInsuranceCase);
  const loading:boolean = useAppSelector(selectLoading);

  const [ name, setName ] = useState('');
  const [ color, setColor ] = useState<Colors|string>(Colors.Red);
  const [ stateLabels, setStateLabels ] = useState<ILabel[]>(documentEntity.labels || []);
  const [ addedLabels, setAddedLabels ] = useState<ILabel[]>([]);
  const [ removedLabels, setRemovedLabels ] = useState<ILabel[]>([]);

  const handleSave = async () => {
    try {
      await dispatch(patchDocument({ documentId: documentEntity.id, data: { labels: stateLabels } })).unwrap();
      evokeAuditCreation();
      onClose();
    } catch(error){}
  }

  const evokeAuditCreation = () => {
    if ( !addedLabels.length && !removedLabels.length ) return;

    dispatch(createAuditRecord({
      operation: addedLabels.length
        ? AuditOperations.DocumentLabelAdding
        : AuditOperations.DocumentLabelRemoving
      ,
      data: {
        caseId: insuranceCase?.id || 0,
        userName: myUser?.name || '',
        addedLabels: addedLabels.map((label:ILabel) => label.name),
        removedLabels: removedLabels.map((label:ILabel) => label.name),
        fileName: documentEntity.name
      }
    }));
  }

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }

  const handleAddLabel = () => {
    if ( !name || !color ) return;

    const label = { name, color };

    const foundedIndex = removedLabels.findIndex((l:ILabel) => l.name === label.name);

    if ( foundedIndex !== -1 ){
      setRemovedLabels((prevState:ILabel[]) => prevState.filter((_:any, idx) => idx !== foundedIndex));
    } else {
      setAddedLabels([...addedLabels, label]);
    }

    setStateLabels((prevState:ILabel[]) => [...prevState, label])
    setName('');
    setColor(Colors.Red);
  }

  const handleRemoveLabel = (index:number) => {
    const label = stateLabels[index];

    const foundedIndex = addedLabels.findIndex((l:ILabel) => l.name === label.name);

    if ( foundedIndex !== -1 ){
      setAddedLabels((prevState:ILabel[]) => prevState.filter((_:any, idx) => idx !== foundedIndex));
    } else {
      setRemovedLabels([...removedLabels, label]);
    }

    setStateLabels((prevState:ILabel[]) => prevState.filter((_:any, idx) => idx !== index));
  }

  const handleClickColor = (nextColor:Colors|string) => setColor(nextColor);

  const actions = (
    <Fragment>
      <Button
        name={`Cancel document label dialog`}
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name={`Save document label dialog`}
        onClick={handleSave}
        loading={loading}
        variant="contained"
        color="primary"
      >{t('labels.save')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={`${t('layouts.filesSidebar.document')} ${t('labels.labels')}`}
      actions={actions}
    >
      <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
        <Box flexGrow={1}>
          <Input
            label={t('labels.name')} value={name}
            onChange={handleChange}
            margin="none"
          />
        </Box>
        <Box sx={{ pt: '10px' }}>
          <Button
            name="Add label"
            disabled={!name}
            onClick={handleAddLabel}
            variant="contained"
            color="primary"
          >{t('labels.add')}</Button>
        </Box>
      </Box>
      <Box sx={{ mb: 4 }}>
        <ColorPicker
          id={documentEntity.id}
          activeColors={[color]}
          onClick={handleClickColor}
        />
      </Box>
      <Labels
        id={documentEntity.id}
        labels={stateLabels}
        onRemove={handleRemoveLabel}
      />
    </Dialog>
  )
}

export default DocumentLabelsDialog;