import { FC } from 'react';
// Types
import { Dimension } from 'app/store/BillingUsage/BillingUsage.types';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectParams } from 'app/store/BillingUsage/BillingUsage.selectors';
// Mui
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Theme } from '@mui/material';
// Utilities
import { toNumberWithCommas } from 'app/utilities/Utilities';

const UsageNoDataFound:FC = () => {
  // State
  const params = useAppSelector(selectParams);

  const renderTableHeadRow = () => {
    switch (params.dimensions) {
      case Dimension.Case:
        return (
          <TableRow>
            <TableCell>Case Name</TableCell>
            <TableCell sx={{ maxWidth: '50%' }}>Invoices</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        );
      case Dimension.Service:
        return (
          <TableRow>
            <TableCell>Service Name</TableCell>
            <TableCell sx={{ maxWidth: '50%' }}>Invoices</TableCell>
            <TableCell>QTY</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        );
      default:
        return (
          <TableRow>
            <TableCell>Case Name</TableCell>
            <TableCell sx={{ maxWidth: '50%' }}>Invoices</TableCell>
            <TableCell>Service Name</TableCell>
            <TableCell>QTY</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        );
    }
  };

  const colspan = params.dimensions === Dimension.Case
    ? 3
    : params.dimensions === Dimension.Service
      ? 4
      : 5
  ;

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table stickyHeader>
        <TableHead>
          {renderTableHeadRow()}
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={colspan}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                <span>No data found for the selected filters</span>
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
        <TableFooter sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1 }}>
          <TableRow sx={{
            bgcolor: (theme:Theme) => theme.palette.grey[50],
            '& > td': {
              borderBottom: 0
            }
          }}>
            <TableCell sx={{ fontWeight: 700, fontSize: '0.875rem' }} colSpan={colspan - 1}>Total</TableCell>
            <TableCell sx={{ fontWeight: 700, fontSize: '0.875rem' }}>{`$${toNumberWithCommas(0)}`}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default UsageNoDataFound