import { FC, useState, useRef, useEffect, Fragment } from 'react'
// Models
import { ICurrentAccount } from 'app/models/Account';
import IInsuranceCase from 'app/models/Case';
import { IPrompt } from 'app/store/AIChatPrompts/AIChatPrompts.models';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createConversation } from 'app/store/AIChat/AIChat.async';
// Actions
import MixpanelActions from 'app/features/mixpanel/MixpanelActions';
// Selectors
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectCaseAIChatPrompts } from 'app/store/AIChatPrompts/AIChatPrompts.selectors';
import { selectHasAnyDocumentOcred } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import { Box, Typography, IconButton } from '@mui/material';
// Icons
import {
  ArrowBackIosNew as ArrowBackIosNewIcon,
  ArrowForwardIos as ArrowForwardIosIcon
} from '@mui/icons-material';
// Components
import DataLoading from 'app/components/DataLoading';
import AIPromptCard from 'app/components/AIPromptCard';

const ConversationInitialPrompts:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const currentAccount = useAppSelector(selectCurrentAccount) as ICurrentAccount;
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;

  const prompts = useAppSelector(selectCaseAIChatPrompts);

  const handleClick = (aiPrompt:IPrompt) => {
    dispatch(createConversation({
      caseId: insuranceCase.id,
      promptId: aiPrompt.id,
      // Read `promptQuestion` in `AIChat.middleware` on `createConversation`
      // to generate `humanMessage`
      // we exclude `promptQuestion` from `data` sent on `createConversation`
      // Better way, will be another action which will set `question`
      promptQuestion: aiPrompt.content
    }))
    dispatch(MixpanelActions.handleAIChatPromptClick({ promptId: aiPrompt.id }));
  }

  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      maxWidth: 920,
      mx: 'auto',
      px: 8,
      gap: 4
    }}>
      <DataLoading data={prompts}>
        {(data) => {
          const { platformPrompts, regularPrompts } = data.reduce((acc:{ platformPrompts:IPrompt[], regularPrompts:IPrompt[] }, cur:IPrompt) => {
            if ( cur.platform ){
              acc.platformPrompts.push(cur);
            } else {
              acc.regularPrompts.push(cur);
            }
            return acc;
          }, { platformPrompts: [], regularPrompts:[] });
          return (
            <Fragment>
              <AIPromptList
                aiPromptsTitle="Case Chronology"
                aiPrompts={platformPrompts as IPrompt[]}
                onClick={handleClick}
              />
              <AIPromptList
                aiPromptsTitle={currentAccount.company}
                aiPrompts={regularPrompts as IPrompt[]}
                onClick={handleClick}
              />
            </Fragment>
          )
        }}
      </DataLoading>
    </Box>
  )
}

export default ConversationInitialPrompts;

type Props = {
  aiPromptsTitle: string;
  aiPrompts: IPrompt[];
  onClick: (aiPrompt:IPrompt) => void;
}

const AIPromptList:FC<Props> = ({
  // Props
  aiPromptsTitle, aiPrompts, onClick
}) => {
  const hasAnyDocumentOcred = useAppSelector(selectHasAnyDocumentOcred);

  const [ showLeftButton, setShowLeftButton ] = useState(false);
  const [ showRightButton, setShowRightButton ] = useState(false);
  const [ showScrollbar, setShowScrollbar ] = useState(false);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const checkButtonsVisibility = () => {
    const scrollContainer = scrollContainerRef.current;
    if ( scrollContainer ){
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;
      setShowLeftButton(scrollLeft > 0);
      setShowRightButton(scrollLeft + clientWidth < scrollWidth);
    }
  };

  useEffect(() => {
    if ( !aiPrompts ) return;

    checkButtonsVisibility(); // Initial check
    const scrollContainer = scrollContainerRef.current;
    if ( scrollContainer ){
      scrollContainer.addEventListener('scroll', checkButtonsVisibility);
      return () => scrollContainer.removeEventListener('scroll', checkButtonsVisibility);
    }
    // eslint-disable-next-line
  }, [aiPrompts]);

  const handleClick = (scrollRight:boolean = false) => () => {
    if ( scrollContainerRef.current ){
      scrollContainerRef.current.scrollBy({ left: scrollRight ? 200 : -200, behavior: 'smooth' });
    }
  };

  // Handle mouse enter/leave to show/hide scrollbar
  const handleMouseOver = () => setShowScrollbar(true);
  const handleMouseOut = () => setShowScrollbar(false);

  const renderButton = (isRightButton:boolean = false) => (
    <IconButton
      sx={{
        position: 'absolute',
        right: isRightButton ? 0 : 'inheri',
        left: !isRightButton ? 0 : 'inherit',
        mr: isRightButton ? -8 : 0,
        ml: !isRightButton ? -8 : 0,
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1
      }}
      onClick={handleClick(isRightButton)}
      size="small"
    >{isRightButton ? <ArrowForwardIosIcon /> : <ArrowBackIosNewIcon />}</IconButton>
  );

  if ( !aiPrompts.length ) return null;
  return (
    <Box>
      <Typography variant="subtitle2" sx={{ mb: 2 }}>{aiPromptsTitle}</Typography>
      <Box
        sx={{ position: 'relative' }}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {showLeftButton ? renderButton() : null}
        <Box
          ref={scrollContainerRef}
          sx={{
            display: 'flex',
            gap: 4,
            overflowX: 'auto',
            scrollBehavior: 'smooth',
            width: '100%',
            '&::-webkit-scrollbar': {
              height: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: showScrollbar ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)', // Opacity transition
              borderRadius: '10px',
              transition: 'background-color 0.3s ease'
            }
          }}
        >
          {aiPrompts.map((aiPrompt:IPrompt) => (
            <AIPromptCard
              key={`ai prompt item ${aiPrompt.id}`}
              aiPrompt={aiPrompt}
              onClick={hasAnyDocumentOcred ? () => onClick(aiPrompt) : undefined}
              width={200}
              height={200}
              maxChars={50}
              flexWrap="no-wrap"
            />
          ))}
        </Box>
        {showRightButton ? renderButton(true) : null}
      </Box>
    </Box>
  );
}
