import { FC } from 'react';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { getCurrentStats, getCurrentMonthlyStats } from 'app/store/Analytics/Analytics.async';
// Mui
import { Container, Divider, Paper } from '@mui/material';
// Components
import { AnalyticsStats, AnalyticsMonthlyStats } from 'app/components/Analytics';

const UsageReportTabContent:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();

  const handleGetStats = () => {
    dispatch(getCurrentStats({}));
  }

  const handleGetMonthlyStats = (params:any) => {
    dispatch(getCurrentMonthlyStats(params));
  }

  return (
    <Container sx={{ pt: 4, pb: 4 }} maxWidth="md">
      <Paper sx={{ p: 4 }} variant="outlined">
        <AnalyticsStats
          title="Account summary"
          onGetStats={handleGetStats}
        />
        <Divider sx={{ my: 4 }} />
        <AnalyticsMonthlyStats
          title="Selected range summary"
          onGetMonthlyStats={handleGetMonthlyStats}
        />
      </Paper>
    </Container>
  )
}

export default UsageReportTabContent