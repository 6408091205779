import { FC, useEffect } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getDocumentPages } from 'app/store/DMSDocumentPages/DMSDocumentPages.async';
import { getDocumentEpisodes } from 'app/store/Episodes/Episodes.async';
// Actions
import MixpanelActions from 'app/features/mixpanel/MixpanelActions';
import { AppUiDialogActions } from 'app/store/AppUIDialog/AppUIDialog.slice';
import { DMSDocumentPagesActions } from 'app/store/DMSDocumentPages/DMSDocumentPages.slice';
// Selectors
import { selectDialogName } from 'app/store/AppUIDialog/AppUIDialog.selectors';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectError } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Hooks
import useToggle from 'app/hooks/useToggle';
// ToDO
import PageActions from 'app/store/page/page.actions';

import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';
// i18next
import { useTranslation } from 'react-i18next';

const PagesErrorHandler:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const dialogName = useAppSelector(selectDialogName);
  const insuranceCase = useAppSelector(selectInsuranceCase);
  const error = useAppSelector(selectError);

  const { open, toggle } = useToggle();

  useEffect(() => {
    if ( error?.status === 409 ){
      toggle();
      const { documentId, pageNum } = error.props;
      dispatch(MixpanelActions.handleCasePageUpdateConflict({ documentId, pageNum }));
    }
    // eslint-disable-next-line
  }, [error]);

  const handleClose = () => {
    dispatch(DMSDocumentPagesActions.setInitialField('error'));
    toggle();
  }

  const handleConfirm = async () => {
    if ( !error || !error.props ) return;

    const { documentId, pageNum } = error.props;

    try {
      await dispatch(getDocumentEpisodes({ documentId, fields: 'author' })).unwrap();
      await dispatch(getDocumentPages({ documentId })).unwrap();
      handleClose();

      if ( insuranceCase ){
        Mixpanel.track(MixpanelTracks.OutdatedPageUpdateAttempt, {
          accountId: insuranceCase.clientAccountId,
          caseId: insuranceCase.id,
          documentId,
          pageNum
        });
      }

      if ( dialogName ) dispatch(AppUiDialogActions.hideDialog());

      // ToDO
      dispatch(PageActions.cancelStapleMode());
      dispatch(DMSDocumentPagesActions.setForceReload(true));
      // End: ToDO

    } catch(error){} finally {
      setTimeout(() => {
        dispatch(DMSDocumentPagesActions.setForceReload(false));
      }, 500);
    }
  }

  if ( !open ) return null;
  return (
    <ConfirmationDialog
      open={open}
      onClose={handleClose}
      onConfirm={handleConfirm}
      confirmLabel={t('layouts.pagesErrorHandler.reload')}

      loading={false}
      title={t('layouts.pagesErrorHandler.pageDataOutdated')}
      content={error?.props?.message || t('layouts.pagesErrorHandler.reloadPageToGetLatestData')}
    />
  )
}

export default PagesErrorHandler;
