import { FC, Fragment } from 'react'
import { useParams, NavLink, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Models
import { IConversation } from 'app/models/ChatAI';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { deleteConversation } from 'app/store/AIChat/AIChat.async';
// Selectors
import { selectLoading } from 'app/store/AIChat/AIChat.selectors';
// Mui
import {
  Theme,
  ListItem, ListItemButton, ListItemText, ListItemIcon,
  Tooltip
} from '@mui/material';
// Icons
import {
  MoreVert as MoreVertIcon,
  Adjust as AdjustIcon,
  ArrowCircleLeftOutlined as ArrowCircleLeftOutlinedIcon,
  ArrowCircleRightOutlined as ArrowCircleRightOutlinedIcon,
  HdrAuto as HdrAutoIcon
} from '@mui/icons-material';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Components
import { IconButton } from 'app/components/Mui';
// Hooks
import useMenu from 'app/hooks/useMenu';
import useToggle from 'app/hooks/useToggle';
// 
import ConversationFormDialog from './ConversationFormDialog';
import useAiChatPermissionResolver from 'app/hooks/useAiChatPermissionResolver';
import useCasePermissionResolved from 'app/hooks/useCasePermissionResolver';

type Props = {
  conversation: IConversation;
}

const ConversationItem:FC<Props> = ({ conversation }) => {
  const { t } = useTranslation('common');

  const { caseId } = useParams<{ caseId:string }>()
  const { pathname } = useLocation();
  const history = useHistory();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const { createdAutomatically, sharedByMe, sharedWithMe, allowModifications } = useAiChatPermissionResolver(conversation);
  const { isCaseProcessingReadyOrNotStarted } = useCasePermissionResolved();

  const { Menu, MenuItem, openMenu } = useMenu();
  const { open, toggle } = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const handleConfirm = async () => {
    try {
      await dispatch(deleteConversation(conversation.id)).unwrap();
      const conversationPath = `/${conversation.id}`;
      if ( pathname.endsWith(conversationPath) ){
        history.replace(pathname.replace(conversationPath, ''));
      }
    } catch(error){}
  }

  const tooltipTitle = createdAutomatically
    ? 'Created by Automated Workflow'
    : sharedByMe ? 'Shared by me' : sharedWithMe ? 'Shared with me' : 'Private';

  return (
    <Fragment>
      <ListItem
        secondaryAction={isCaseProcessingReadyOrNotStarted && (allowModifications || createdAutomatically) ? (
          <Fragment>
            <IconButton
              name={`Conversation dropdown ${conversation.id}`}
              aria-label="Conversation item dropdown button"
              onClick={openMenu}
              edge="end"
            ><MoreVertIcon /></IconButton>
            <Menu>
              {!createdAutomatically ? (
                <MenuItem
                  key={`Edit conversation ${conversation.id}`}
                  name={`Edit conversation ${conversation.id}`}
                  onClick={toggle}
                >{t('labels.edit')}</MenuItem>
              ) : null}
              <MenuItem
                name={`Delete conversation ${conversation.id}`}
                sx={{
                  color: (theme:Theme) => theme.palette.error.main
                }}
                onClick={toggleConfirmationDialog}
              >{t('labels.delete')}</MenuItem>
            </Menu>
          </Fragment>
        ) : null}
        disablePadding
      >
        <ListItemButton
          sx={{
            borderLeft: `4px solid transparent`,
            pl: 2,
            '& + &': {
              borderTop: (theme:Theme) => `1px solid ${theme.palette.divider}`
            }
          }}
          component={NavLink}
          to={`/admin/cases/${caseId}/ai-chat/${conversation.id}`}
          activeStyle={{
            backgroundColor: 'rgba(0,0,0,0.04)',
            borderLeftColor: 'rgba(100,100,230,1)'
          }}
        >
          <ListItemIcon sx={{
            minWidth: 'initial',
            color: (theme:Theme) => theme.palette.primary.main
          }}>
            <Tooltip title={tooltipTitle} placement="right">
              {createdAutomatically ? (
                <HdrAutoIcon />
              ) : sharedByMe ? (
                <ArrowCircleLeftOutlinedIcon />
              ) : sharedWithMe ? (
                <ArrowCircleRightOutlinedIcon />
              ) : (
                <AdjustIcon />
              )}
            </Tooltip>
          </ListItemIcon>
          <ListItemText sx={{ pl: 2, wordBreak: 'break-word' }} primary={conversation.name} />
        </ListItemButton>
      </ListItem>
      {open ? (
        <ConversationFormDialog
          open={open}
          onClose={toggle}
          conversation={conversation}
        />
      ) : null}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          loading={loading}
          title={t('pages.staffPages.caseDetailsPage.deleteConversation')}
          content={t('pages.staffPages.caseDetailsPage.deleteConversationConfirmation', { conversationName: conversation.name })}
          onConfirm={handleConfirm}
        />
      ) : null}
    </Fragment>
  )
}

export default ConversationItem;
