import { forwardRef, ReactNode } from 'react';
// Mui
import {
  FormControlLabel as MuiFormControlLabel,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControl as MuiFormControl,
  FormHelperText
} from '@mui/material';
// Utilities
import { generateAttrForTesting } from 'app/utilities/Utilities';

type Props = MuiCheckboxProps & {
  label: ReactNode;
  value: boolean | undefined;
  name: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
};

const Checkbox = forwardRef<any, Props>(({
  // Props
  label, value, required = false, error = false, helperText, ...otherProps
}, ref) => {
  return (
    <MuiFormControl error={error}>
      <MuiFormControlLabel
        {...generateAttrForTesting('control', 'checkbox', otherProps.name)}
        ref={ref}
        label={label}
        required={required}
        control={
          <MuiCheckbox {...otherProps} checked={value} />
        }
      />
      {helperText ? (
        <FormHelperText>{helperText}</FormHelperText>
      ) : null}
    </MuiFormControl>
  )
})

export default Checkbox;
