import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
import FileFormats from 'app/types/FileFormats';
// Models
import { RootState } from 'app/store';
import { ICollection } from './DMSCollections.models';
// Constants
import { SUPPORTED_FILE_FORMATS, SUPPORTED_MIME_TYPES } from 'app/App.constants';

export const selectIds = (state:RootState) => state[Reducers.DMSCollections].ids;
export const selectEntities = (state:RootState) => state[Reducers.DMSCollections].entities;
export const selectLoading = (state:RootState) => state[Reducers.DMSCollections].loading;

export const selectCollectionEntity = createSelector(
  [
    selectEntities,
    (_, props:{ collectionId:number }) => props
  ],
  ( entities:Record<number, ICollection>, { collectionId }):ICollection | undefined => entities[collectionId]
);

export const selectCollections = createSelector(
  [
    selectIds,
    selectEntities
  ],
  ( ids:number[] | null, entities:Record<number, ICollection> ) => {
    if ( !ids ) return null;
    return ids.map((id:number) => entities[id]);
  }
);

export const selectCollectionsAsOptions = createSelector(
  [ selectCollections ],
  ( collections:ICollection[] | null) => {
    if ( !collections ) return null;
    return collections.map((collection:ICollection) => ({
      id: collection.id,
      name: collection.name,
      props: {
        description: collection.description || ''
      }
    }));
  }
);

export const selectCollectionsAsOptionsByFileType = createSelector(
  [
    selectCollections,
    (_:any, props:{ fileFormat?:FileFormats, ocred?: boolean }) => props
  ],
  ( collections:ICollection[] | null, { fileFormat, ocred }) => {
    if ( !collections ) return null;

    let filteredCollections = collections;

    if ( fileFormat ){
      if ( !SUPPORTED_FILE_FORMATS.includes(fileFormat) ) return null;

      filteredCollections = collections.filter((collection:ICollection) => {
        if (
          !collection.fileFormats ||
          !collection.fileFormats.length ||
          collection.fileFormats.includes(fileFormat)
        ){
          return typeof ocred !== 'undefined' ? collection.applyOcr === ocred : collection;
        }
        return false;
      });
  
      if ( !filteredCollections.length ) return null;
    }

    return filteredCollections
      .filter((collection:ICollection) => {
        if ( typeof ocred === 'undefined' ) return collection;
        return collection.applyOcr === ocred;
      })
      .map((collection:ICollection) => ({
        id: collection.id.toString(),
        name: collection.name,
        props: {
          description: collection.description || ''
        }
      }));
  }
);

export const selectAcceptMimeTypes = createSelector(
  [ selectCollections ],
  ( collections:ICollection[] | null ) => {
    type Formats = Exclude<FileFormats, 'custom'>;
    const FILE_FORMATS = SUPPORTED_FILE_FORMATS.filter((format:string) => format !== 'custom')
    const allFormats = FILE_FORMATS.reduce((acc:Record<string, string[]>, cur:string) => {
      acc[SUPPORTED_MIME_TYPES[cur as Formats]] = [`.${cur}`];
      return acc;
    }, {});
    if ( !collections ) return allFormats;
    let accessAllFormats = false;
    const result:FileFormats[] = [];
    for ( let collection of collections ){
      if ( !collection.fileFormats || !collection.fileFormats.length ){
        accessAllFormats = true;
        break;
      }
      for ( let fileFormat of collection.fileFormats ){
        if ( !result.includes(fileFormat) ) result.push(fileFormat);
      }
    }
    return accessAllFormats ? allFormats : result.reduce((acc:Record<string, string[]>, cur:FileFormats) => {
      acc[SUPPORTED_MIME_TYPES[cur as Formats]] = [`.${cur}`];
      return acc;
    }, {});
  }
);
