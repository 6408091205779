import { FC, Fragment, useEffect } from 'react';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Actions
import { BillingInvoicesActions } from 'app/store/BillingInvoices/BillingInvoices.slice';
// 
import BillingInvoicesFilter from './BillingInvoicesFilter';
import BillingInvoicesBatchPayment from './BillingInvoicesBatchPayment';
import InvoicesList from './InvoicesList';

const BillingInvoicesPage:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(BillingInvoicesActions.resetState());
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <BillingInvoicesFilter />
      <BillingInvoicesBatchPayment />
      <InvoicesList />
    </Fragment>
  )
}

export default BillingInvoicesPage;
