import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
// Mui
import { Box, CircularProgress, Theme } from '@mui/material';

type Props = {
  loading: boolean;
  uploadedFilesLength: number;
}

const FilesUploadProgress:FC<Props> = ({
  // Props
  loading, uploadedFilesLength
}) => {
  const { watch } = useFormContext();

  const watchFiles = useMemo(() => {
    return watch('files') || [];
    // eslint-disable-next-line
  }, [watch('files')]);

  const totalUploadedPercents:number = useMemo(() => {
    if ( !watchFiles || !watchFiles.length ) return 0;
    const percentPerOneFile = 100/watchFiles.length;
    return Math.round(percentPerOneFile * uploadedFilesLength);
  }, [watchFiles, uploadedFilesLength]);

  if ( !loading ) return null;
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 4 }}>
      {`${uploadedFilesLength}/${watchFiles.length}`}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
        <CircularProgress
          sx={{
            color: (theme:Theme) => theme.palette.grey[300]
          }}
          variant="determinate"
          value={100}
          size={36}
          thickness={4}
        />
        <CircularProgress
          sx={{
            position: 'absolute',
            top: 0, left: 0,
            zIndex: 1
          }}
          variant="determinate"
          value={totalUploadedPercents}
          size={36}
          thickness={4}
        />
        <Box
          sx={{
            position: 'absolute',
            fontSize: 10
          }}
        >{totalUploadedPercents}%</Box>
      </Box>
    </Box>
  )
}

export default FilesUploadProgress;
