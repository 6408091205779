import { useCallback } from "react";
// Types
import FileTypes from "app/types/FileTypes";
import FileFormats from "app/types/FileFormats";
// Models
import { ICollection } from 'app/store/DMSCollections/DMSCollections.models';
// Store
import { useAppSelector } from "app/hooks/useStore";
// Selectors
import { selectCollections } from 'app/store/DMSCollections/DMSCollections.selectors';
// Utilities
import { getFileExtFromFileName } from 'app/utilities/Utilities';
// Constants
import { SUPPORTED_FILE_FORMATS, MAX_FILE_SIZE, MAX_FILE_NAME_LENGTH } from 'app/App.constants';
// 
import { IFileItem } from "./UploadFiles.provider";

const useUploadFilesUtils = () => {
  // State
  const collections:ICollection[] | null = useAppSelector(selectCollections);

  // Validate file size
  const validateFileSize = (file:File) => {
    if ( file.size && file.size > MAX_FILE_SIZE ){
      return 'Document exceeds max document size';
    }
    return null;
  };

  // Validate file name length
  const validateFileNameLength = (file:File) => {
    if ( file.name && file.name.length > MAX_FILE_NAME_LENGTH ){
      return `The document name is too long. Rename the Document with less than ${MAX_FILE_NAME_LENGTH} characters`;
    }
    return null;
  };

  // Validate all file items and directly set error to the fileItem
  const validateFileItems = useCallback((fileItem:IFileItem, index:number) => {
    const { file } = fileItem;

    // Initialize fileError as `undefined`
    fileItem.fileError = null;

    const sizeError = validateFileSize(file);
    if ( sizeError ){
      fileItem.fileError = sizeError;
      return;
    }

    const nameLengthError = validateFileNameLength(file);
    if ( nameLengthError ){
      fileItem.fileError = nameLengthError;
      return;
    }
    // eslint-disable-next-line
  }, []);

  const getCollectionId = useCallback((fileFormat:FileFormats):number | undefined => {
    let collectionId:number | undefined;

    if ( !collections || !collections.length ) return collectionId;

    for ( let collection of collections ){
      if (
        !collection.fileFormats ||
        !collection.fileFormats.length ||
        collection.fileFormats.includes(fileFormat)
      ){
        if ( !collectionId ){
          collectionId = collection.id;
        } else {
          collectionId = undefined;
          break;
        }
      }
    }
    return collectionId;
  }, [collections])

  const fileFormatNotSupported = useCallback((fileFormat:FileFormats) => {
    return !SUPPORTED_FILE_FORMATS.includes(fileFormat);
  }, []);

  const generateFileItem = useCallback((file:File, fileItems:IFileItem[]):IFileItem | undefined => {
    const fileFormat = getFileExtFromFileName(file.name);

    const newFileItem:IFileItem = {
      id: `${Date.now()}-${Math.random()}`,
      file,
      type: FileTypes.Document,
      fileError: null,
      fileFormat,
      comment: ''
    };

    if ( fileFormatNotSupported(fileFormat) ) return;

    newFileItem['collectionId'] = getCollectionId(fileFormat);

    validateFileItems(newFileItem, fileItems.length);

    return newFileItem;
  }, [fileFormatNotSupported, getCollectionId, validateFileItems]);

  return { generateFileItem };
};

export default useUploadFilesUtils;
