import { FC, Fragment, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// Models
import IMatch from 'app/models/Match';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { getAccounts } from 'app/store/AccountsManagement/AccountsManagement.async';
import { getServices } from 'app/store/BillingServices/BillingServices.async';
import { getPackages } from 'app/store/BillingPackages/BillingPackages.async';
// Components
import Header from 'app/components/Header';
// Pages
import CompanyAccountsPage from './CompanyAccountsPage';
import SubscriptionsPage from './SubscriptionsPage';
import SubscriptionPackagesPage from './SubscriptionPackagesPage';
import AnalyticsPage from './AnalyticsPage';
import BillingInvoicesPage from './BillingInvoicesPage';
import BillingOrdersPage from './BillingOrdersPage';
import BillingServicesPage from './BillingServicesPage';
import UsersPage from './UsersPage';
import TermsAndConditionsPage from './TermsAndConditionsPage';
import AIPromptHubPage from './AIPromptHubPage';
import CostExplorerPage from './CostExplorerPage';

type Props = {
  match: IMatch;
}

const AccountManagerRouting:FC<Props> = ({
  // Props
  match
}) => {
  // Dispatch
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAccounts({}));
    dispatch(getServices({}));
    dispatch(getPackages({}));
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Header />
      <Switch>
        <Route path={`${match.path}/company-accounts`} component={CompanyAccountsPage} />
        <Route path={`${match.path}/subscriptions`} component={SubscriptionsPage} />
        <Route path={`${match.path}/subscription-packages`} component={SubscriptionPackagesPage} />
        <Route path={`${match.path}/analytics`} component={AnalyticsPage} />
        <Route path={`${match.path}/cost-explorer`} component={CostExplorerPage} />
        <Route path={`${match.path}/billing-invoices`} component={BillingInvoicesPage} />
        <Route path={`${match.path}/billing-orders`} component={BillingOrdersPage} />
        <Route path={`${match.path}/billing-services`} component={BillingServicesPage} />
        <Route path={`${match.path}/users`} component={UsersPage} />
        <Route path={`${match.path}/terms-and-conditions`} component={TermsAndConditionsPage} />
        <Route path={`${match.path}/ai-prompt-hub`} component={AIPromptHubPage} />
        <Redirect from={match.path} to={`${match.path}/company-accounts`} />
      </Switch>
    </Fragment>
  )
}

export default AccountManagerRouting;
