import { FC, Fragment, ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// Mui
import { Box } from '@mui/material';
// Utilities
import { getSearchParam } from 'app/utilities/Utilities';
// 
import BillingAndSubscriptionsToolbar from './BillingAndSubscriptionsToolbar';
import SubscriptionsTabContent from './SubscriptionsTabContent';
import BillingInvoicesTabContent from './BillingInvoicesTabContent';
import UsageReportTabContent from './UsageReportTabContent';
import CostExplorerTabContent from './CostExplorerTabContent';

const BillingAndSubscriptionsPage:FC = () => {
  const { search } = useLocation();

  const [ activeTab, setActiveTab ] = useState(0);

  useEffect(() => {
    const tab = getSearchParam(search, 'tab');
    setActiveTab(tab ? parseInt(tab) : 0);
  }, [search]);

  const handleChange = (nextActiveTab:number) => {
    setActiveTab(nextActiveTab);
  }

  const renderContent:Record<number, ReactNode> = {
    0: <SubscriptionsTabContent />,
    1: <BillingInvoicesTabContent />,
    2: <UsageReportTabContent />,
    3: <CostExplorerTabContent />
  };

  return (
    <Fragment>
      <BillingAndSubscriptionsToolbar activeTab={activeTab} onChange={handleChange} />
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        {renderContent[activeTab]}
      </Box>
    </Fragment>
  )
}

export default BillingAndSubscriptionsPage;
