export enum DroppableIds {
  Template = 'template',
  Document = 'document',
  Destination = 'destination'
};

export enum ContentType {
  Document = 'document',
  Template = 'template'
};

export type ContentItemType = `urn:cc:${ContentType}:${number}`;

