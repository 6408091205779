// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from "./Accounts.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $put } from 'app/utilities/HttpClient';

const _url:string = '/accounts';

// Get current account
export const getCurrentAccount = asyncThunkHandler(
  `${Reducers.Accounts}/Get current account`,
  async () => {
    const response:Response = await $get(`${_url}/current`);
    return response.json();
  }
);

// Update current account
export const updateCurrentAccount = asyncThunkHandler(
  `${Reducers.Accounts}/Update current account`,
  async (data:any, { dispatch }) => {
    const response:Response = await $put(`${_url}/current`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return response.json();
  }
);
