import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Types
import { ContentItemType, ContentType } from 'app/types/ContentItem';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { mergeTemplate } from 'app/store/Templates/Templates.async';
// Components
import Dialog from 'app/components/Dialog';
import { Button } from 'app/components/Mui';
import { Loader, Message } from 'app/components/Utilities';
// i18next
import { useTranslation } from 'react-i18next';
import { generateContentItem } from 'app/utilities/ContentItemUtils';

type Props = {
  open: boolean;
  onClose: () => void;
  templateIds?: number[];
  documentIds?: number[];
  content?: ContentItemType[];
}

const ContentPreviewDialog:FC<Props> = ({
  // Props
  open, onClose, templateIds, documentIds, content
}) => {
  const { t } = useTranslation('common');

  const { caseId } = useParams<{ caseId:string }>();

  // Dispatch
  const dispatch = useAppDispatch();

  const [ innerHTML, setInnerHTML ] = useState<string | TrustedHTML>('');

  useEffect(() => {
    const inputContent = templateIds
      ? templateIds.map((id:number) => generateContentItem(ContentType.Template, id))
      : documentIds
        ? documentIds.map((id:number) => generateContentItem(ContentType.Document, id))
        : content
    ;

    const asyncPreviewTemplate = async () => {
      const mergedTemplateData:any = { content: inputContent };
      if ( caseId ) mergedTemplateData['caseId'] = Number(caseId);
      try {
        const data = await dispatch(mergeTemplate(mergedTemplateData)).unwrap();
        setInnerHTML(data.content);
      } catch(error){
        setInnerHTML('');
      }
    }

    asyncPreviewTemplate();
    // eslint-disable-next-line
  }, []);

  const actions = (
    <Button
      name="Close template preview dialog"
      onClick={onClose}
    >{t('labels.close')}</Button>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('dialogs.contentPreviewDialog.title')}
      actions={actions}
      maxWidth="lg"
    >
      {typeof innerHTML === 'undefined' ? <Loader /> : !innerHTML
        ? <Message text={t('dialogs.contentPreviewDialog.noPreview')} />
        : <div
            style={{ 'whiteSpace': 'break-spaces', padding: 0 }}
            className="ql-editor preview"
            dangerouslySetInnerHTML={{ __html: innerHTML }}
          />
      }
    </Dialog>
  )
}

export default ContentPreviewDialog;
