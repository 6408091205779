// Types
import Strategy from 'app/types/Strategy';
import MixpanelEvents from 'app/features/mixpanel/MixpanelEvents';
// Models
import ITemplate from 'app/models/Template';
// Services
import Mixpanel from "app/services/Mixpanel.service";

export const createTemplateStrategy:Strategy = (payload, { userId, accountId, caseId }) => {
  const template = payload as ITemplate;
  let mixpanelEvent:any = MixpanelEvents.TemplateCreated;
  const mixpanelData:any = {
    accountId,
    userId,
    templateId: template.id,
  };
  if ( caseId ){
    mixpanelEvent = MixpanelEvents.CaseTemplateCreated;
    mixpanelData['caseId'] = caseId;
    if ( template.globalTemplateId ){
      mixpanelEvent = MixpanelEvents.CaseTemplateImported;
      mixpanelData['globalTemplateId'] = template.globalTemplateId;
    }
  }
  Mixpanel.track(mixpanelEvent, mixpanelData);
};

export const updateTemplateStrategy:Strategy = (payload, { userId, accountId, caseId }) => {
  const template = payload as ITemplate;
  let mixpanelEvent:any = MixpanelEvents.TemplateUpdated;
  const mixpanelData:any = {
    accountId,
    userId,
    templateId: template.id,
  };
  if ( caseId ) {
    mixpanelEvent = MixpanelEvents.CaseTemplateUpdated;
    mixpanelData['caseId'] = caseId;
  }
  Mixpanel.track(mixpanelEvent, mixpanelData);
};

export const updateTemplateConflictStrategy:Strategy = (payload, { userId, accountId, caseId }) => {
  const { path, statusCode } = payload;
  if ( statusCode === 409 ) {
    const id = path.match(/\d+$/)?.[0];
    let mixpanelEvent:any = MixpanelEvents.TemplateUpdateConflict;
    const mixpanelData:any = {
      accountId,
      userId,
      templateId: parseInt(id, 10),
    };
    if ( caseId ) {
      mixpanelEvent = MixpanelEvents.CaseTemplateUpdateConflict;
      mixpanelData['caseId'] = caseId;
    }
    Mixpanel.track(mixpanelEvent, mixpanelData);
  }
};
export const deleteTemplateStrategy:Strategy = (payload, { userId, accountId, caseId }) => {
  const templateId = payload as number;
  let mixpanelEvent:any = MixpanelEvents.TemplateDeleted;
  const mixpanelData:any = {
    accountId,
    userId,
    templateId,
  };
  if ( caseId ) {
    mixpanelEvent = MixpanelEvents.CaseTemplateDeleted;
    mixpanelData['caseId'] = caseId;
  }
  Mixpanel.track(mixpanelEvent, mixpanelData);
};

export const handleTemplateOpenedStrategy:Strategy = (payload, { userId, accountId, caseId }) => {
  const templateId = payload as number;
  let mixpanelEvent:any = MixpanelEvents.TemplateOpened;
  const mixpanelData:any = {
    accountId,
    userId,
    templateId,
  };
  if ( caseId ) {
    mixpanelEvent = MixpanelEvents.CaseTemplateOpened;
    mixpanelData['caseId'] = caseId;
  }
  Mixpanel.track(mixpanelEvent, mixpanelData);
};
