import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
import { Dimension, ReferenceTypes } from './BillingUsage.types';
// Models
import { RootState } from 'app/store';
import { IUsage, IGroupByCaseAndService } from './BillingUsage.models';

export const selectUsages = (state:RootState) => state[Reducers.BillingUsage].usages;
export const selectParams = (state:RootState) => state[Reducers.BillingUsage].params;

export const selectGroupByUsage = createSelector(
  [
    selectUsages,
    selectParams
  ],
  ( usages:IUsage[], { dimensions }) => {
    if (
      dimensions === Dimension.Service ||
      dimensions === Dimension.Case
    ) return usages;
    return groupByCaseAndService(usages);
  }
);

interface IValidationErrors {
  startError?: string;
  endError?: string;
  rangeError?: string;
}

export const selectValidateDateRange = createSelector(
  [ selectParams ],
  ( params ) => {
    const start = params['period.start'];
    const end = params['period.end'];
    const errors: IValidationErrors = {};
    // Check if start date is valid
    if ( !start ){
      errors.startError = 'Start date is required.';
    } else if ( !start.isValid() ){
      errors.startError = 'Start date is invalid.';
    }

    // Check if end date is valid
    if ( !end ){
      errors.endError = 'End date is required.';
    } else if ( !end.isValid() ){
      errors.endError = 'End date is invalid.';
    }

    // Perform range validations if both dates are valid
    if ( start && end && start.isValid() && end.isValid() ){
      if ( start.isAfter(end) ){
        errors.rangeError = 'Start date cannot be after end date.';
      } else if (end.diff(start, 'year', true) > 1) {
        errors.rangeError = 'The date range cannot exceed 1 year.';
      }
    }

    // Return null if there are no errors
    return Object.keys(errors).length > 0 ? errors : null;
  }
);

const groupByCaseAndService = (usages: IUsage[]):IGroupByCaseAndService[] => {
  // Group by caseId and populate services as an array
  const groupedData = usages.reduce((acc:IGroupByCaseAndService[], cur) => {
    const caseReference = cur.reference && cur.reference.type === ReferenceTypes.Case ? cur.reference : null;

    if ( !caseReference ) return acc;

    const caseId = caseReference.value;
    const caseName = caseReference.name;
    const { service, quantity, amount, invoices } = cur;

    // Skip if service is undefined
    if ( !service ) return acc;

    // Find or create case entry
    let caseEntry = acc.find((entry) => entry.caseId === caseId);
    if ( !caseEntry ){
      caseEntry = { caseId, caseName, services: [], invoices };
      acc.push(caseEntry);
    }

    // Add or update service
    const existingService = caseEntry.services.find(({ id }) => id === service.id);
    if ( existingService ){
      existingService.quantity += quantity;
      existingService.amount += amount;
    } else {
      caseEntry.services.push({
        id: service.id,
        name: service.name,
        quantity,
        amount,
      });
    }

    return acc;
  }, []);

  // Sort the services inside each case by amount (descending)
  groupedData.forEach(caseData => {
    caseData.services.sort((a, b) => b.amount - a.amount); // Sort services by amount descending
  });

  // Sort the cases by the total amount of services (descending)
  return groupedData
    .map(caseData => ({
      ...caseData,
      totalAmount: caseData.services.reduce((sum, service) => sum + service.amount, 0) // Calculate total amount for each case
    }))
    .sort((a, b) => b.totalAmount - a.totalAmount) // Sort cases by total amount descending
    .map(({ totalAmount, ...caseData }) => caseData); // Remove the totalAmount from the final result
};
