import { createSelector } from '@reduxjs/toolkit';
// Models
import { RootState } from 'app/store';
import IBillingPackage from 'app/models/BillingPackage';

export const selectPackages = (state:RootState) => state['billing/packages'].packages;
export const selectPackage = (state:RootState) => state['billing/packages'].package;
export const selectLoading = (state:RootState) => state['billing/packages'].loading;

export const selectPackagesAsOptions = createSelector(
  [ selectPackages ],
  ( packages:IBillingPackage[] | null) => {
    if ( !packages ) return null;
    return packages.map((pkg:IBillingPackage) => ({ id: pkg.id, name: pkg.name }))
  }
);
