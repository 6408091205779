import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// Mui
import { Theme, Box, FormHelperText } from '@mui/material';
// Icons
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
import useMenu from 'app/hooks/useMenu';
// 
import { IFileItem, useUploadFilesContext } from './UploadFiles.provider';
import FieldItemText from './FieldItemText';
import FieldItemCollectionSelector from './FieldItemCollectionSelector';
import FieldCommentDialog from './FieldCommentDialog';

type Props = {
  fileItem: IFileItem;
}

const FieldItem:FC<Props> = ({
  // Props
  fileItem
}) => {
  const { t } = useTranslation('common');

  const { onRemoveFileItem, onUpdateFileItem } = useUploadFilesContext();

  const { open, toggle } = useToggle();
  const { Menu, MenuItem, openMenu } = useMenu();

  const handleClose = (comment:string) => {
    if ( open ) toggle();
    onUpdateFileItem(fileItem.id, { comment: comment || '' });
  }

  const handlePreviewClick = () => {
    if ( !fileItem.file ) return;
    const url = URL.createObjectURL(fileItem.file);
    window.open(url, '_blank');
  }

  const handleRemoveClick = () => {
    onRemoveFileItem(fileItem.id);
  }

  const showPreviewMenuItem = fileItem.file.type && fileItem.file.type.includes('pdf');

  return (
    <Fragment>
      <Box sx={{ alignSelf: 'center', flex: 1, overflow: 'hidden' }}>
        <FieldItemText fileItem={fileItem} />
        {fileItem.fileError ? (
          <FormHelperText sx={{ mt: 0 }} error>{fileItem.fileError}</FormHelperText>
        ) : null}
      </Box>
      <FieldItemCollectionSelector fileItem={fileItem} />
      <IconButton
        name={`File context menu ${fileItem.id}`}
        aria-label="File context menu"
        onClick={openMenu}
      ><MoreVertIcon /></IconButton>
      <Menu>
        {showPreviewMenuItem ? (
          <MenuItem
            name={`Preview file ${fileItem.id}`}
            onClick={handlePreviewClick}
          >{t('labels.preview')}</MenuItem>
        ) : null}
        <MenuItem
          name={`Comment file ${fileItem.id}`}
          onClick={toggle}
        >{t('labels.comment')}</MenuItem>
        <MenuItem
          name={`Remove file ${fileItem.id}`}
          sx={{
            color: (theme:Theme) => theme.palette.error.main
          }}
          onClick={handleRemoveClick}
        >{t('labels.delete')}</MenuItem>
      </Menu>
      {open ? (
        <FieldCommentDialog
          open={open}
          onClose={handleClose}
          comment={fileItem.comment}
        />
      ) : null}
    </Fragment>
  )
}

export default FieldItem