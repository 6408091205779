import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { BillingSubscriptionsActions } from 'app/store/BillingSubscriptions/BillingSubscriptions.slice';
// Selectors
import { selectFilter } from 'app/store/BillingSubscriptions/BillingSubscriptions.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Select } from 'app/components/Mui';

const SubscriptionsFilter:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const filter = useAppSelector(selectFilter);

  const handleChange = (field:keyof typeof filter, value:string) => {
    dispatch(BillingSubscriptionsActions.setFilter({ [field]: value }));
  }

  return (
    <Box sx={{ display: 'flex', gap: 4, mb: 4, justifyContent: 'flex-end' }}>
      <Select
        label={t('labels.status')} name="status" value={filter.status}
        onChange={(event:ChangeEvent<HTMLInputElement>) => handleChange('status', event.target.value)}
        options={[
          { id: 'all', name: t('labels.all') },
          { id: 'active', name: 'Active' },
          { id: 'inactive', name: 'Inactive' },
        ]}
        margin="none"
        size="small"
      />
    </Box>
  )
}

export default SubscriptionsFilter;