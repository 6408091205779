import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
import { ISubscription } from './Subscriptions.models';
import { SubscriptionStatus } from './Subscriptions.types';
import { sortByName } from 'app/utilities/SortBy';

export const selectSubscriptions = (state:RootState) => state[Reducers.Subscriptions].subscriptions;
export const selectParams = (state:RootState) => state[Reducers.Subscriptions].params;

export const selectSortedSubscriptions = createSelector(
  [ selectSubscriptions ],
  ( subscriptions:ISubscription[]) => {
    return [...subscriptions].sort((a, b) => sortByName(a, b, 'status'))
  }
);

export const selectHasAnyActiveSubscripionPackageCPTCodesAccess = createSelector(
  [ selectSubscriptions ],
  ( subscriptions:ISubscription[] ) => {
      let hasCPTCodesAccess = false;
      for ( let subscription of subscriptions ){
        if ( subscription.status !== SubscriptionStatus.Active ) continue;
        for ( let item of subscription.items ){
          if ( item.service.code === 'cptCodesAccess' ){
            hasCPTCodesAccess = true;
            break;
          }
        }
        if ( hasCPTCodesAccess ) break;
      }
      return hasCPTCodesAccess;
  }
)
