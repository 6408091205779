enum CaseEvents {
  DocumentsValidationFailed = 'DocumentsValidationFailed',

  CasePageUpdateConflict = 'CasePageUpdateConflict',
  CaseEpisodeUpdateConflict = 'CaseEpisodeUpdateConflict',

  CaseTemplateOpened = 'CaseTemplateOpened',
  CaseTemplateCreated = 'CaseTemplateCreated',
  CaseTemplateUpdated = 'CaseTemplateUpdated',
  CaseTemplateUpdateConflict = 'CaseTemplateUpdateConflict',
  CaseTemplateDeleted = 'CaseTemplateDeleted',
  CaseTemplateImported = 'CaseTemplateImported'
};

export default CaseEvents;
