export enum SubscriptionStatus {
  Active = 'active',
  Inactive = 'inactive'
};

export enum UsageType {
  Licensed = 'licensed',
  Metered = 'metered'
};

export enum RecurringInterval {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year'
};

export enum ServiceUnit {
  Page = 'page',
  Minute = 'minute',
  User = 'user',
  Organization = 'organization',
  Case = 'case'
}
