import { ChangeEvent, FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
// Types
import { ContentType } from 'app/types/ContentItem';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { mergeTemplate } from 'app/store/Templates/Templates.async';
// Selectors
import { selectAllNonTableTemplatesAsOptions } from 'app/store/Templates/Templates.selectors';
// Mui
import {
  Radio, RadioGroup, FormControlLabel, FormControl
} from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button, LoadingButton } from 'app/components/Mui';
import Autocomplete from 'app/components/Mui/AutocompleteV2';
// Utils
import { generateContentItem } from 'app/utilities/ContentItemUtils';

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: (content:string) => void;
}

const DocumentTemplateDialog:FC<Props> = ({
  // Props
  open, onClose, onConfirm
}) => {
  const { caseId } = useParams<{ caseId:string }>();

  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const templateOptions = useAppSelector(selectAllNonTableTemplatesAsOptions);

  const [ value, setValue ] = useState<'blank' | 'template'>('blank');
  const [ templateId, setTemplateId ] = useState<number | null>(null);
  const [ loading, setLoading ] = useState(false);

  const handleClick = () => {
    if ( value === 'blank' ){
      onConfirm('');
      onClose();
    } else {
      if ( templateId === null ) return;
      asyncMergeTemplate();
    }
  }

  const asyncMergeTemplate = async () => {
    const mergeTemplateContent = templateId && [generateContentItem(ContentType.Template, templateId)];

    setLoading(true);
    try {
      const { content } = await dispatch(mergeTemplate({
        caseId: Number(caseId),
        content: mergeTemplateContent
      })).unwrap();
      onConfirm(content);
      onClose();
    } catch(error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value as any);
    setTemplateId(null);
  };

  const isButtonDisabled = value === 'template' && templateId === null;

  const actions = (
    <Fragment>
      <Button
        name="Cancel doucment template dialog"
        onClick={onClose}
      >{t('labels.cancel')}</Button>
      <LoadingButton
        name="Use document template dialog"
        loading={loading}
        disabled={isButtonDisabled}
        onClick={handleClick}
        color="primary"
        variant="contained"
      >{t('labels.next')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title="Create document"
      actions={actions}
    >
      <FormControl>
        <RadioGroup
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel value="blank" control={<Radio />} label="Blank document" />
          <FormControlLabel value="template" control={<Radio />} label="From a template" />
        </RadioGroup>
      </FormControl>
      {value === 'template' ? (
        <Autocomplete
          value={templateId}
          onChange={(nextValue:any) => setTemplateId(nextValue)}
          options={templateOptions || []}
          InputProps={{
            label: 'Select template'
          }}
        />
      ) : null}
    </Dialog>
  )
}

export default DocumentTemplateDialog;
