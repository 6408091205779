enum AIChatConversationEvents {
  AIChatConversationCreated = 'AIChatConversationCreated',
  AIChatConversationDeleted = 'AIChatConversationDeleted',
  AIChatConversationUpdated = 'AIChatConversationUpdated',
  AIChatConversationResponsePopupClick = 'AIChatConversationResponsePopupClick',
  AIChatConversationResponsePopupPageClick = 'AIChatConversationResponsePopupPageClick',
  AIChatConversationResponseRefinement = 'AIChatConversationResponseRefinement'
};

enum AIChatPromptEvents {
  AIChatPromptClick = 'AIChatPromptClick', // should be recorded when conversation is created from prompt ( prompt click on AI Chat initial screen )
  AIChatPromptUse = 'AIChatPromptUse', // should be recorded when question is created from prompt ( inside conversation )
  AIChatPromptSaved = 'AIChatPromptSaved'
};

enum AIChatPromptHubEvents {
  AIChatPromptHubPromptCreated = 'AIChatPromptHubPromptCreated',
  AIChatPromptHubPromptUpdated = 'AIChatPromptHubPromptUpdated',
  AIChatPromptHubPromptDeleted = 'AIChatPromptHubPromptDeleted'
};

const AIChatEvents = {
  ...AIChatConversationEvents,
  ...AIChatPromptEvents,
  ...AIChatPromptHubEvents
};

export default AIChatEvents;
