import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
// Types
import Reducers from "app/types/Reducers";
import { Dimension } from "./BillingUsage.types";
// Models
import { IState, IUsage } from "./BillingUsage.models";
// Async
import { getUsage } from './BillingUsage.async';

const initialState:IState = {
  usages: [],
  params: {
    dimensions: Dimension.Service,
    'reference:clientAccount': null,
    subscriptionId: null,
    serviceId: null,
    'reference:case': null,
    "period.start": dayjs().subtract(1, 'month').startOf('month'),
    "period.end": dayjs().subtract(1, 'month').endOf('month')
  }
};

const slice = createSlice({
  name: Reducers.BillingUsage,
  initialState,
  reducers: {
    setParams: (state, action:PayloadAction<any>) => {
      const params = action.payload;
      state.params = Object.keys(state.params).reduce((acc:any, cur:any) => {
        acc[cur] = (state.params as any)[cur];
        if ( typeof params[cur] !== 'undefined' ) acc[cur] = params[cur];
        return acc;
      }, {});
    },
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers(builder) {
    // Get subscriptions
    builder.addCase(getUsage.pending, (state) => {
      state.usages = [];
    });
    builder.addCase(getUsage.fulfilled, (state, action:PayloadAction<IUsage[]>) => {
      state.usages = action.payload;
    });
  },
});

export const BillingUsageActions = slice.actions;

export default slice.reducer;
