import { FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { ContentType } from 'app/types/ContentItem';
// Models
import { RootState } from 'app/store';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectDocumentEntity } from 'app/store/DMSDocuments/DMSDocuments.selectors';
import { selectTemplateById } from 'app/store/Templates/Templates.selectors';
// Mui
import { Paper, Box, Typography, Tooltip, FormLabel } from '@mui/material';
// Icons
import {
  DragIndicator as DragIndicatorIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  Close as CloseIcon,
  SourceOutlined as SourceOutlinedIcon,
} from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
import TemplateTypeInfo from 'app/components/TemplateTypeInfo';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Dialogs
import ContentPreviewDialog from 'app/dialogs/ContentPreviewDialog';
// 
import { useReportPresetContext } from 'app/context/ReportPreset.context';

type Props = {
  id: number;
  contentType: ContentType;
}

const SelectedContentItem:FC<Props> = ({
  // Props
  id, contentType
}) => {
  const { t } = useTranslation('common');
  // State
  const document = useAppSelector((state:RootState) => selectDocumentEntity(state, {
    documentId: id
  }));
  const template = useAppSelector((state:RootState) => selectTemplateById(state, {
    templateId: id
  }));

  const { caseId, onRemoveContentItem } = useReportPresetContext();
  const { open, toggle } = useToggle();

  const name = useMemo(() => {
    return contentType === ContentType.Document ? document.name : template?.name;
  }, [contentType, document, template]);

  const handleClick = () => {
    onRemoveContentItem(contentType, id);
  }

  const isContentTypeTemplate = contentType === ContentType.Template;
  const contentPreviewDialogProps = isContentTypeTemplate
    ? { templateIds: [id] }
    : { documentIds: [id] }
  ;

  return (
    <Fragment>
      <Paper sx={{
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        p: 4
      }}>
        <DragIndicatorIcon />
        <Box sx={{ px: 2, flexGrow: 1 }}>
          <Typography sx={{ mb: isContentTypeTemplate ? 2 : 0 }} variant="h6">{name}</Typography>
          {isContentTypeTemplate && template ? (
            <TemplateTypeInfo
              type={template.type}
              dataType={template.dataType}
              layout={template.layout}
            />
          ) : (
            <FormLabel sx={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
              <SourceOutlinedIcon sx={{ mr: 2 }} fontSize="small" />
              Document
            </FormLabel>
          )}
        </Box>
        <Box>
          {caseId ? (
            <Tooltip title={t('labels.preview')}>
              <IconButton
                name={`Preview ${contentType} ${id}`}
                aria-label={`Preview ${contentType}`}
                onClick={toggle}
              ><VisibilityOutlinedIcon /></IconButton>
            </Tooltip>
          ) : null}
          <Tooltip title={t('labels.remove')}>
            <IconButton
              aria-label="Remove"
              name={`Add ${contentType} ${id}`}
              onClick={handleClick}
            ><CloseIcon /></IconButton>
          </Tooltip>
        </Box>
      </Paper>
      {open ? (
        <ContentPreviewDialog
          {...contentPreviewDialogProps}
          open={open}
          onClose={toggle}
        />
      ) : null}
    </Fragment>
  )
}

export default SelectedContentItem;