import { FC } from 'react';
// Mui
import { Container } from '@mui/material';
// 
import BillingInvoicesPage from './BillingInvoicesPage'

const BillingInvoicesTabContent:FC = () => {
  return (
    <Container sx={{ pt: 4, pb: 4 }} maxWidth="md">
      <BillingInvoicesPage />
    </Container>
  )
}

export default BillingInvoicesTabContent