// Types
import Reducers from "app/types/Reducers";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get } from 'app/utilities/HttpClient';

const _url:string = '/management/billing/usage/aggregated';

// Get usage
export const getUsage = asyncThunkHandler(
  `${Reducers.ManagementBillingUsage}/Get usage`,
  async (params) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);
