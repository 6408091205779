// Types
import Strategy from "app/types/Strategy";
// Async
import { validateBatch } from "app/store/DMSBatches/DMSBatches.async";
// Strategies
import { validateBatchStrategy } from "app/features/mixpanel/MixpanelStrategies/DMSBatch.strategies";

const DMSBatcheStrategyMap:Record<string, Strategy> = {
  [validateBatch.fulfilled.type]: validateBatchStrategy,
};

export default DMSBatcheStrategyMap;