import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
import { SubscriptionStatus } from "./Subscriptions.types";
// Models
import { IState, ISubscription } from "./Subscriptions.models";
// Async
import { getSubscriptions, createSubscription, updateSubscription, cancelSubscription } from './Subscriptions.async';

const initialState:IState = {
  subscriptions: [],
  params: {
    accountId: 'all',
    subscriptionPackageId: 'all',
    status: SubscriptionStatus.Active
  }
};

const slice = createSlice({
  name: Reducers.Subscriptions,
  initialState,
  reducers: {
    setParams: (state, action:PayloadAction<any>) => {
      const params = action.payload;
      state.params = Object.keys(state.params).reduce((acc:any, cur:any) => {
        acc[cur] = (state.params as any)[cur];
        if ( typeof params[cur] !== 'undefined' ) acc[cur] = params[cur];
        return acc;
      }, {});
    },
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers(builder) {
    // Get subscriptions
    builder.addCase(getSubscriptions.pending, (state) => {
      state.subscriptions = [];
    });
    builder.addCase(getSubscriptions.fulfilled, (state, action:PayloadAction<ISubscription[]>) => {
      state.subscriptions = action.payload;
    });
    // Create subscription
    builder.addCase(createSubscription.fulfilled, (state, action:PayloadAction<ISubscription>) => {
      state.subscriptions = [...state.subscriptions, action.payload]
    });
    // Update subscription
    builder.addCase(updateSubscription.fulfilled, (state, action:PayloadAction<ISubscription>) => {
      state.subscriptions = state.subscriptions.map((subscription) => {
        if ( subscription.id === action.payload.id ) return action.payload;
        return subscription;
      });
    });
    // Cancel subscription
    builder.addCase(cancelSubscription.fulfilled, (state, action:PayloadAction<ISubscription>) => {
      if ( state.params.status === SubscriptionStatus.Active ){
        state.subscriptions = state.subscriptions.filter((subscription) => {
          return subscription.id !== action.payload.id
        });
      } else {
        state.subscriptions = state.subscriptions.map((subscription) => {
          if ( subscription.id === action.payload.id ) return action.payload;
          return subscription;
        });
      }
    });
  },
});

export const SubscriptionsActions = slice.actions;

export default slice.reducer;
