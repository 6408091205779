export enum ReferenceTypes {
  Account = 'account',
  ClientAccount = 'clientAccount',
  User = 'user',
  Case = 'case',
  Document = 'document',
  DocumentsExport = 'documentsExport',
  DocumentBatch = 'documentBatch',
  Template = 'template',
  Workflow = 'workflow'
};

export enum Dimension {
  Service = 'service',
  Account = 'account',
  SubscriptionPackage = 'subscriptionPackage'
};
