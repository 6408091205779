// Types
import Strategy from "app/types/Strategy";
// Async
import { createTemplate, deleteTemplate, updateTemplate } from "app/store/Templates/Templates.async";
// Actions
import MixpanelActions from "app/features/mixpanel/MixpanelActions";
// Strategies
import {
  createTemplateStrategy,
  updateTemplateStrategy,
  updateTemplateConflictStrategy,
  deleteTemplateStrategy,
  handleTemplateOpenedStrategy
} from "app/features/mixpanel/MixpanelStrategies/Template.strategies";

const TemplatesStrategyMap:Record<string, Strategy> = {
  [createTemplate.fulfilled.type]: createTemplateStrategy,
  [updateTemplate.fulfilled.type]: updateTemplateStrategy,
  [updateTemplate.rejected.type]: updateTemplateConflictStrategy,
  [deleteTemplate.fulfilled.type]: deleteTemplateStrategy,
  [MixpanelActions.handleTemplateOpened.type]: handleTemplateOpenedStrategy
};

export default TemplatesStrategyMap;
