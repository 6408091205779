import { ChangeEvent, FC, Fragment, useState, useCallback, useEffect } from 'react';
import { debounce } from 'throttle-debounce';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
// Types
import CaseStatuses from 'app/types/CaseStatuses';
import { Dimension } from 'app/store/BillingUsage/BillingUsage.types';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getInsuranceCases } from 'app/store/Cases/Cases.async';
// Actions
import { BillingUsageActions } from 'app/store/BillingUsage/BillingUsage.slice';
// Selectors
import { selectAccountsAsOptions } from 'app/store/Clients/Clients.selectors';
import { selectActiveSubscriptionsAsOptions, selectActiveSubscriptionServicesAsOptions } from 'app/store/BillingSubscriptions/BillingSubscriptions.selectors';
import { selectCaseOptions, selectCasesParams } from 'app/store/Cases/Cases.selectors';
import { selectParams, selectValidateDateRange } from 'app/store/BillingUsage/BillingUsage.selectors';
// Mui
import {
  Theme, Drawer, Typography, CircularProgress,
  Autocomplete as MuiAutocomplete, TextField,
  Divider,
  FormHelperText
} from '@mui/material';
// Components
import { Autocomplete, DesktopDatePicker, Select } from 'app/components/Mui';

import { Autocomplete as AutocompleteV2 } from 'app/components/Mui/Autocompletes';
// Constant
import { SIDEBAR_WIDTH } from 'app/App.constants';

const CostExplorerDrawer:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const accountOptions = useAppSelector(selectAccountsAsOptions);
  const subscriptionOptions = useAppSelector(selectActiveSubscriptionsAsOptions);
  const serviceOptions = useAppSelector(selectActiveSubscriptionServicesAsOptions);
  const caseOptions = useAppSelector(selectCaseOptions);
  const params = useAppSelector(selectParams);
  const validationErrors = useAppSelector(selectValidateDateRange);

  const caseParams = useAppSelector(selectCasesParams);

  const [ stateParams, setStateParams ] = useState(params);

  const debounceParams = useCallback(debounce(1000, (field:keyof typeof params, value:any) => {
    dispatch(BillingUsageActions.setParams({ [field]: value }));
    // eslint-disable-next-line
  }), []);

  const debounceGetCases = useCallback(debounce(500, (search:string) => {
    dispatch(getInsuranceCases({
      ...caseParams,
      search,
      status: `${CaseStatuses.Open},${CaseStatuses.Archived}`
    }))
  }), []);

  useEffect(() => {
    debounceGetCases('');
    // eslint-disable-next-line
  }, []);

  const handleChange = (field:keyof typeof params) => (newValue:any) => {
    // debounceParams(field, newValue);
    dispatch(BillingUsageActions.setParams({ [field]: newValue }));
  }

  const handleDateChange = (period:'start' | 'end') => (newValue:Dayjs | null) => {
    setStateParams((prevState:any) => ({
      ...prevState,
      [`period.${period}`]: newValue
    }));
    debounceParams(`period.${period}`, newValue);
  }

  const handleInputChange = (_:any, value:string) => {
    debounceGetCases(value);
  }

  const initialCaseOptions = caseOptions || [];
  const caseLoading = !initialCaseOptions;

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: SIDEBAR_WIDTH,
          position: 'relative',
          borderRadius: 0,
          border: 'initial',
          borderRight: (theme:Theme) => `1px solid ${theme.palette.divider}`,
          py: 2,
          px: 4
        }
      }}
      // Data attribute which explude block from print using CTRL/CMD + P
      data-print="exclude"
      open={true}
      variant="permanent"
      anchor="left"
    >
      <Select
        label="Breakdown" name="status" value={params.dimensions}
        onChange={(event:ChangeEvent<HTMLInputElement>) => handleChange('dimensions')(event.target.value)}
        options={[
          { id: Dimension.Service, name: 'Service' },
          { id: Dimension.Case, name: 'Case' },
          { id: Dimension.CaseAndService, name: 'Case + Service' },
        ]}
      />
      <Divider sx={{ mt: 4, mb: 2 }} />
      <Autocomplete
        label={t('labels.clientAccount')} value={params['reference:clientAccount']}
        onChange={handleChange('reference:clientAccount')}
        options={accountOptions || []}
        renderOptions={(props, option) => (
          <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.id}>
            <Typography variant="subtitle1">{option.name}</Typography>
            {option.props?.fullAddress ? (
              <Typography variant="caption">{option.props.fullAddress}</Typography>
            ) : null}
          </li>
        )}
      />
      <AutocompleteV2
        value={params.subscriptionId}
        onChange={handleChange('subscriptionId')}
        options={subscriptionOptions}
        TextFieldProps={{
          label: 'Subscription'
        }}
      />
      <AutocompleteV2
        value={params.serviceId}
        onChange={handleChange('serviceId')}
        options={serviceOptions}
        TextFieldProps={{
          label: 'Service'
        }}
      />
      <MuiAutocomplete
        value={params['reference:case'] as any}
        onChange={(_:any, newValue:any) => handleChange('reference:case')(newValue)}
        onInputChange={handleInputChange}
        options={caseOptions || []}
        loading={caseLoading}
        fullWidth
        renderInput={(params:any) => (
          <TextField
            {...params}
            label="Start typing to find a case"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {caseLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
            margin="normal"
            fullWidth
          />
        )}
        renderOption={(props:any, option:any) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        getOptionLabel={(option:any) => option.name}
        isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
      />
      <DesktopDatePicker
        label={t('labels.start')} name="dateRangeStart" value={stateParams['period.start']}
        onChange={handleDateChange('start')}
        inputProps={{
          error: Boolean(validationErrors && validationErrors.startError),
          helperText: validationErrors?.startError || ''
        }}
      />
      <DesktopDatePicker
        label={t('labels.end')} name="dateRangeEnd" value={stateParams['period.end']}
        onChange={handleDateChange('end')}
        inputProps={{
          error: Boolean(validationErrors && validationErrors.endError),
          helperText: validationErrors?.endError || ''
        }}
      />
      {validationErrors && validationErrors.rangeError ? (
        <FormHelperText error>{validationErrors.rangeError}</FormHelperText>
      ) : null}
    </Drawer>
  )
}

export default CostExplorerDrawer