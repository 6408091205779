import { FC, Fragment, useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Models
import IInsuranceCase from 'app/models/Case';
import { IFileError } from 'app/store/DMSBatches/DMSBatches.models';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createBatch, validateBatch } from 'app/store/DMSBatches/DMSBatches.async';
import { createFileUploadToken, createPreSignedUrlForFile, uploadFileByPreSignedUrl } from 'app/store/DMSUploads/DMSUploads.async';
// Actions
import { DMSBatchesActions } from 'app/store/DMSBatches/DMSBatches.slice';
import { DMSUploadsActions } from 'app/store/DMSUploads/DMSUploads.slice';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectPreSignedUrls } from 'app/store/DMSUploads/DMSUploads.selectors';
// Mui
import { Theme, Box } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button, LoadingButton } from 'app/components/Mui';
// 
import CommentControl from './CommentControl';
import FilesUploadAlert from './FilesUploadAlert';
import FilesUploader from './FilesUploader';
import FilesUploadHints from './FilesUploadHints';
import UploadDocumentsProvider, { IFileItem } from './UploadFiles.provider';

interface IFormData {
  comment: string;
  files: IFileItem[];
}

type Props = {
  open: boolean;
  onClose: () => void;
};

let token:any = null;

const CaseUploadDocumentsFormDialog:FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const preSignedUrls = useAppSelector(selectPreSignedUrls);

  const methods = useForm<IFormData>({
    defaultValues: {
      comment: '',
      files: []
    }
  });

  const [ loading, setLoading ] = useState(false);
  const [ uploadedFilesLength, setUploadedFilesLength ] = useState(0);

  useEffect(() => {
    return () => {
      token = null;

      dispatch(DMSBatchesActions.setInitialField('filesErrors'));
      dispatch(DMSUploadsActions.setInitialField('preSignedUrls'));
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = methods.handleSubmit(async (data:IFormData) => {
    const { comment, files = [] } = data;

    if (
      !files.length ||
      files.some((fileItem) => !fileItem.collectionId || fileItem.fileError)
    ) return;

    const s3Errors:(boolean | null)[] = [];
    const nextData:any = { caseId: insuranceCase.id, files: [] };

    if ( comment ) nextData['comment'] = comment;

    setLoading(true);
    setUploadedFilesLength(0);

    try {
      if ( !token ){
        const response = await dispatch(createFileUploadToken({ caseId: insuranceCase.id })).unwrap();
        token = response.token;
      }

      nextData['token'] = token;

      for ( let i = 0; i < files.length; i++ ){
        const { id, file, type, collectionId, comment } = files[i];
        const fileData:any = { name: file.name, type };

        if ( comment ) fileData['annotations'] = [{ name: 'comment', value: comment }];

        const preSignedData:any = {
          token,
          file: {
            name: file.name,
            contentType: file.type
          },
          fileType: type
        };
        if ( collectionId ){
          fileData['collectionId'] = Number(collectionId);
          preSignedData['collectionId'] = Number(collectionId);
        }

        if ( !Object.keys(preSignedUrls).length || !preSignedUrls[id] ){
          const { url } = await dispatch(createPreSignedUrlForFile(preSignedData)).unwrap();
          const s3Error = await dispatch(uploadFileByPreSignedUrl({ url, file, index: i })).unwrap();
          if ( s3Error ){
            s3Errors[i] = true;
          }
          dispatch(DMSUploadsActions.addPreSignedUrl({ id, url }));
        } else {
          s3Errors[i] = null;
        }

        nextData.files = [...nextData.files, fileData];
        setUploadedFilesLength((prevState:number) => prevState + 1);
      }

      if ( s3Errors.some((error:boolean | null) => error) ) return;
      const errors = await dispatch(validateBatch(nextData)).unwrap();
      if ( errors.some((error:IFileError | null) => error) ) return;
      await dispatch(createBatch(nextData)).unwrap();
      onClose();
    } catch(error){
      console.error(error);
    } finally {
      setLoading(false);
    }
  });

  const actions = (
    <Fragment>
      <Button
        name="Cancel batch dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name="Upload batch dialog"
        loading={loading}
        onClick={onSubmit}
        color="primary"
        variant="contained"
      >{t('labels.upload')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      title={t('dialogs.batchCreate.uploadCaseMaterials')}
      actions={actions}

      PaperProps={{
        sx: {
          height: '100%',
          '& .MuiDialogContent-root': {
            borderTop: (theme:Theme) => `1px solid ${theme.palette.divider}`,
            borderBottom: (theme:Theme) => `1px solid ${theme.palette.divider}`,
            py: '24px !important'
          }
        }
      }}

      ContentProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <FormProvider {...methods}>
        <UploadDocumentsProvider isSubmitted={methods.formState.isSubmitted}>
          <input {...methods.register('files', { required: true })} type="hidden" />

          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column'
            }}
            component="form"
            noValidate
          >
            <CommentControl />
            <FilesUploadAlert loading={loading} />
            <FilesUploader loading={loading} uploadedFilesLength={uploadedFilesLength} />
            <FilesUploadHints />
          </Box>
        </UploadDocumentsProvider>
      </FormProvider>
    </Dialog>
  )
}

export default CaseUploadDocumentsFormDialog;
