// Types
import Strategy from 'app/types/Strategy';
import MixpanelEvents from 'app/features/mixpanel/MixpanelEvents';
// Models
import IReportPreset from 'app/models/ReportPreset';
// Services
import Mixpanel from "app/services/Mixpanel.service";

export const createPresetStrategy:Strategy = (payload, { userId, accountId }) => {
  const reportPreset = payload as IReportPreset;
  Mixpanel.track(MixpanelEvents.ReportPresetCreated, {
    accountId,
    userId,
    presetId: reportPreset.id,
  });
};

export const updatePresetStrategy:Strategy = (payload, { userId, accountId }) => {
  const reportPreset = payload as IReportPreset;
  Mixpanel.track(MixpanelEvents.ReportPresetUpdated, {
    accountId,
    userId,
    presetId: reportPreset.id,
  });
};

export const deletePresetStrategy:Strategy = (payload, { userId, accountId }) => {
  const presetId = payload as number;
  Mixpanel.track(MixpanelEvents.ReportPresetDeleted, {
    accountId,
    userId,
    presetId,
  });
};

export const handleReportPresetOpenedStrategy: Strategy = (payload, { userId, accountId }) => {
  const presetId = payload as number;
  Mixpanel.track(MixpanelEvents.ReportPresetOpened, {
    accountId,
    userId,
    presetId,
  });
};

export const handleReportPresetSelectedStrategy:Strategy = (payload, { userId, accountId }) => {
  const presetId = payload as number;
  Mixpanel.track(MixpanelEvents.ReportPresetSelected, {
    accountId,
    userId,
    presetId,
  });
};
