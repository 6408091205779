import { FC, useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
// Types
import { TemplateDataType, TemplateLayout, TemplateType } from 'app/types/Template';
// Models
import ITemplate from 'app/models/Template';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createTemplate, getTemplate, updateTemplate } from 'app/store/Templates/Templates.async';
// Actions
import MixpanelActions from 'app/features/mixpanel/MixpanelActions';
import { TemplatesActions } from 'app/store/Templates/Templates.slice';
// Selectors
import { selectTemplate, selectWizardFormInfo } from 'app/store/Templates/Templates.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import CaseInfoBar from 'app/components/CaseInfoBar';
// 
import DialogTitle from './DialogTitle';
import DialogContainer from './DialogContainer';

interface IFormData {
  type: TemplateType | null;
  dataType: TemplateDataType | null;
  layout: TemplateLayout | null;
  name: string;
  content: string;
  preContent: string;
  postContent: string;
  document: {
    collectionIds: number[];
  };
  page: {
    tags: string[];
    colors: string[]
  };
  episode: {
    preEvent: string;
    authorIds: number[];
    labels: string[];
    types: string[];
  };
  tableConfig: {
    columns: { header:string, data:string, width:number }[]
  };
  copyToNewCases: boolean;
}

type Props = {
  open: boolean;
  onClose: () => void;
  globalTemplateId?: number;
  templateId?: number;
  caseId?: number;
}

const TemplateFormDialog:FC<Props> = ({
  // Props
  open, onClose, globalTemplateId, templateId, caseId
}) => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const template:ITemplate | null = useAppSelector(selectTemplate);
  const wizardFormInfo = useAppSelector(selectWizardFormInfo);

  const methods = useForm<IFormData>({
    defaultValues: {
      type: wizardFormInfo.type || null,
      dataType: wizardFormInfo.dataType || null,
      layout: wizardFormInfo.layout || null,
      name: '',
      content: wizardFormInfo.layout === TemplateLayout.Custom
        ? wizardFormInfo.dataType === TemplateDataType.Episode
          ? `<p>${toSpan('{{ episode:dateTime }}')}\t<strong>${toSpan('{{ episode:type }}')}</strong>: ${toSpan('{{ episode:author }}')}</p><p>${toSpan('{{ episode:notes }}')}</p>`
          : `<p>${toSpan('{{ document:createdOn }}')} ${toSpan('{{ document:name }}')} ( ${toSpan('{{ document:numberOfPages }}')} pages )</p>`
        : ''
      ,
      preContent: '',
      postContent: '',

      document: {
        collectionIds: []
      },

      page: {
        tags: [],
        colors: [],
      },
      episode: {
        preEvent: 'all',
        authorIds: [],
        labels: [],
        types: []
      },

      tableConfig: {
        columns: wizardFormInfo.layout === TemplateLayout.Table
          ? wizardFormInfo.dataType === TemplateDataType.Episode
            ? [
                { header: '<strong>Date/Time</strong>', data: toSpan('{{ episode:dateTime }}'), width: 16.66 },
                { header: '<strong>Type</strong>', data: toSpan('{{ episode:type }}'), width: 16.66 },
                { header: '<strong>Author</strong>', data: toSpan('{{ episode:author }}'), width: 16.66 },
                { header: '<strong>Notes</strong>', data: toSpan('{{ episode:notes }}'), width: 50 }
              ]
            : [
              { header: '<strong>Created On</strong>', data: toSpan('{{ document:createdOn }}'), width: 33.33 },
              { header: '<strong>Name</strong>', data: toSpan('{{ document:name }}'), width: 33.33 },
              { header: '<strong>Number of pages</strong>', data: toSpan('{{ document:numberOfPages }}'), width: 33.33 },
            ]
          : []
      },
      copyToNewCases: false
    }
  });

  const { register, handleSubmit, reset } = methods;

  const onSubmit = handleSubmit((data:IFormData) => {
    const { preContent, postContent, content, document, page, episode, tableConfig, ...otherData } = data;

    const nextData:any = otherData;
    const documentFilter:any = {};
    const pageFilter:any = {};
    const episodeFilter:any = {};

    if ( globalTemplateId ) nextData['globalTemplateId'] = globalTemplateId;
    if ( caseId ) nextData['caseId'] = caseId;
    if ( content ) nextData['content'] = content;
    if ( preContent ) nextData['preContent'] = preContent;
    if ( postContent ) nextData['postContent'] = postContent;

    if ( otherData.dataType === TemplateDataType.Document ){
      if ( document && Object.keys(document).length ){
        let key:keyof typeof document;
        for ( key in document ){
          const value = document[key];
          if ( value && value.length ) documentFilter[key] = value;
        }
      }

      if ( Object.keys(documentFilter) ){
        nextData['filter'] = {};
        if ( Object.keys(documentFilter).length ) nextData['filter']['document'] = documentFilter;
      }
    }
    if ( otherData.dataType === TemplateDataType.Episode ){
      if ( page && Object.keys(page).length ){
        let key:keyof typeof page
        for ( key in page ){
          const value = page[key];
          if ( value && value.length ) pageFilter[key] = value;
        }
      }
      if ( episode && Object.keys(episode).length ){
        let key:keyof typeof episode
        for ( key in episode ){
          let value = episode[key];

          if ( key === 'preEvent' ){
            if ( value === 'all' ) continue;
            episodeFilter[key] = typeof value === 'string'
              ? value === 'true' ? true : false
              : value
            ;
          } else {
            if ( value && value.length ) episodeFilter[key] = value;
          }
        }
      }

      if ( Object.keys(documentFilter) || Object.keys(pageFilter) || Object.keys(episodeFilter) ){
        if ( nextData.filter === undefined ){
          nextData['filter'] = {};
        }
        if ( Object.keys(documentFilter).length ) nextData['filter']['document'] = documentFilter;
        if ( Object.keys(pageFilter).length ) nextData['filter']['page'] = pageFilter;
        if ( Object.keys(episodeFilter).length ) nextData['filter']['episode'] = episodeFilter;
      }
    }

    if ( otherData.layout === TemplateLayout.Table ){
      if ( !tableConfig.columns.length ) return;
      nextData['tableConfig'] = tableConfig;
    }

    if ( template && !globalTemplateId ){
      nextData['version'] = template.version;
      dispatch(updateTemplate({ templateId: template.id, data: nextData }));
    } else {
      dispatch(createTemplate(nextData));
    }
  });

  const [ rendered, setRendered ] = useState(false);

  useEffect(() => {
    if ( templateId ){
      dispatch(MixpanelActions.handleTemplateOpened({ templateId }));
      dispatch(getTemplate(templateId));
    } else {
      setRendered(true);
    }
    return () => {
      dispatch(TemplatesActions.setInitialField('template'))
      dispatch(TemplatesActions.setInitialField('wizardForm'))
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( !template ) return;

    const templateFormData:any = {
      type: template.type,
      dataType: template.dataType || null,
      layout: template.layout || null,

      name: template.name,
      content: template.content || '',
      preContent: template.preContent || '',
      postContent: template.postContent || '',

      document: template.filter?.document || {},
      page: template.filter?.page || {},
      episode: template.filter?.episode || {},
      tableConfig: template.tableConfig || {
        columns: []
      },
      copyToNewCases: typeof template.copyToNewCases !== 'undefined' ? template.copyToNewCases : false
    };

    reset(templateFormData);

    setRendered(true);
    // eslint-disable-next-line
  }, [template?.id]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      PaperProps={{ sx: { border: 0 } }}
      ContentProps={{ sx: { border: 0, p: 0 } }}
    >
      <FormProvider {...methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            position: 'relative'
          }}
          component="form"
          onSubmit={onSubmit}
          noValidate
        >
          <input {...register('type') as any} type="hidden" />
          <input {...register('dataType') as any} type="hidden" />
          <input {...register('layout') as any} type="hidden" />
          <input {...register('tableConfig') as any} type="hidden" />

          <DialogTitle globalTemplateId={globalTemplateId} templateId={templateId ? templateId : template?.id} onClose={onClose} />
          <CaseInfoBar />
          <DialogContainer rendered={rendered} />
        </Box>
      </FormProvider>
    </Dialog>
  )
}

export default TemplateFormDialog;

const toSpan = (value:string) => {
  return `<var class="ql-variable">${value}</var>`;
}
