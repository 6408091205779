import { Fragment, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { AuditOperations } from 'app/store/Audit/Audit.types';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createAuditRecord } from 'app/store/Audit/Audit.async';
import { exportPagesToPDF } from 'app/store/Presenter/Presenter.async';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectLoading } from 'app/store/Presenter/Presenter.selectors';
// Components
import Dialog from 'app/components/Dialog';
import { Button, LoadingButton, Checkbox } from 'app/components/Mui';
import { selectFilteredWorkspacePagesIds } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Services
import PageService from 'app/services/PageService';

type Props = {
  open: boolean;
  onClose: () => void;
  pageIds?: string[] | null;
};

const ExportPagesToPDFDialog:FC<Props> = ({
  // Props
  open, onClose, pageIds
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser = useAppSelector(selectMyUser);
  const insuranceCase = useAppSelector(selectInsuranceCase);
  const workspacePageIds = useAppSelector(selectFilteredWorkspacePagesIds);
  const loading = useAppSelector(selectLoading);

  const [ checked, setChecked ] = useState<boolean>(false);

  const handleClick = async () => {
    const pagesToExport = [...(pageIds || workspacePageIds || [])].map((pageId:string) => {
      const [ documentId, pageNum ] = PageService.fromPageId(pageId);
      return { documentId, pageNum }
    });
    try {
      await dispatch(exportPagesToPDF(pagesToExport)).unwrap();
      if ( myUser && insuranceCase ){
        dispatch(createAuditRecord({
          operation: AuditOperations.ExportPagesToPDF,
          data: {
            caseId: insuranceCase.id,
            userName: myUser.name 
          }
        }));
      }
      onClose();
    } catch(error){}
  }

  const actions = (
    <Fragment>
      <Button
        name="Cancel export pages to pdf dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name="Submit export pages to pdf dialog"
        onClick={handleClick}
        loading={loading}
        disabled={!checked}
        variant="contained"
        color="primary"
      >{t('labels.export')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('dialogs.exportPagesToPdf.exportPagesToPdf')}
      actions={actions}
    >
      <Checkbox
        name="Export pages to pdf permission"
        label={t('dialogs.exportWorkspacePagesToPdf.checkLabel')}
        value={checked}
        onChange={(_:any, checked:boolean) => setChecked(checked)}
      />
    </Dialog>
  )
}

export default ExportPagesToPDFDialog;
