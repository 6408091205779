import { FC, useMemo } from 'react';
// Types
import { Dimension } from 'app/store/BillingUsage/BillingUsage.types';
// Models
import { IUsage, IGroupByCaseAndService } from 'app/store/BillingUsage/BillingUsage.models';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Async
import { getUsage } from 'app/store/BillingUsage/BillingUsage.async';
// Selectors
import { selectParams, selectGroupByUsage, selectValidateDateRange } from 'app/store/BillingUsage/BillingUsage.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import DataLoader from 'app/components/DataLoader';
// 
import CostExplorerDrawer from './CostExplorerDrawer';
import UsageGroupByService from './UsageGroupByService';
import UsageGroupByCase from './UsageGroupByCase';
import UsageGroupByCaseAndService from './UsageGroupByCaseAndService';
import UsageNoDataFound from './UsageNoDataFound';

const CostExplorerTabContent:FC = () => {
  // State
  const params = useAppSelector(selectParams);
  const validationErrors = useAppSelector(selectValidateDateRange);

  const formattedParams = useMemo(() => {
    const formattedStart = params['period.start'] ? params['period.start'].format('YYYY-MM-DD') : null;
    const formattedEnd = params['period.end'] ? params['period.end'].add(1, 'day').format('YYYY-MM-DD') : null;
    return {
      ...params,
      'reference:case': params['reference:case'] ? (params['reference:case'] as any).id : null,
      'period.start': formattedStart,
      'period.end': formattedEnd
    };
  }, [params]);

  return (
    <Box sx={{ height: '100%', display: 'flex' }}>
      <CostExplorerDrawer />
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden', p: 4 }}>
        <DataLoader
          asyncThunkFn={validationErrors ? undefined : getUsage}
          params={formattedParams}
          selector={selectGroupByUsage}
          noData={<UsageNoDataFound />}
        >
          {( data ) => (
            (() => {
              switch (params.dimensions) {
                case Dimension.Service:
                  return <UsageGroupByService data={data as IUsage[]} />
                case Dimension.Case:
                  return <UsageGroupByCase data={data as IUsage[]} />
                default:
                  return <UsageGroupByCaseAndService data={data as IGroupByCaseAndService[]} />
              }
            })()
          )}
        </DataLoader>
      </Box>
    </Box>
  )
}

export default CostExplorerTabContent;
