import { FC, useMemo } from 'react';
// Models
import { IUsageInvoice } from 'app/store/BillingUsage/BillingUsage.models';
// Mui
import { Box, Tooltip } from '@mui/material';
// Icons
import { Receipt as ReceiptIcon } from '@mui/icons-material';

type Props = {
  invoices: IUsageInvoice[] | undefined;
}

const DraftInvoiceIcon:FC<Props> = ({
  // Props
  invoices
}) => {
  const hasAnyDraft = useMemo(() => {
    if ( !invoices || !invoices.length ) return false;
    return invoices.some((invoice) => invoice.status === 'draft');
  }, [invoices]);
  if ( !hasAnyDraft ) return null;
  return (
    <Tooltip title="Includes services delivered during the current billing period that will be reflected in next month's invoice">
      <Box sx={{
        display: 'flex',
        color: 'warning.light'
      }}><ReceiptIcon fontSize="small" /></Box>
    </Tooltip>
  )
}

export default DraftInvoiceIcon