import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// Types
import RefineStopReasons from 'app/types/RefineStopReasons';
// Models
import { IMessage } from 'app/models/ChatAI';
// Mui
import { Theme, Box } from '@mui/material';
import { useMessageContext } from './Message.context';

type Props = {
  message: IMessage;
  showRefineText: boolean;
  percents: number;
  roundedPercents: number;
  roundedRefinePercents: number;
};

const MessageRelevantText:FC<Props> = ({
  // Props
  message, showRefineText, percents, roundedPercents, roundedRefinePercents
}) => {
  const { t } = useTranslation('common');

  const { refineMessages } = useMessageContext();

  const lastMessageMaxTokenReached = useMemo(() => {
    const lastMessage = refineMessages[refineMessages.length - 1];
    return lastMessage.metadata?.refineStopReason === RefineStopReasons.MaxTokens;
  }, [refineMessages]);

  const showExtendedTextLastMessage = useMemo(() => {
    if ( !showRefineText ) return false;
    const lastMessage = refineMessages[refineMessages.length - 1];
    if ( lastMessage.metadata?.refineStopReason === RefineStopReasons.MaxTokens || percents === 100 ) return false;
    // Show extend text if lastMessage is same as current selected message
    return message.id === lastMessage.id;
    // eslint-disable-next-line
  }, [lastMessageMaxTokenReached, message, showRefineText, refineMessages, percents]);

  if ( !percents ) return null;
  return (
    <Box
      sx={{
        border: (theme:Theme) => `2px solid ${theme.palette.primary.main}`,
        bgcolor: (theme:Theme) => theme.palette.primary.light,
        borderRadius: '0 0 8px 8px',
        fontSize: 11,
        px: 4,
        py: 2
      }}
    >{`${t('pages.staffPages.caseDetailsPage.basedOnRelevantDocument', { percents: roundedPercents })}`} {
      showExtendedTextLastMessage
      ? t('pages.staffPages.caseDetailsPage.refineHint', { moreDocumentPagesPercents: roundedRefinePercents })
      : ''
    }</Box>
  )
}

export default MessageRelevantText;
