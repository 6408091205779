import './CardPage.styles.css';

import { CSSProperties, FC } from 'react';
import LazyLoad from 'react-lazyload';
// Models
import { RootState } from 'app/store';
import { IPresenterPage } from 'app/models/Page';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectPageEntity } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Services
import PageService from 'app/services/PageService';
// 
import CardPageLazyPlaceholder from './CardPageLazyPlaceholder';
import CardPageColors from './CardPageColors';
import CardPageHead from './CardPageHead';
import CardPageBody from './CardPageBody';
import CardPageFoot from './CardPageFoot';

type Props = {
  style?: CSSProperties;
  pageId: string;
  index?: number;
}

const CardPage:FC<Props> = ({
  // Props
  style, pageId, index
}) => {
  const [ documentId, pageNum ] = PageService.fromPageId(pageId);
  // State
  const page:IPresenterPage | undefined = useAppSelector((state:RootState) => selectPageEntity(state, { documentId, pageNum }));

  if ( !page ) return null;
  return (
    <LazyLoad
      classNamePrefix="card-page"
      placeholder={
        <CardPageLazyPlaceholder
          documentId={page.documentId}
          pageNum={page.pageNum}
          index={index}
        />
      }
      scrollContainer={'.react_scrollPageList'}
      height={385}
      debounce={false}
      throttle={250}
    >
      <div className="cp" style={style}>
        <CardPageColors pageId={pageId} colors={page.colors} />
        <CardPageHead page={page} index={index} />
        <CardPageBody page={page} />
        <CardPageFoot pageId={pageId} />
      </div>
    </LazyLoad>
  )
}

export default CardPage;
