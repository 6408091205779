
import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
// Types
import UserRoles from 'app/types/UserRoles';
// Models
import { AppDispatch } from 'app/store';
// Selectors
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectConversation } from 'app/store/AIChat/AIChat.selectors';
// Strategies
import MixpanelStrategyMap from 'app/features/mixpanel/MixpanelStrategyMap';

const MixpanelMiddleware:Middleware = ({ getState }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type, payload, meta } = action;

  const state = getState();
  const myUser = selectMyUser(state);

  // Skipped Mixpanel actions for `Acount manager`
  if ( myUser?.role === UserRoles.AccountManager ) return next(action);

  const strategy = MixpanelStrategyMap[type];

  if ( strategy ){
    const insuranceCase = selectInsuranceCase(state);
    const currentAccount = selectCurrentAccount(state);
    const conversation = selectConversation(state);
  
    // Execute the strategy with necessary context
    strategy(payload || meta.arg, {
      userId: myUser?.id,
      accountId: currentAccount?.id,
      caseId: insuranceCase?.id,
      conversationId: conversation?.id
    });
  }

  return next(action);
}

export default MixpanelMiddleware;
