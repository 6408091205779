import { FC, ReactNode } from 'react';
import { Droppable } from 'react-beautiful-dnd';
// Mui
import { alpha, Theme, Box } from '@mui/material';

type Props = {
  droppableId: string;
  children: ReactNode;
};

const ContentDroppable:FC<Props> = ({
  // Props
  droppableId, children
}) => {
  return (
    <Droppable droppableId={droppableId} direction="vertical">
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.droppableProps}
          sx={{
            flexGrow: 1,
            bgcolor: (theme:Theme) => snapshot.draggingOverWith ? alpha(theme.palette.primary.light, 0.05) : 'initial',
            overflowY: 'auto'
          }}
        >
          {children}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  )
}

export default ContentDroppable;