import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from 'dayjs';
// Types
import Reducers from "app/types/Reducers";
// Models
import IBillingInvoice from "app/models/BillingInvoice";
// Async
import { getInvoices, getInvoicesSummary, updateInvoice, payInvoice, exportInvoicesToPDF } from './BillingInvoices.async';

interface IState {
  invoices: any;
  invoicesSummary: any;
  params: {
    accountId: string | null;
    search: string;
    status: string;
    dateRangeStart: Dayjs | null;
    dateRangeEnd: Dayjs | null;
    limit: number;
    offset: number;
  },
  hasMore: boolean;
  gettingMore: boolean;
  batchInvoicesIds: number[];
  loading: boolean;
};

const initialState:IState = {
  invoices: null,
  invoicesSummary: null,
  params: {
    accountId: null,
    search: '',
    status: 'sent',
    dateRangeStart: dayjs().startOf('year'),
    dateRangeEnd: dayjs(),
    limit: 100,
    offset: 0
  },
  hasMore: false,
  gettingMore: false,
  batchInvoicesIds: [],
  loading: false
};

const slice = createSlice({
  name: Reducers.BillingInvoices,
  initialState,
  reducers: {
    toggleBatchInvoiceId: (state, action:PayloadAction<number>) => {
      const invoiceId = action.payload;
      state.batchInvoicesIds = state.batchInvoicesIds.includes(invoiceId)
        ? state.batchInvoicesIds.filter((id:number) => id !== invoiceId)
        : [...state.batchInvoicesIds, invoiceId]
      ;
    },
    selectAllSentBatchInvoicesIds: (state) => {
      if ( state.invoices ){
        state.batchInvoicesIds = state.invoices
          .filter((invoice:any) => invoice.status === 'sent')
          .map((invoice:any) => invoice.id)
      }
    },
    setParams: (state, action:PayloadAction<any>) => {
      const params = action.payload;
      state.params = Object.keys(state.params).reduce((acc:any, cur:any) => {
        acc[cur] = (state.params as any)[cur];
        if ( typeof params[cur] !== 'undefined' ) acc[cur] = params[cur];
        return acc;
      }, {});
    },

    setInvoicesStatusPaid: (state, action:PayloadAction<number[]>) => {
      if ( state.invoices ){
        state.invoices = state.invoices.map((invoice:IBillingInvoice) => {
          if ( action.payload.includes(invoice.id) ) return { ...invoice, status: 'paid' }
          return invoice;
        });
      }
      state.batchInvoicesIds = initialState['batchInvoicesIds'];
    },

    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers(builder) {
    // Get invoices
    builder.addCase(getInvoices.pending, (state, action:any) => {
      const { offset } = action.meta.arg;

      if ( offset === 0 ){
        state.invoices = null;
      } else {
        state.params.offset = offset;
        state.gettingMore = true;
      }
    });
    builder.addCase(getInvoices.fulfilled, (state, action:PayloadAction<{ data:IBillingInvoice[], total:number }>) => {
      const { data } = action.payload;
      state.invoices = state.invoices ? [...state.invoices, ...data] : data;
      // state.invoicesTotal = total;
      state.hasMore = data.length === state.params.limit;
      state.gettingMore = false;
    })
    // Get invoices summary
    builder.addCase(getInvoicesSummary.pending, (state) => {
      state.invoicesSummary = null;
    });
    builder.addCase(getInvoicesSummary.fulfilled, (state, action:PayloadAction<any>) => {
      state.invoicesSummary = action.payload;
    })
    // Update package
    builder.addCase(updateInvoice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateInvoice.fulfilled, (state, action:PayloadAction<IBillingInvoice>) => {
      if ( state.invoices ){
        state.invoices = state.invoices.map((invoice:IBillingInvoice) => {
          if ( invoice.id === action.payload.id ) return action.payload;
          return invoice;
        })
      }
    })
    // Pay invoice
    builder.addCase(payInvoice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(payInvoice.fulfilled, (state, action:PayloadAction<IBillingInvoice>) => {
      if ( state.invoices ){
        state.invoices = state.invoices.map((invoice:IBillingInvoice) => {
          if ( invoice.id === action.payload.id ) return action.payload;
          return invoice;
        })
      }
    })
    // Export invoices
    builder.addCase(exportInvoicesToPDF.pending, (state) => {
      state.loading = true;
    })

    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  },
});

export const BillingInvoicesActions = slice.actions;

export default slice.reducer;
