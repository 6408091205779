import { FC, Fragment } from 'react';
// Types
import UserRoles from 'app/types/UserRoles';
// Models
import { RootState } from 'app/store';
import { IMyUser } from 'app/models/User';
import { IBatch } from 'app/store/DMSBatches/DMSBatches.models';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { patchBatch, downloadCoverLetter, deleteCoverLetter } from 'app/store/DMSBatches/DMSBatches.async';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectLoading, selectBatchTotalPages } from 'app/store/DMSBatches/DMSBatches.selectors';
// Mui
import {
  Theme,
  Box,
  ListSubheader, Typography
} from '@mui/material';
// Icons
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Components
import Icon from 'app/components/Icon';
import { Bull } from 'app/components/Utilities';
import { IconButton } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
import useMenu from 'app/hooks/useMenu';
import useLongDateTime from 'app/hooks/useLongDateTime';
// i18next
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  batch: IBatch;
}

const BatchItemSubhead:FC<Props> = ({
  // Props
  batch
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser:IMyUser | null = useAppSelector(selectMyUser);
  const loading = useAppSelector(selectLoading);
  const totalPages:number = useAppSelector((state:RootState) => selectBatchTotalPages(state, {
    documentIds: batch.documentIds
  }));

  const { open, toggle } = useToggle();
  const { Menu, MenuItem, openMenu } = useMenu();

  const longCreatedOn = useLongDateTime({
    dateValue: batch.createdOn,
    displayTime: true,
    local: true
  });

  const handlePatchBatch = () => {
    dispatch(patchBatch({ batchId: batch.id, data:{ additional: !batch.additional } }));
  }

  const handleGetCoverLetter = () => {
    dispatch(downloadCoverLetter(batch.id));
  }

  const handleConfirm = async () => {
    try {
      await dispatch(deleteCoverLetter(batch.id)).unwrap();
      toggle();
    } catch(error){}
  }

  const isMyRoleAdmin = myUser?.role === UserRoles.Admin;
  const additionalLabel = batch.additional ? t('labels.initial') : t('labels.additional');

  return (
    <Fragment>
      <ListSubheader
        sx={{
          display: 'flex',
          alignItems: 'center',
          bgcolor: 'rgb(238,238,238)',
          py: 1
        }}
      >
        <Box>
          {batch.additional ? <Icon icon="additional" /> : <Icon icon="batch" />}
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          px: 2
        }}>
          <Typography
            sx={{
              display: 'flex',
              flexDirection: 'column',
              fontWeight: 700
            }}
            color="CaptionText"
            variant="subtitle2"
          >
            <span>{longCreatedOn}</span>
            {totalPages ? (
              <Fragment>
                <span>{totalPages} {t('labels.pages')}</span>
              </Fragment>
            ) : null}
          </Typography>
          <Typography sx={{ wordBreak: 'break-all' }} variant="body2">
            {batch.additional ? <Typography component="span" variant="body2" color="red">{t('labels.additional')}</Typography> : null}
            {batch.additional && batch.comment ? <Bull /> : null}
            {batch.comment ? <span>{batch.comment}</span> : null}
          </Typography>
        </Box>
        <Box>
          <IconButton
            name={`Batch dropdown ${batch.id}`}
            aria-label="Batch item dropdown button"
            onClick={openMenu}
            edge="end"
          ><MoreVertIcon /></IconButton>
          <Menu>
            {batch.coverLetterFile?.name ? (
              <MenuItem
                name={`Open cover letter ${batch.id}`}
                onClick={handleGetCoverLetter}
              >{t('layouts.filesSidebar.openCoverLetter')}</MenuItem>
            ) : null}
            <MenuItem
              name={`Mark batch as ${additionalLabel} ${batch.id}`}
              onClick={handlePatchBatch}
            >{t('layouts.filesSidebar.markBatchAs', { additionalLabel })}</MenuItem>
            {isMyRoleAdmin && batch.coverLetterFile?.name ? (
              <MenuItem
                name={`Delete cover letter ${batch.id}`}
                sx={{
                  color: (theme:Theme) => theme.palette.error.main
                }}
                onClick={toggle}
              >{t('layouts.filesSidebar.deleteCoverLetter')}</MenuItem>
            ) : null}
          </Menu>
        </Box>
      </ListSubheader>
      {open ? (
        <ConfirmationDialog
          open={open}
          onClose={toggle}
          onConfirm={handleConfirm}
          loading={loading}
          title={t('layouts.filesSidebar.deleteCoverLetter')}
          content={(
            <Trans
              t={t} i18nKey="layouts.filesSidebar.deleteCoverLetterConfirmation"
              components={{ strong: <strong /> }}
              values={{ fileName: batch.coverLetterFile?.name }}
            />
          )}
        />
      ) : null}
    </Fragment>
  )
}

export default BatchItemSubhead;
