// Types
import Reducers from "app/types/Reducers";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get } from 'app/utilities/HttpClient';

const _url:string = '/billing/subscriptions';

// Get subscriptions
export const getSubscriptions = asyncThunkHandler(
  `${Reducers.BillingSubscriptions}/Get subscriptions`,
  async (params) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

