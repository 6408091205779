import { ReactNode, FC, useState, useEffect, Fragment } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Models
import { ICurrentAccount } from 'app/models/Account';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getTeams } from 'app/store/Teams/Teams.async';
import { updateCurrentAccount } from 'app/store/Accounts/Accounts.async';
// Selectors
import { selectCurrentAccount, selectLoading, selectHcpcsCodesEnabled } from 'app/store/Accounts/Accounts.selectors';
// Mui
import {
  SxProps, Theme,
  Box, Container, Grid, Paper, Toolbar,
  Typography,
  Stepper, Step, StepLabel
} from '@mui/material';
// Icons
import {
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  Error as ErrorIcon
} from '@mui/icons-material';
// Component
import { Button, LoadingButton } from 'app/components/Mui';
// 
import GeneralTabContent from './GeneralTabContent';
import LocalizationTabContent from './LocalizationTabContent';
import ReportTabContent from './ReportTabContent';
import WorkflowsTabContent from './WorkflowsTabContent';
import MedicareTabContent from './MedicareTabContent';
import PageTagsTabContent from './PageTagsTabContent';

interface IFormData {
  company: string;
  email: string;
  phone: string;
  onboardingTeamId: string;
  address: {
    line: string;
    city: string;
    state: string;
    zipCode: string;
  };
  settings: {
    recordsLanguage: string;
    language: string;
    timeZone: string;
    dateFormat: string;
    timeFormat: string;
    medicareOverchargeThreshold: number;
    reportPreferences: {
      fontFamily: string;
      fontSize: number;
    };
    caseStatusTransitionRules: {
      status: 'open';
      triggerEvent: 'none' | 'documentCreated';
      filter: {
        collectionId: number[];
      };
    };
  };
  billingEmail: string;
  tags: string[];
};

const CompanyAccountPage:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const currentAccount:ICurrentAccount | null = useAppSelector(selectCurrentAccount);
  const loading = useAppSelector(selectLoading);
  const hcpcsCodesEnabled = useAppSelector(selectHcpcsCodesEnabled);

  const steps:string[] = [
    'General',
    'Localization',
    'Report preferences',
    'Workflows',
    hcpcsCodesEnabled ? 'Medicare' : '',
    'Page tags'
  ].filter(s => s);

  const stepFields:(string[] | null)[] = [
    ['company', 'email', 'phone', 'billingEmail'],
    ['settings.timeZone', 'settings.dateFormat', 'settings.timeFormat', 'settings.recordsLanguage', 'settings.language'],
    [],
    [],
    hcpcsCodesEnabled ? ['settings.medicareOverchargeThreshold'] : null,
    []
  ].filter(s => s !== null);

  const [ activeStep, setActiveStep ] = useState(0);

  const methods = useForm<IFormData>({
    defaultValues: {
      company: '',
      email: '',
      phone: '',
      onboardingTeamId: '',
      address: {
        line: '',
        city: '',
        state: '',
        zipCode: '',
      },
      settings: {
        recordsLanguage: '',
        language: '',
        timeZone: '',
        dateFormat: '',
        timeFormat: '',
        medicareOverchargeThreshold: 2.0,
        reportPreferences: {
          fontFamily: 'Arial',
          fontSize: 12
        },
        caseStatusTransitionRules: {
          status: 'open',
          triggerEvent: 'none',
          filter: {
            collectionId: []
          }
        }
      },
      billingEmail: '',
      tags: []
    }
  });

  const { handleSubmit, formState: { errors } } = methods;

  const onSubmit = handleSubmit((data:IFormData) => {
    const {
      onboardingTeamId,
      billingEmail,
      settings:{
        medicareOverchargeThreshold,
        reportPreferences,
        caseStatusTransitionRules,
        ...otherSettings
      },
      tags,
      ...otherData
    } = data;
    const nextData:any = {
      ...otherData,
      settings: {
        ...otherSettings,
        reportPreferences: {
          ...reportPreferences,
          fontSize: Number(reportPreferences.fontSize)
        }
      }
    };
    if ( caseStatusTransitionRules.triggerEvent !== 'none' ){
      nextData['settings']['caseStatusTransitionRules'] = [caseStatusTransitionRules];
    }
    if ( tags.length ) nextData['tags'] = tags;
    if ( hcpcsCodesEnabled ) nextData['settings']['medicareOverchargeThreshold'] = Number(medicareOverchargeThreshold);
    if ( onboardingTeamId ) nextData['onboardingTeamId'] = Number(onboardingTeamId);
    if ( billingEmail ) nextData['billingEmail'] = billingEmail;

    dispatch(updateCurrentAccount(nextData));
  })

  useEffect(() => {
    dispatch(getTeams({}));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( !currentAccount ) return;

    const { id, onboardingTeam, settings:{ caseStatusTransitionRules, ...otherSettings }, ...otherAccountData } = currentAccount;

    const openTransitionRules = caseStatusTransitionRules && caseStatusTransitionRules.length
      ? caseStatusTransitionRules[0]
      : null;

    const resetData:any = {
      ...otherAccountData,
      settings: otherSettings,
      onboardingTeamId: (onboardingTeam?.id || '').toString()
    };
    if ( openTransitionRules ){
      resetData['settings']['caseStatusTransitionRules'] = openTransitionRules;
    }
    methods.reset(resetData);
    // eslint-disable-next-line
  }, [currentAccount])

  const handleClick = (step:number) => () => {
    setActiveStep(step);
  };

  const hasError = (fields:string[]): boolean => {
    return fields.some(field => {
      const fieldParts = field.split('.');
      let error: any = errors;
      for ( const part of fieldParts ){
        if ( error[part] ){
          error = error[part];
        } else {
          return false;
        }
      }
      return !!error;
    });
  };

  const getStepStatus = (step:number): { icon: ReactNode; sx:SxProps<Theme>; error:boolean } => {
    const active = activeStep === step;
    const error = hasError(stepFields[step] as (keyof IFormData)[]);
    return {
      icon: error
        ? <ErrorIcon color="error" />
        : active
          ? <RadioButtonCheckedIcon color="primary" />
          : <RadioButtonUncheckedIcon color="disabled" />
      ,
      sx: {
        cursor: 'pointer !important',
        '& .MuiStepLabel-iconContainer': {
          color: (theme:Theme) => `${theme.palette.primary.main} !important`
        },
        '& .MuiStepLabel-label': {
          color: (theme:Theme) => `${
            error
              ? theme.palette.error.main
              : active
                ? theme.palette.primary.main
                : theme.palette.grey[700]
          }`,
          '&.Mui-completed': {
            fontWeight: 400
          }
        }
      },
      error
    };
  };

  const getDisplay = (step:number) => activeStep === step ? 'block' : 'none';

  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {t('labels.companyAccount')}
        </Typography>
        <Box sx={{ display: 'flex', gap: 4 }}>
        <Button
          name="Privacy policy"
          href="https://www.casechronology.com/privacy-policy"
          target="_blank"
          color="primary"
          size="small"
        >{t('pages.adminPages.billingInvoicesPage.privacyPolicy')}</Button>
        <Button
          name="Security policy"
          href="https://www.casechronology.com/security-policy"
          target="_blank"
          color="primary"
          size="small"
        >{t('pages.adminPages.billingInvoicesPage.securityPolicy')}</Button>
        <Button
          name="Refund/Cancellation policy"
          href="https://www.casechronology.com/refund-cancellation"
          target="_blank"
          color="primary"
          size="small"
        >{t('pages.adminPages.billingInvoicesPage.refundPolicy')}</Button>
      </Box>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ py: 4 }} maxWidth="md">
          <FormProvider {...methods}>
            <Paper
              sx={{ p: 8, overflowY: 'auto' }}
              variant="outlined"
              component="form"
              onSubmit={onSubmit}
              noValidate
            >
              <Grid container spacing={2}>
                <Grid item sm={4}>
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => {
                      const { icon, sx, error } = getStepStatus(index);
                      return (
                        <Step
                          key={label}
                          disabled={false}
                          onClick={handleClick(index)}
                        ><StepLabel sx={sx} icon={icon} error={error}>{label}</StepLabel></Step>
                      );
                    })}
                  </Stepper>
                </Grid>
                <Grid sx={{ minHeight: 600 }} item sm={8}>
                  <Box sx={{ display: getDisplay(0) }}><GeneralTabContent /></Box>
                  <Box sx={{ display: getDisplay(1) }}><LocalizationTabContent /></Box>
                  <Box sx={{ display: getDisplay(2) }}><ReportTabContent /></Box>
                  <Box sx={{ display: getDisplay(3) }}><WorkflowsTabContent /></Box>
                  {hcpcsCodesEnabled ? (
                    <Box sx={{ display: getDisplay(4) }}><MedicareTabContent /></Box>
                  ) : null}
                  <Box sx={{ display: getDisplay(hcpcsCodesEnabled ? 5 : 4) }}><PageTagsTabContent /></Box>
                </Grid>
              </Grid>
              <Box sx={{ pt: 4, textAlign: 'right' }}>
                <LoadingButton
                  name="Save company account form"
                  loading={loading}
                  type="submit"
                  color="primary"
                  variant="contained"
                >{t('labels.save')}</LoadingButton>
              </Box>
            </Paper>
          </FormProvider>
        </Container>
      </Box>
    </Fragment>
  )
}

export default CompanyAccountPage;