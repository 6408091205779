import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getOrders } from 'app/store/BillingOrders/BillingOrders.async';
// Selectors
import { selectHasSubscriptionWithAdditionalItems } from 'app/store/BillingSubscriptions/BillingSubscriptions.selectors';
// Mui
import { Box, Container } from '@mui/material';
// 
import OrdersAlert from './OrdersAlert';
import OrdersList from './OrdersList';
import OrdersFab from './OrdersFab';

const OrdersPage:FC = () => {
  const { caseId } = useParams<{ caseId:string }>();
  // Dispatch
  const dispatch:any = useAppDispatch();
  // State
  const hasSubscriptionWithAdditionalItems = useAppSelector(selectHasSubscriptionWithAdditionalItems)

  useEffect(() => {
    dispatch(getOrders({ caseId }));
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
      <Container sx={{ pt: 4, pb: 20 }} maxWidth="sm">
        {!hasSubscriptionWithAdditionalItems ? (
          <OrdersAlert />
        ) : null}
        <OrdersList />
      </Container>
      {hasSubscriptionWithAdditionalItems ? (
        <OrdersFab />
      ) : null}
    </Box>
  )
}

export default OrdersPage;
