import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { AuditOperations } from 'app/store/Audit/Audit.types';
// Models
import { RootState } from 'app/store';
import IUser, { IMyUser } from 'app/models/User';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createAssignment, deleteAssignment } from 'app/store/CaseAssignments/CaseAssignments.async';
import { createAuditRecord } from 'app/store/Audit/Audit.async';
// Selectors
import { selectUserIsAssigned } from 'app/store/CaseAssignments/CaseAssignments.selectors';
import { selectMyUser } from 'app/store/Users/Users.selectors';
// Mui
import { ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';
import { LoadingButton } from '@mui/lab';

type Props = {
  user: IUser;
  insuranceCaseId: number;
}

const UsersListItem:FC<Props> = ({
  // Props
  user, insuranceCaseId
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser:IMyUser | null = useAppSelector(selectMyUser);
  const userIsAssigned = useAppSelector((state:RootState) => selectUserIsAssigned(state, {
    userId: user.id
  }));

  const [ loading, setLoading ] = useState(false);

  const handleClick = async () => {
    setLoading(true);

    const data:any = {
      insuranceCaseId,
      userIds: [user.id]
    }
    if ( userIsAssigned ){
      deleteAssignmentsAsync(data);
    } else {
      createAssignmentsAsync(data);
    }
  }

  const createAssignmentsAsync = async (data:any) => {
    try {
      await dispatch(createAssignment(data)).unwrap();
      dispatch(createAuditRecord({
        operation: AuditOperations.CaseShared,
        data: {
          caseId: data.insuranceCaseId,
          userName: myUser?.name || '',
          userEmail: user.email,
          shared: true
        }
      }));
    } catch(err){} finally {
      setLoading(false)
    }
  }

  const deleteAssignmentsAsync = async (data:any) => {
    try {
      await dispatch(deleteAssignment(data)).unwrap();
      dispatch(createAuditRecord({
        operation: AuditOperations.CaseShared,
        data: {
          caseId: data.insuranceCaseId,
          userName: myUser?.name || '',
          userEmail: user.email,
          shared: false
        }
      }));
    } catch(err){} finally {
      setLoading(false)
    }
  }

  return (
    <ListItem>
      <ListItemText primary={`${user.name}`} />
      <ListItemSecondaryAction>
        <LoadingButton
          onClick={handleClick}
          color={userIsAssigned ? 'error' : 'primary'}
          loading={loading}
        >{userIsAssigned ? t('labels.stopSharing') : t('labels.share')}</LoadingButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default UsersListItem;
