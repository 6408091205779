import Strategy from "app/types/Strategy";
// Async
import { createConversation, updateConversation, deleteConversation, refineAnswer } from 'app/store/AIChat/AIChat.async';
import { createPrompt, updatePrompt, deletePrompt } from 'app/store/AIChatPrompts/AIChatPrompts.async';
// Actions
import MixpanelActions from "app/features/mixpanel/MixpanelActions";
// Strategies
import {
  createConversationStrategy, updateConversationStrategy, deleteConversationStrategy, refineAnswerStrategy,
  createPromptStrategy, updatePromptStrategy, deletePromptStrategy,
  handleAIChatConversationResponsePopupClickStrategy,
  handleAIChatConversationResponsePopupPageClickStrategy,
  handleAIChatPromptClickStrategy,
  handleAICHatPromptUseStrategy
} from "app/features/mixpanel/MixpanelStrategies/AIChat.strategies";

const AIChatStrategyMap:Record<string, Strategy> = {
  [createConversation.fulfilled.type]: createConversationStrategy,
  [updateConversation.fulfilled.type]: updateConversationStrategy,
  [deleteConversation.fulfilled.type]: deleteConversationStrategy,
  [refineAnswer.pending.type]: refineAnswerStrategy,
  [createPrompt.fulfilled.type]: createPromptStrategy,
  [updatePrompt.fulfilled.type]: updatePromptStrategy,
  [deletePrompt.fulfilled.type]: deletePromptStrategy,
  [MixpanelActions.handleAIChatConversationResponsePopupClick.type]: handleAIChatConversationResponsePopupClickStrategy,
  [MixpanelActions.handleAIChatConversationResponsePopupPageClick.type]: handleAIChatConversationResponsePopupPageClickStrategy,
  [MixpanelActions.handleAIChatPromptClick.type]: handleAIChatPromptClickStrategy,
  [MixpanelActions.handleAIChatPromptUse.type]: handleAICHatPromptUseStrategy,
};

export default AIChatStrategyMap;