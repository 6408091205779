import { ChangeEvent, FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// Types
import UserRoles from 'app/types/UserRoles';
import Permissions from 'app/types/Permissions';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectInvoicingEnabled, selectHcpcsCodesEnabled } from 'app/store/Accounts/Accounts.selectors';
// Mui
import { FormGroup, FormHelperText } from '@mui/material';
// Components
import { Switch } from 'app/components/Mui';
import { OutlineBlock } from 'app/components/Utilities';
// i18next
import { useTranslation } from 'react-i18next';

const FormPermissionTogglers:FC = () => {
  const { t } = useTranslation('common');
  // State
  const invoicingEnabled = useAppSelector(selectInvoicingEnabled);
  const hcpcsCodesEnabled = useAppSelector(selectHcpcsCodesEnabled);

  const { control, watch } = useFormContext();

  const watchRole = watch('role');

  const isWatchRoleAdmin = watchRole === UserRoles.Admin;
  const isWatchRoleStaff = watchRole === UserRoles.Staff;

  if ( !isWatchRoleAdmin && !isWatchRoleStaff ) return null;
  if ( !invoicingEnabled && !hcpcsCodesEnabled ) return null;
  return (
    <Fragment>
      <OutlineBlock sx={{ mt: 6 }} label={t('labels.permissions')}>
        <Controller
          control={control} name="permissions"
          render={({ field: { value, onChange } }) => {
            const permissions = (value as (Permissions)[]);

            const checkPermission = (permission:Permissions) => permissions.includes(permission);

            const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
              const permission = event.target.name as Permissions;
              const hasPermission = checkPermission(permission);

              const isInvoiceView = permission === Permissions.InvoiceView;
              const isInvoiceCreate = permission === Permissions.InvoiceCreate;
              const isInvoiceEdit = permission === Permissions.InvoiceEdit;

              let nextPermissions = hasPermission
                ? permissions.filter((v) => v !== permission)
                : [...value, permission]
              ;
              if ( hasPermission ){
                if ( isInvoiceView ) nextPermissions = nextPermissions.filter((p) => p !== Permissions.InvoiceCreate && p !== Permissions.InvoiceEdit);
              } else {
                if (
                  (isInvoiceCreate || isInvoiceEdit) &&
                  !nextPermissions.includes(Permissions.InvoiceView)
                ) nextPermissions = [...nextPermissions, Permissions.InvoiceView];
              }

              onChange(nextPermissions);
            }

            const hasHcpcsCodesAccount = value.some((v:string) => v.includes(`${Permissions.HcpcsCodes}:account`));

            return (
              <FormGroup>
                {invoicingEnabled && isWatchRoleStaff ? (
                  <Fragment>
                    <Switch
                      label={t('pages.adminPages.usersPage.invoiceView')} name={Permissions.InvoiceView}
                      value={checkPermission(Permissions.InvoiceView)}
                      onChange={handleChange}
                    />
                    <Switch
                      label={t('pages.adminPages.usersPage.invoiceCreate')} name={Permissions.InvoiceCreate}
                      value={checkPermission(Permissions.InvoiceCreate)}
                      onChange={handleChange}
                    />
                    <Switch
                      label={t('pages.adminPages.usersPage.invoiceEdit')} name={Permissions.InvoiceEdit}
                      value={checkPermission(Permissions.InvoiceEdit)}
                      onChange={handleChange}
                    />
                  </Fragment>
                ) : null}
                {hcpcsCodesEnabled ? (
                  <Fragment>
                    <Switch
                      label={t('pages.adminPages.usersPage.hcpcsCodes')} name={Permissions.HcpcsCodes}
                      value={hasHcpcsCodesAccount || checkPermission(Permissions.HcpcsCodes)}
                      onChange={handleChange}
                      disabled={hasHcpcsCodesAccount}
                    />
                    {hasHcpcsCodesAccount ? (
                      <FormHelperText>{t('pages.adminPages.usersPage.hcpcsCodesHint')}</FormHelperText>
                    ) : null}
                  </Fragment>
                ) : null}
              </FormGroup>
            )
          }}
        />
      </OutlineBlock>
    </Fragment>
  )
}

export default FormPermissionTogglers;
