import { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import IInsuranceCase from 'app/models/Case';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { AIChatActions } from 'app/store/AIChat/AIChat.slice';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectDocumentsEntities } from 'app/store/DMSDocuments/DMSDocuments.selectors';
import { selectSelectedInjestedPageId } from 'app/store/AIChat/AIChat.selectors';
// Mui
import { Paper, Box, Tooltip } from '@mui/material';
// Icons
import {
  Close as CloseIcon,
  Link as LinkIcon
} from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
import PageImage from 'app/components/PageImage';
import DeletePlaceholder from 'app/components/DeletePlaceholder';
import { parsePageId } from 'app/store/page/page.service';
import { selectPageEntities } from 'app/store/page/page.selectors';

const ConversationInjestedPagePreview:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const injestedPageId = useAppSelector(selectSelectedInjestedPageId);

  const documentsEntities = useAppSelector(selectDocumentsEntities);
  const pageEntities = useAppSelector(selectPageEntities);

  const pageId = injestedPageId ? parsePageId(injestedPageId) : null;

  const handleClick = () => {
    if ( !pageId ) return null;
    const documentPath = `/admin/cases/${insuranceCase?.id}/documents/${pageId.documentId}`;
    const pagePath = `${documentPath}/${pageId.pageNum}`;
    const link = `${window.location.origin}${pagePath}`;
    window.open(link, '_blank');
  }

  const handleClose = () => {
    dispatch(AIChatActions.setSelectedInjestedPageId(null));
  }

  if ( !pageId ) return null;

  const documentEntity = documentsEntities[pageId.documentId];
  const pageEntity = documentEntity && !documentEntity.deleted
  ? pageEntities[pageId.documentId][pageId.pageNum]
    : null
  ;
  // const noonedDeleted = documentEntity && documentEntity.deleted || pageEntity!.deleted;

  return (
    <Paper sx={{
      position: 'fixed',
      top: 0, left: 0, bottom: 0,
      width: '40%',
      display: 'flex',
      flexDirection: 'column',
      bgcolor: 'white',
      margin: 4,
      overflow: 'hidden',
      zIndex: 1301
    }}>
      <Box sx={{
        position: 'absolute',
        top: 0, right: 0,
        m: 4,
        p: 2,
        bgcolor: 'white',
        border: '1px solid rgba(0,0,0,0.08)',
        borderRadius: 2,
        display: 'flex',
        gap: 2,
        zIndex: 1
      }}>
        {pageEntity && !pageEntity.deleted ? (
          <Tooltip title={t('labels.goToPage')}>
            <IconButton
              name="Go to page"
              onClick={handleClick}
              size="small"
            ><LinkIcon /></IconButton>
          </Tooltip>
        ) : null}
        <Tooltip title={t('labels.close')}>
          <IconButton
            name="Close injested page preview"
            onClick={handleClose}
            size="small"
          ><CloseIcon /></IconButton>
        </Tooltip>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          position: 'relative'
        }}
      >
        <Box sx={{
          position: 'absolute',
          p: 4,
          left: 0, top: 0,
          width: '100%',
          height: '100%',
          '& > div': {
            width: '100%',
            height: '100%',
            '& > img': {
              width: '100%',
              height: '100%',
              objectFit: 'contain'
            }
          }
        }}>
          {!pageEntity || pageEntity.deleted ? (
            <DeletePlaceholder label={documentEntity.deleted ? t('labels.documentDeleted') : t('labels.pageDeleted')} />
          ) : (
            <PageImage documentId={pageEntity.documentId} pageNum={pageEntity.pageNum} showPageNum={true} />
          )}
        </Box>
      </Box>
    </Paper>
  )
}

export default ConversationInjestedPagePreview