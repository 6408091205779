import { FC } from 'react';
// Models
import { ISubscription } from 'app/store/Subscriptions/Subscriptions.models';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectSubscriptions, selectFilteredSubscriptions } from 'app/store/BillingSubscriptions/BillingSubscriptions.selectors';
// Mui
import { Box, Container } from '@mui/material';
// Components
import { Message } from 'app/components/Utilities';
// 
import SubscriptionsFilter from './SubscriptionsFilter';
import SubscriptionItem from './SubscriptionItem';

const SubscriptionsTabContent:FC = () => {
  const allSubscriptions = useAppSelector(selectSubscriptions);
  const subscriptions = useAppSelector(selectFilteredSubscriptions);

  if ( allSubscriptions.length === 0 ) return <Message text="No subscription(s)" />
  return (
    <Container sx={{ pt: 4, pb: 4 }} maxWidth="md">
      <SubscriptionsFilter />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        {subscriptions.map((subscription:ISubscription) => (
          <SubscriptionItem
            key={`subscriptionItem-${subscription.id}`}
            subscription={subscription}
          />
        ))}
      </Box>
    </Container>
  )
}

export default SubscriptionsTabContent