import { FC, Fragment } from 'react';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectConversation } from 'app/store/AIChat/AIChat.selectors';
// Mui
import { Box } from '@mui/material';
// Hooks
import useAiChatPermissionResolver from 'app/hooks/useAiChatPermissionResolver';
import useCasePermissionResolved from 'app/hooks/useCasePermissionResolver';
// 
import ConversationInitialRoute from './ConversationInitialRoute';
import ConversationInjestedPagePreview from './ConversationInjestedPagePreview';
import ConversationMessageList from './ConversationMessageList';
import ConversationMessageForm from './ConversationMessageForm';

const AIChatRoutes:FC = () => {
  const { path, url } = useRouteMatch<{ path:string; url:string }>();

  // State
  const conversation = useAppSelector(selectConversation);

  const { caseAIChatEnabled, isCaseProcessingReadyOrNotStarted } = useCasePermissionResolved();
  const { allowModifications, createdAutomatically } = useAiChatPermissionResolver();

  const isEditableIfNotAutoCreated = allowModifications && !createdAutomatically;
  const isNonConversationOrEditableSelected = !conversation || isEditableIfNotAutoCreated;

  const showConversationForm = caseAIChatEnabled && isCaseProcessingReadyOrNotStarted && isNonConversationOrEditableSelected;
  const showConversationMessages = caseAIChatEnabled;

  const renderConversationMessageListRoute = () => (
    showConversationMessages ? (
      <Fragment>
        <ConversationInjestedPagePreview />
        <ConversationMessageList />
      </Fragment>
    ) : (
      <Redirect to={url} />
    )
  );

  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }}>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
        <Switch>
          <Route exact path={path} component={ConversationInitialRoute} />
          <Route path={`${path}/:conversationId`} render={renderConversationMessageListRoute} />
        </Switch>
      </Box>
      {showConversationForm ? (
        <ConversationMessageForm />
      ) : null}
    </Box>
  )
}

export default AIChatRoutes;
