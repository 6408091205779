import { FC, useMemo } from 'react';
// Models
import { IUsage } from 'app/store/ManagementBillingUsage/ManagementBillingUsage.models';
// Mui
import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Theme } from '@mui/material';
// Utils
import { toNumberWithCommas } from 'app/utilities/Utilities';

type Props = {
  data: IUsage[];
};

const UsageGroupByService:FC<Props> = ({
  // Props
  data
}) => {
  const totalAmount = useMemo(() => {
    return data.reduce((acc:number, cur) => acc + cur.amount, 0)
  }, [data]);

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Service Name</TableCell>
            <TableCell>QTY</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((usage, index) => (
            <TableRow key={`tableRow-${usage.service?.id}-${index}`}>
              <TableCell>{usage.service?.name}</TableCell>
              <TableCell>{usage.quantity}</TableCell>
              <TableCell>{`$${toNumberWithCommas(usage.amount)}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1 }}>
          <TableRow sx={{
            bgcolor: (theme:Theme) => theme.palette.grey[50],
            '& > td': {
              borderBottom: 0
            }
          }}>
            <TableCell sx={{ fontWeight: 700, fontSize: '0.875rem' }} colSpan={2}>Total</TableCell>
            <TableCell sx={{ fontWeight: 700, fontSize: '0.875rem' }}>{`$${toNumberWithCommas(totalAmount)}`}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default UsageGroupByService;