import { createAction } from '@reduxjs/toolkit';
// 
import MixpanelEvents from 'app/features/mixpanel/MixpanelEvents';

// ToDO: needed only for `Page.store`
// since we do not migrate from saga to asyn functions
const handleCasePageUpdateConflict = createAction<{ documentId:number; pageNum:number }>(MixpanelEvents.CasePageUpdateConflict);
// End ToDO
const handleCaseEpisodeUpdateConflict = createAction<{ episodeId:number }>(MixpanelEvents.CaseEpisodeUpdateConflict);
const handleReportPresetOpened = createAction<{ presetId:number }>(MixpanelEvents.ReportPresetOpened);
const handleReportPresetSelected = createAction<{ presetId:number }>(MixpanelEvents.ReportPresetSelected);
const handleTemplateOpened = createAction<{ templateId:number }>(MixpanelEvents.TemplateOpened);
const handleAIChatConversationResponsePopupClick = createAction<{ messageId:string }>(MixpanelEvents.AIChatConversationResponsePopupClick);
const handleAIChatConversationResponsePopupPageClick = createAction<{ messageId:string; documentId:number; pageNum:number }>(MixpanelEvents.AIChatConversationResponsePopupPageClick);
const handleAIChatPromptClick = createAction<{ promptId:string }>(MixpanelEvents.AIChatPromptClick);
const handleAIChatPromptUse = createAction<{ promptId:string }>(MixpanelEvents.AIChatPromptUse);

const MixpanelActions = {
  handleCasePageUpdateConflict,
  handleCaseEpisodeUpdateConflict,
  handleReportPresetOpened,
  handleReportPresetSelected,
  handleTemplateOpened,
  handleAIChatConversationResponsePopupClick,
  handleAIChatConversationResponsePopupPageClick,
  handleAIChatPromptClick,
  handleAIChatPromptUse
};

export default MixpanelActions;