// Types
import Strategy from 'app/types/Strategy';
import MixpanelEvents from 'app/features/mixpanel/MixpanelEvents';
// Services
import Mixpanel from "app/services/Mixpanel.service";

export const handleCasePageUpdateConflictStrategy:Strategy = (payload, { accountId, userId, caseId }) => {
  const { documentId, pageNum } = payload;
  Mixpanel.track(MixpanelEvents.CasePageUpdateConflict, {
    accountId,
    userId,
    caseId,
    documentId,
    pageNum
  });
};

export const handleCaseEpisodeUpdateConflictStrategy:Strategy = (payload, { accountId, userId, caseId }) => {
  const { episodeId } = payload;
  Mixpanel.track(MixpanelEvents.CaseEpisodeUpdateConflict, {
    accountId,
    userId,
    caseId,
    episodeId
  });
};
