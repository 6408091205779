import { FC } from 'react';
// Components
import Icon from 'app/components/Icon';
// i18next
import { Box } from '@mui/material';

type Props = {
  size?: 'small' | 'default' | 'large';
  label: string;
}

const DeletePlaceholder:FC<Props> = ({
  // Props
  size = 'default', label
}) => {
  const sizes = {
    'small': { width: 208, height: 208, fontSize: 208 },
    'default': { width: 160, height: 160, fontSize: 160 },
    'large': { width: 208, height: 208, fontSize: 208 }
  };

  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Icon
        sx={{
          ...sizes[size],
          color: 'rgba(0,0,0,0.32)'
        }}
        icon="delete"
      />
      <span style={{
        display: 'block',
        color: 'rgba(0,0,0,0.32)',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        textAlign: 'center'
      }}>{label}</span>
    </Box>
  )
}

export default DeletePlaceholder;
