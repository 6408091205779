import { ChangeEvent, FC, useState, useCallback } from 'react';
import { debounce } from 'throttle-debounce';
// Models
import IOption from 'app/models/Option';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { UsersManagementActions } from 'app/store/UsersManagement/UsersManagement.slice';
// Selectors
import { selectAccountsAsOptions } from 'app/store/AccountsManagement/AccountsManagement.selectors';
import { selectParams } from 'app/store/UsersManagement/UsersManagement.selectors';
// Mui
import { Box, Typography } from '@mui/material';
// Components
import { Autocomplete } from 'app/components/Autocomplete';
import { Input, Select } from 'app/components/Mui';

const UsersFilter:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const accountsOptions:IOption[] | null = useAppSelector(selectAccountsAsOptions);
  const params = useAppSelector(selectParams);

  const [ stateParams, setStateParams ] = useState(params);

  const debounceParams = useCallback(debounce(500, (field:keyof typeof params, value:any) => {
    dispatch(UsersManagementActions.setParams({ [field]: value, offset: 0 }));
    // eslint-disable-next-line
  }), []);

  const handleChangeAccount = (value:number | string | null) => {
    setStateParams((prevState:any) => ({
      ...prevState,
      accountId: value
    }));
    debounceParams('accountId', value);
  }

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStateParams((prevState:any) => ({
      ...prevState,
      [name]: value
    }));
    debounceParams(name as keyof typeof params, value);
  }

  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
        <Input
          label="Search" name="search" placeholder="Name or email"
          value={stateParams.search}
          onChange={handleChange}
          margin="none"
          size="small"
        />
        <Autocomplete
          label="Company account" value={stateParams.accountId}
          onChange={handleChangeAccount}
          options={accountsOptions || []}
          renderOptions={(props, option) => (
            <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.id}>
              <Typography variant="subtitle1">{option.name}</Typography>
              {option.subname ? (
                <Typography variant="caption">{option.subname}</Typography>
              ) : null}
            </li>
          )}
          margin="none"
          size="small"
        />
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Select
          label="Locked" name="locked" value={stateParams.locked}
          onChange={handleChange}
          options={[
            { id: '', name: 'All' },
            { id: 'true', name: 'Locked' },
            { id: 'false', name: 'Non-locked' }
          ]}
          margin="none"
          size="small"
          SelectProps={{
            displayEmpty: true
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
        <Select
          label="Enabled" name="enabled" value={stateParams.enabled}
          onChange={handleChange}
          options={[
            { id: '', name: 'All' },
            { id: 'true', name: 'Enabled' },
            { id: 'false', name: 'Disabled' } 
          ]}
          margin="none"
          size="small"
          SelectProps={{
            displayEmpty: true
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
        <Select
          label="Platform" name="platform" value={stateParams.platform}
          onChange={handleChange}
          options={[
            { id: '', name: 'All' },
            { id: 'true', name: 'Platform' },
            { id: 'false', name: 'Non-platform' } 
          ]}
          margin="none"
          size="small"
          SelectProps={{
            displayEmpty: true
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Box>
    </Box>
  )
}

export default UsersFilter;
