import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// State
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
// Mui
import { AppBar, Toolbar, Box, Tooltip } from '@mui/material';
// Icons
import { SupportAgent as SupportAgentIcon } from '@mui/icons-material';
// Components
import Brand from 'app/components/Brand';
import MyUserDrawer from 'app/components/MyUserDrawer';
import UnseenConversations from 'app/components/UnseenConversations';
import { IconButton } from 'app/components/Mui';
// ToDO
import SystemNotifications from '@root/components/SystemNotifications';

const Header:FC = () => {
  const { t } = useTranslation('common');
  // State
  const trialMode = useAppSelector(selectTrialMode);

  return (
    <Fragment>
      <AppBar
        // Data attribute which explude block from print using CTRL/CMD + P
        data-print="exclude"
        position="relative"
        elevation={0}
      >
        <Toolbar>
          <Brand />
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex', gap: 1 }}>
            {!trialMode ? (
              <Fragment>
                <Tooltip title={t('components.header.contactUs')}>
                  <IconButton
                    name="Support"
                    aria-label="Support button"
                    component="a"
                    // @ts-ignore
                    href="https://www.casechronology.com/#contact-us"
                    target="_blank"
                  ><SupportAgentIcon /></IconButton>
                </Tooltip>
                <UnseenConversations />
              </Fragment>
            ) : null}
            <MyUserDrawer />
          </Box>
        </Toolbar>
      </AppBar>
      <SystemNotifications />
    </Fragment>
  )
}

export default Header;
