// Types
import Strategy from 'app/types/Strategy';
import MixpanelEvents from 'app/features/mixpanel/MixpanelEvents';
// Models
import { IConversation, IAnnotation } from 'app/models/ChatAI';
import { IPrompt } from 'app/store/AIChatPrompts/AIChatPrompts.models';
// Services
import Mixpanel from "app/services/Mixpanel.service";

export const createConversationStrategy:Strategy = (payload, { caseId }) => {
  const conversation = payload as IConversation;
  const source:string = conversation.annotations?.find((annotation:IAnnotation) => annotation.name === 'source')?.value || 'manual';
  Mixpanel.track(MixpanelEvents.AIChatConversationCreated, {
    caseId: caseId,
    conversationId: conversation.id,
    source
  });
};

export const updateConversationStrategy:Strategy = (payload, { caseId }) => {
  const conversation = payload as IConversation;
  Mixpanel.track(MixpanelEvents.AIChatConversationUpdated, {
    caseId: caseId,
    conversationId: conversation.id,
  });
};

export const deleteConversationStrategy:Strategy = (payload, { caseId }) => {
  const conversation = payload as IConversation;
  Mixpanel.track(MixpanelEvents.AIChatConversationDeleted, {
    caseId,
    conversationId: conversation.id,
  });
};

export const refineAnswerStrategy:Strategy = (payload, { caseId, conversationId }) => {
  if ( conversationId ){
    const { messageId } = payload;
    Mixpanel.track(MixpanelEvents.AIChatConversationResponseRefinement, {
      caseId,
      conversationId,
      messageId,
    });
  }
};

export const createPromptStrategy:Strategy = (payload, { accountId, userId, caseId, conversationId }) => {
  const aiChatPrompt = payload as IPrompt;
  const mixpanelData: Record<string, any> = {
    accountId,
    userId,
    promptId: aiChatPrompt.id,
  };
  let mixpanelEvent:any = MixpanelEvents.AIChatPromptHubPromptCreated;
  // If prompt is created from a conversation within an insurance case
  if ( caseId ){
    mixpanelData['caseId'] = caseId;
    if ( conversationId ) mixpanelData['conversationId'] = conversationId;
    mixpanelEvent = MixpanelEvents.AIChatPromptSaved;
  }
  Mixpanel.track(mixpanelEvent, mixpanelData);
};

export const updatePromptStrategy:Strategy = (payload, { accountId, userId }) => {
  const aiChatPrompt = payload as IPrompt;
  
  Mixpanel.track(MixpanelEvents.AIChatPromptHubPromptUpdated, {
    accountId,
    userId,
    promptId: aiChatPrompt.id,
  });
};

export const deletePromptStrategy:Strategy = (payload, { accountId, userId }) => {
  const aiChatPrompt = payload as IPrompt;
  Mixpanel.track(MixpanelEvents.AIChatPromptHubPromptDeleted, {
    accountId,
    userId,
    promptId: aiChatPrompt.id,
  });
};

export const handleAIChatConversationResponsePopupClickStrategy: Strategy = (payload, { caseId, conversationId }) => {
  const { messageId } = payload;

  if ( conversationId ) {
    Mixpanel.track(MixpanelEvents.AIChatConversationResponsePopupClick, {
      caseId,
      conversationId,
      messageId,
    });
  }
};

export const handleAIChatConversationResponsePopupPageClickStrategy:Strategy = (payload, { caseId, conversationId }) => {
  const { messageId, documentId, pageNum } = payload;

  if ( conversationId ) {
    Mixpanel.track(MixpanelEvents.AIChatConversationResponsePopupPageClick, {
      caseId,
      conversationId,
      messageId,
      documentId,
      pageNum,
    });
  }
};

export const handleAIChatPromptClickStrategy:Strategy = (payload, { caseId, userId, accountId }) => {
  const { promptId } = payload;
  Mixpanel.track(MixpanelEvents.AIChatPromptClick, {
    accountId,
    userId,
    caseId,
    promptId
  });
};

export const handleAICHatPromptUseStrategy:Strategy = (payload, { caseId, userId, accountId }) => {
  const { promptId } = payload;
  Mixpanel.track(MixpanelEvents.AIChatPromptUse, {
    accountId,
    userId,
    caseId,
    promptId
  });
};
