import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Components
import { Input } from 'app/components/Mui';

const CommentControl:FC = () => {
  const { t } = useTranslation('common');

  const { control } = useFormContext();

  return (
    <Controller
      control={control} name="comment"
      render={({ field }) => (
        <Input
          {...field}
          label={t('labels.comment')}
          size="small"
          margin="none"
        />
      )}
    />
  )
}

export default CommentControl;
