import { FC, ReactNode } from 'react';
import { Draggable } from 'react-beautiful-dnd';
// Mui
import { Box } from '@mui/material';

type Props = {
  draggableId: string;
  index: number;
  children: ReactNode;
}

const ContentDraggable:FC<Props> = ({
  // Props
  draggableId, index, children
}) => {
  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <Box
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          sx={{
            display: 'flex'
          }}
          style={{
            ...provided.draggableProps.style,
          }}
        >{children}</Box>
      )}
    </Draggable>
  )
}

export default ContentDraggable