import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from 'app/types/Reducers';

interface IState {
  loading: boolean;
  preSignedUrls: Record<string, string | null>;
}

const initialState:IState = {
  loading: false,
  preSignedUrls: {}
}

const slice = createSlice({
  name: Reducers.DMSUploads,
  initialState,
  reducers: {
    addPreSignedUrl: (state, action:PayloadAction<{ id:string, url:string | null }>) => {
      const { id, url } = action.payload;
      state.preSignedUrls[id] = url;
    },
    removePreSignedUrl: (state, action:PayloadAction<string>) => {
      state.preSignedUrls = Object.fromEntries(Object.entries(state.preSignedUrls).filter(([key]) => key !== action.payload));
    },
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  }
});

export const DMSUploadsActions = slice.actions;

export default slice.reducer;
