import { MouseEvent, FC, Fragment, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import { IMessage, IMessagePart } from 'app/models/ChatAI';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import MixpanelActions from 'app/features/mixpanel/MixpanelActions';
// Selectors
import { selectDocumentsEntities } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import { Box, Tooltip, Popover, Typography } from '@mui/material';
// Icons
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
// Services
import PageService from 'app/services/PageService';
// 
import MetadataDocumentPageNum from './MetadataDocumentPageNum';

type Props = {
  message: IMessage;
}

const MessageIngestedDocuments:FC<Props> = ({
  // Props
  message
}) => {
  const { t } = useTranslation('common');

  // Dispathc
  const dispatch = useAppDispatch();

  // State
  const documentEntities = useAppSelector(selectDocumentsEntities)

  const [ anchorEl, setAnchorEl ] = useState<HTMLElement | null>(null);

  const pageIds = useMemo(() => {
    if ( !message.parts || !message.parts.length ) return [];
    return message.parts.reduce((acc:string[], cur:IMessagePart) => {
      if ( cur.references && cur.references.length ){
        for ( let ref of cur.references ){
          if ( ref.metadata ){
            const { documentId, page } = ref.metadata;
            const pageId = PageService.toPageId(documentId, page);
            if ( !acc.includes(pageId) ) acc.push(pageId);
          }
        }
      }
      return acc;
    }, []);
  }, [message]);

  const handleOpen = (event:MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if ( message && message.id ){
      dispatch(MixpanelActions.handleAIChatConversationResponsePopupClick({
        messageId: message.id
      }));
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const documents = message.metadata?.documents || [];

  if ( !documents.length ) return null;
  return (
    <Fragment>
      <Tooltip title={t('pages.staffPages.caseDetailsPage.ingestedDocumentsPages')}>
        <IconButton
          name={`Ingested documents ${message.id}`}
          onClick={handleOpen}
          size="small"
        ><InfoOutlinedIcon fontSize="small" /></IconButton>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ maxWidth: 450, maxHeight: 300, overflowY: 'auto', p: 4 }}>
          {documents.map((document:{ id:number, pages:number[] }) => {
            const documentEntity = documentEntities[document.id];
            return (
              <Box
                key={`${message.id} document information ${document.id}`}
                sx={{ '& + &':{ mt: 4 } }}
              >
                <Typography variant="body2" sx={{ mb: 2 }}>{documentEntity.name}</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                  {document.pages.map((pageNum:number) => {
                    const pageId = PageService.toPageId(document.id, pageNum);
                    return (
                      <MetadataDocumentPageNum
                        key={`Page item ${document.id} ${pageNum}`}
                        documentId={document.id}
                        pageNum={pageNum}
                        highlighted={pageIds.includes(pageId)}
                        messageId={message.id}
                      />
                    )
                  })}
                </Box>
              </Box>
            )
          })}
        </Box>
      </Popover>
    </Fragment>
  )
}

export default MessageIngestedDocuments;
