import { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Mui
import { Alert } from '@mui/material';

type Props = {
  loading: boolean;
}

const FilesUploadAlert:FC<Props> = ({
  // Props
  loading
}) => {
  const { t } = useTranslation('common');

  if ( !loading ) return null;
  return (
    <Alert
      sx={{
        mt: 4
      }}
      color="warning"
      severity="warning"
    >{t('dialogs.batchCreate.uploadInProgress')}</Alert>
  )
}

export default FilesUploadAlert