import { ChangeEvent, FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
// Models
import { RootState } from 'app/store';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectCollectionsAsOptionsByFileType } from 'app/store/DMSCollections/DMSCollections.selectors';
// Mui
import { Box, Typography, FormHelperText, TextField, MenuItem, Divider } from '@mui/material';
// Components
import { Checkbox } from 'app/components/Mui';
// Utilities
import { isRequired } from 'app/utilities/Validations';
// Hooks
import { IFileItem, useUploadFilesContext } from './UploadFiles.provider';

type Props = {
  fileItem: IFileItem;
}

const FieldItemCollectionSelector:FC<Props> = ({
  // Props
  fileItem
}) => {
  const { t } = useTranslation('common');

  // State
  const collectionOptions = useAppSelector((state:RootState) => selectCollectionsAsOptionsByFileType(state, {
    fileFormat: fileItem.fileFormat
  }));

  const { formState:{ isSubmitted } } = useFormContext();
  const { onUpdateFileItem, updateCollectionsInFileItems } = useUploadFilesContext();

  const [ isChecked, setIsChecked ] = useState(false);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const collectionId = Number(event.target.value);
    onUpdateFileItem(fileItem.id, { collectionId });

    if ( !isChecked ) return;

    updateCollectionsInFileItems(fileItem.fileFormat, collectionId);

    setIsChecked(false);
  }

  const handleClose = () => {
    if ( !isChecked || !fileItem.collectionId ) return;

    updateCollectionsInFileItems(fileItem.fileFormat, fileItem.collectionId);

    setIsChecked(false);
  }

  const handleCheckboxChange = (checked:boolean) => {
    setIsChecked(checked);
  }

  const error = isSubmitted && !fileItem.collectionId ? isRequired : '';

  if ( !collectionOptions ) return (
    <FormHelperText
      sx={{ alignSelf: 'center' }}
      error={true}
    >{t('dialogs.batchCreate.fileFormatNotSupported')}</FormHelperText>
  );
  return (
    <TextField
      sx={{ maxWidth: 200 }}
      label={t('labels.documentCollection')} value={(fileItem.collectionId || '').toString()}
      onChange={handleChange}
      required
      error={Boolean(error)}
      helperText={error || ''}
      SelectProps={{
        onClose: handleClose,
        renderValue: (value) => {
          if ( !value ) return '';
          const foundedOption = collectionOptions.find((option) => option.id.toString() === value.toString());
          return foundedOption?.name || '';
        }
      }}
      select
      fullWidth
      margin="none"
      size="small"
    >
      {collectionOptions.map((option) => (
        <MenuItem
          key={`option item ${option.id}`}
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
          value={option.id.toString()}
        >
          <Typography variant="subtitle1">{option.name}</Typography>
          {option.props?.description ? (
            <Typography sx={{ maxWidth: 320, whiteSpace: 'normal' }} variant="caption">{option.props.description}</Typography>
          ) : null}
        </MenuItem>
      ))}
      <Divider />
      <Box sx={{
        py: 1.5,
        px: 4
      }}>
        <Checkbox
          name="applyToAll"
          label={
            <Fragment>
              <Typography>{t('dialogs.batchCreate.applyToAll')}</Typography>
              <FormHelperText sx={{ m: 0 }}>{t('dialogs.batchCreate.applyToAllHint')}</FormHelperText>
            </Fragment>
          }
          value={isChecked}
          onChange={(_, checked) => handleCheckboxChange(checked)}
          size="small"
        />
      </Box>
    </TextField>
  )
}

export default FieldItemCollectionSelector