import { HTMLAttributes, Ref, forwardRef } from "react";
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";

type Option = {
  value: string | number;
  label: string;
  props?: any;
};

type Props<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
> = Omit<
  MuiAutocompleteProps<Option, Multiple, DisableClearable, FreeSolo>,
  'renderInput' | 'isOptionEqualToValue' | 'value' | 'onChange'
> & {
  TextFieldProps?: Partial<MuiTextFieldProps>;
  value?: string | number | (string | number)[] | null;
  onChange: (value: string | number | (string | number)[] | null) => void;
};

const Autocomplete = forwardRef(<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>({
  // Props
    TextFieldProps,
    // Default option rendering
    renderOption = (props:HTMLAttributes<HTMLLIElement>, option:Option) => (
      <li {...props} key={option.value}>
        {option.label}
      </li>
    ),
    options,
    value,
    onChange,
    ...autocompleteProps
  }: Props<Multiple, DisableClearable, FreeSolo>,
  ref: Ref<HTMLDivElement>
) => {
  // Ensure the value is correctly typed, and find the selected option
  const selectedOptions = autocompleteProps.multiple
    ? options.filter(option => (value as (string | number)[]).includes(option.value))
    : options.find(option => option.value === value) || null;

  const handleChange = (_:any, newValue:Option | Option[] | null) => {
    if ( !onChange ) return;
    if ( autocompleteProps.multiple ) {
      // If multiple is true, return an array of values
      onChange(newValue ? (newValue as Option[]).map(option => option.value) : []);
    } else {
      // If single selection, return a single value
      onChange(newValue ? (newValue as Option).value : null);
    }
  }

  return (
    <MuiAutocomplete
      {...autocompleteProps}
      ref={ref}
      value={selectedOptions as any}
      onChange={handleChange as any}
      options={options}
      renderOption={renderOption}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          {...TextFieldProps}
          fullWidth={typeof TextFieldProps?.fullWidth === 'undefined' ? true : TextFieldProps?.fullWidth}
          margin={TextFieldProps?.margin || 'normal'}
        />
      )}
      getOptionLabel={(option) => (typeof option === 'object' ? option.label : '')}
      isOptionEqualToValue={(option, value) => option.value === value.value}
    />
  );
});

export default Autocomplete;
