import { FC, useMemo } from 'react'
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Mui
import { FormHelperText } from '@mui/material';
// 
import { useUploadFilesContext } from './UploadFiles.provider';

const FilesUploaderError:FC = () => {
  const { t } = useTranslation('common');

  const { formState:{ isSubmitted } } = useFormContext();
  const { sortedFileItems } = useUploadFilesContext();

  const hasErrors = useMemo(() => {
    if ( !sortedFileItems.length ) return false;
    return isSubmitted && sortedFileItems.some((fileItem) => fileItem.fileError || !fileItem.collectionId);
  }, [isSubmitted, sortedFileItems]);

  if ( !hasErrors ) return null;
  return (
    <FormHelperText sx={{ mt: 0, mb: 4 }} error>{t('dialogs.batchCreate.uploadError')}</FormHelperText>
  )
}

export default FilesUploaderError;