import { FC } from 'react';
// Types
import { Dimension } from 'app/store/ManagementBillingUsage/ManagementBillingUsage.types';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectParams } from 'app/store/ManagementBillingUsage/ManagementBillingUsage.selectors';
// Mui
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Theme } from '@mui/material';
// Utilities
import { toNumberWithCommas } from 'app/utilities/Utilities';

const UsageNoDataFound:FC = () => {
  // State
  const params = useAppSelector(selectParams);

  const tableHeaderName = params.dimensions === Dimension.Service
    ? 'Service Name'
    : params.dimensions === Dimension.Account
      ? 'Company'
      : 'Subscription Package'
  ;

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>{tableHeaderName}</TableCell>
            <TableCell>QTY</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={3}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                <span>No data found for the selected filters</span>
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
        <TableFooter sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1 }}>
          <TableRow sx={{
            bgcolor: (theme:Theme) => theme.palette.grey[50],
            '& > td': {
              borderBottom: 0
            }
          }}>
            <TableCell sx={{ fontWeight: 700, fontSize: '0.875rem' }} colSpan={2}>Total</TableCell>
            <TableCell sx={{ fontWeight: 700, fontSize: '0.875rem' }}>{`$${toNumberWithCommas(0)}`}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default UsageNoDataFound