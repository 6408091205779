import { FC, ReactNode, createContext, useCallback, useContext, useState } from 'react';
// Type
import { ContentType, ContentItemType } from 'app/types/ContentItem';
// Utils
import { generateContentItem, parseContentItem } from 'app/utilities/ContentItemUtils';


type ContextType = {
  caseId?: number;
  content: ContentItemType[];
  onPresentContent: (presentContent:ContentItemType[]) => void;
  onAddContentItem: (type:ContentType, id:number, destinationIndex?:number) => void;
  onRemoveContentItem: (type:ContentType, id:number) => void;
  onRemoveContentItemByIndex: (index:number) => void;
  onReorderContent: (sourceIndex:number, destinationIndex:number) => void;
  getAvailableIds: (type:ContentType, id:number[] | null) => number[] | null;
  parseContentItem: (contentItem:ContentItemType) => [ContentType, number];
}

const Context = createContext<ContextType | undefined>(undefined);

export const useReportPresetContext = ():ContextType => {
  const context = useContext(Context);
  if ( !context ) throw new Error('useReportPresetContext must be used within a ListProvider');
  return context;
}

type Props = {
  caseId?: number;
  initialContent: ContentItemType[];
  children: ReactNode;
}

const ReportPresetProvider:FC<Props> = ({
  // Props
  caseId, initialContent = [], children
}) => {
  const [ content, setContent ] = useState<ContentItemType[]>(initialContent);

  const onPresentContent = useCallback((presetContent:ContentItemType[]) => {
    setContent(presetContent);
  }, [])

  const onAddContentItem = useCallback((type:ContentType, id:number, destinationIndex?:number) => {
    const sectionItem = generateContentItem(type, id);
    if ( typeof destinationIndex !== 'undefined' ){
      setContent((prevState) => [
        ...prevState.slice(0, destinationIndex),
        sectionItem,
        ...prevState.slice(destinationIndex)
      ]);
    } else {
      setContent((prevState) => [...prevState, sectionItem]);
    }
    // eslint-disable-next-line
  }, []);

  const onRemoveContentItem = useCallback((type:ContentType, id:number) => {
    const sectionItem = generateContentItem(type, id)
    setContent((prevState) => prevState.filter((item) => item !== sectionItem));
    // eslint-disable-next-line
  }, []);

  const onRemoveContentItemByIndex = useCallback((index:number) => {
    setContent((prevState) => prevState.filter((_,idx) => idx !== index));
    // eslint-disable-next-line
  }, []);

  const onReorderContent = useCallback((sourceIndex:number, destinationIndex:number) => {
    const contentCopy = [...content];
    const [ movedItem ] = contentCopy.splice(sourceIndex, 1); // Remove item from source index
    contentCopy.splice(destinationIndex, 0, movedItem); // Insert item at destination index
    setContent(contentCopy);
  }, [content]);

  const getAvailableIds = useCallback((type:ContentType, ids:number[] | null):number[] | null => {
    if ( !ids ) return null;
    return ids.filter((id:number) => {
      const contentItem = generateContentItem(type, id);
      return !content.includes(contentItem);
    });
    // eslint-disable-next-line
  }, [content]);

  return (
    <Context.Provider value={{
      caseId,
      content,
      onPresentContent,
      onAddContentItem,
      onRemoveContentItem,
      onRemoveContentItemByIndex,
      onReorderContent,
      getAvailableIds,
      parseContentItem
    }}>{children}</Context.Provider>
  );
}

export default ReportPresetProvider;

