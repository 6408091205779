import { FC } from 'react';
// Components
import Icon from 'app/components/Icon';
// i18next
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

const CardPageDeletedPlaceholder:FC = () => {
  const { t } = useTranslation('common');

  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Icon
        sx={{
          width: 208,
          height: 208,
          color: 'rgba(0,0,0,0.32)',
          fontSize: 208
        }}
        icon="delete"
      />
      <span style={{
        display: 'block',
        color: 'rgba(0,0,0,0.32)',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        textAlign: 'center'
      }}>{t('labels.pageDeleted')}</span>
    </Box>
  )
}

export default CardPageDeletedPlaceholder;
