import { FC, Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Types
import { AuditOperations } from 'app/store/Audit/Audit.types';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createAuditRecord } from 'app/store/Audit/Audit.async';
import { shareReport } from 'app/store/CaseReports/CaseReports.async';
import { getAccountUsers } from 'app/store/Clients/Clients.async';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectLoading } from 'app/store/CaseReports/CaseReports.selectors';
import { selectAccountUsersAsOptions } from 'app/store/Clients/Clients.selectors';
// Mui
import { Typography, Autocomplete, TextField, FormHelperText } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button, LoadingButton } from 'app/components/Mui';
// 
import MixpanelTracks from 'app/types/MixpanelTracks';
import Mixpanel from 'app/services/Mixpanel.service';

type Props = {
  open: boolean;
  onClose: () => void;
  reportId: number;
  reportName: string;
}

const ReportShareDialog:FC<Props> = ({
  // Props
  open, onClose, reportId, reportName
}) => {
  const { t } = useTranslation('common');

  const { caseId } = useParams<{ caseId:string }>();
  // Dispatch
  const dispatch = useAppDispatch();
  // Sttae
  const myUser = useAppSelector(selectMyUser);
  const loading = useAppSelector(selectLoading)
  // ToDO: State
  const litigationCase = useAppSelector(selectInsuranceCase);
  const accountUsersOptions = useAppSelector(selectAccountUsersAsOptions);

  const [ recipients, setRecipients ] = useState([]);
  const [ error, setError ] = useState<string | null>(null);

  const handleChange = (_:any, nextValue:any) => {
    setRecipients(nextValue);
  }

  const handleClick = async () => {
    if ( !recipients || !recipients.length ){
      setError(t('validations.fieldRequired'));
      return;
    }

    setError(null);

    const insuranceCaseId = Number(caseId);

    try {
      const notificationRecipients = recipients.map((recipient:any) => ({ id: recipient.id, name: recipient.name, email: recipient.props.email }));
      await dispatch(shareReport({ reportId, data:{ notificationRecipients } })).unwrap();

      Mixpanel.track(MixpanelTracks.CaseReportShared, { caseId: insuranceCaseId });

      dispatch(createAuditRecord({
        operation: AuditOperations.ReportShared,
        data: {
          caseId: insuranceCaseId,
          reportName,
          userName: myUser?.name || '',
          clientUserEmails: notificationRecipients.map((recipient:any) => recipient.email)
        }
      }));

      onClose();
    } catch(error){}
  }

  useEffect(() => {
    if ( litigationCase?.clientAccount?.id ){
      dispatch(getAccountUsers(litigationCase.clientAccount.id));
    }
    // eslint-disable-next-line
  }, []);

  const actions = (
    <Fragment>
      <Button
        name="Cancel report dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name="Share report dialog"
        loading={loading}
        onClick={handleClick}
        variant="contained"
        color="primary"
      >{t('labels.share')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.staffPages.caseDetailsPage.shareReport')}
      actions={actions}
    >
      <Typography sx={{ mb: 2 }} variant="body1">{t('pages.staffPages.caseDetailsPage.shareReportDialogTitle1')}</Typography>
      <Typography sx={{ mb: 4 }} variant="body1">{t('pages.staffPages.caseDetailsPage.shareReportDialogTitle2')}</Typography>
      <Autocomplete
        value={recipients}
        disabled={!accountUsersOptions}
        options={accountUsersOptions || []}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('labels.recipients')}
            placeholder={!accountUsersOptions ? `${t('labels.loading')} ...` : ''}
            disabled={!accountUsersOptions}
            required
            error={Boolean(error)}
            helperText={error || ''}
          />
        )}
        multiple
        filterSelectedOptions
        getOptionLabel={(option:any) => option.props.email}
      />
      <FormHelperText>{t('pages.staffPages.caseDetailsPage.shareReportDialogRecipientsHint')}</FormHelperText>
    </Dialog>
  )
}

export default ReportShareDialog;
