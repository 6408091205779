// Types
import Strategy from "app/types/Strategy";
// Actions
import MixpanelActions from "app/features/mixpanel/MixpanelActions";
// Strategies
import {
  handleCaseEpisodeUpdateConflictStrategy,
  handleCasePageUpdateConflictStrategy
} from "app/features/mixpanel/MixpanelStrategies/Case.strategies";

const CaseStrategyMap:Record<string, Strategy> = {
  [MixpanelActions.handleCaseEpisodeUpdateConflict.type]: handleCaseEpisodeUpdateConflictStrategy,
  [MixpanelActions.handleCasePageUpdateConflict.type]: handleCasePageUpdateConflictStrategy
};

export default CaseStrategyMap;
