// Types
import Strategy from 'app/types/Strategy';
import MixpanelEvents from 'app/features/mixpanel/MixpanelEvents';
// Models
import { IFileError } from 'app/store/DMSBatches/DMSBatches.models';
// Services
import Mixpanel from "app/services/Mixpanel.service";

export const validateBatchStrategy:Strategy = (payload, { accountId, userId, caseId }) => {
  const filesErrors = payload as any;

  const hasError = filesErrors.filter((error:IFileError | null) => error).length;

  if ( hasError ){
    const errors = filesErrors.map((error:IFileError | null) => error ? error.errorType : null);
    const numberOfUploadedFiles = filesErrors.length;
  
    Mixpanel.track(MixpanelEvents.DocumentsValidationFailed, {
      portal: 'case',
      accountId,
      userId,
      caseId,
      numberOfUploadedFiles,
      errors
    });
  }
};