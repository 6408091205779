
// Types
import Strategy from "app/types/Strategy";
// Async
import { createPreset, updatePreset, deletePreset } from "app/store/ReportPresets/ReportPresets.async";
// Actions
import MixpanelActions from "app/features/mixpanel/MixpanelActions";
// Strategies
import {
  createPresetStrategy,
  updatePresetStrategy,
  deletePresetStrategy,
  handleReportPresetOpenedStrategy,
  handleReportPresetSelectedStrategy
} from "app/features/mixpanel/MixpanelStrategies/ReportPreset.strategies";

const ReportPresetStrategyMap:Record<string, Strategy> = {
  [createPreset.fulfilled.type]: createPresetStrategy,
  [updatePreset.fulfilled.type]: updatePresetStrategy,
  [deletePreset.fulfilled.type]: deletePresetStrategy,
  [MixpanelActions.handleReportPresetOpened.type]: handleReportPresetOpenedStrategy,
  [MixpanelActions.handleReportPresetSelected.type]: handleReportPresetSelectedStrategy,
};

export default ReportPresetStrategyMap;