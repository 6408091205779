import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';

export const selectUsages = (state:RootState) => state[Reducers.ManagementBillingUsage].usages;
export const selectParams = (state:RootState) => state[Reducers.ManagementBillingUsage].params;

interface IValidationErrors {
  startError?: string;
  endError?: string;
  rangeError?: string;
}

export const selectValidateDateRange = createSelector(
  [ selectParams ],
  ( params ) => {
    const start = params['period.start'];
    const end = params['period.end'];
    const errors: IValidationErrors = {};
    // Check if start date is valid
    if ( !start ){
      errors.startError = 'Start date is required.';
    } else if ( !start.isValid() ){
      errors.startError = 'Start date is invalid.';
    }

    // Check if end date is valid
    if ( !end ){
      errors.endError = 'End date is required.';
    } else if ( !end.isValid() ){
      errors.endError = 'End date is invalid.';
    }

    // Perform range validations if both dates are valid
    if ( start && end && start.isValid() && end.isValid() ){
      if ( start.isAfter(end) ){
        errors.rangeError = 'Start date cannot be after end date.';
      }
    }

    // Return null if there are no errors
    return Object.keys(errors).length > 0 ? errors : null;
  }
);

