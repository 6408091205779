// Types
import Strategy from "app/types/Strategy";
// 
import CaseStrategyMap from "./CaseStrategy.map";
import DMSBatcheStrategyMap from './DMSBatcheStrategy.map';
import TemplateStrategyMap from './TemplateStrategy.map';
import ReportPresetStrategyMap from './ReportPresetStrategy.map';
import AIChatStrategyMap from './AIChatStrategy.map';

const MixpanelStrategyMap:Record<string, Strategy> = {
  ...CaseStrategyMap,
  ...DMSBatcheStrategyMap,
  ...TemplateStrategyMap,
  ...ReportPresetStrategyMap,
  ...AIChatStrategyMap
};

export default MixpanelStrategyMap;
