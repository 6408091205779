import { FC, Fragment, useEffect, useMemo, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
// Types
import UserRoles from 'app/types/UserRoles';
// Models
import { IMyUser } from 'app/models/User';
import IEpisode from 'app/models/Episode';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// ASync
import { getEpisode, createEpisode, updateEpisode } from 'app/store/Episodes/Episodes.async';
import { createFact } from 'app/store/EpisodeFacts/EpisodeFacts.async';
// Actions
import MixpanelActions from 'app/features/mixpanel/MixpanelActions';
import { EpisodesActions } from 'app/store/Episodes/Episodes.slice';
import { EpisodeFactsActions } from 'app/store/EpisodeFacts/EpisodeFacts.slice';
// Selectors
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectLoading, selectStatusCode, selectStatusMessage } from 'app/store/Episodes/Episodes.selectors';
import { selectEpisodeFactsWithNegativeId } from 'app/store/EpisodeFacts/EpisodeFacts.selectors';
// Mui
import { Box, FormHelperText, Typography, Alert } from '@mui/material';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Components
import { Button, LoadingButton } from 'app/components/Mui';
// Hooks
import useCaseStatus from 'app/hooks/useCaseStatus';
import useToggle from 'app/hooks/useToggle';
// Context
import { usePageEpisodesContext } from 'app/context/PageEpisodes.context';
// Utilities
import { dateToString, parseDate, parseTime, timeToString } from 'app/utilities/DateTime';
// 
import EpisodeFormLabels from './EpisodeFormLabels';
import EpisodeFormFacts from './EpisodeFormFacts';
import EpisodeFormDates from './EpisodeFormDates';
import EpisodeFormDateTime from './EpisodeFormDateTime';
import EpisodeFormAuthorType from './EpisodeFormAuthorType';
import EpisodeFormPageNum from './EpisodeFormPageNum';
import EpisodeFormNotes from './EpisodeFormNotes';
import EpisodeFormTogglers from './EpisodeFormTogglers';
// ToDO
import { IPage } from '@root/models/Page';
import PageActions from 'app/store/page/page.actions';
import { selectPage, selectPageStaples } from 'app/store/page/page.selectors';
import { generateWorkspaceOrder } from 'app/store/page/page.service';
import { validateAuthorAndType } from 'app/store/EpisodeAuthors/EpisodeAuthors.async';
// End: ToDO

interface IFormData {
  labels: string[];
  authorId: string | null;
  // ToDO
  author: any;
  // End: ToDO
  type: string;
  date: Dayjs | null;
  time: Dayjs | null;
  pageNum: string | null;
  notes: string;
  preEvent: boolean;
  showInTimeline: boolean;
};

type Props = {
  episode?: Omit<IEpisode, 'id'> & {
    id: number | undefined
  } | undefined;
  onClose?: () => void;
  isParentEpisodeCreation?: boolean;
}

const EpisodeForm:FC<Props> = ({
  // Props
  episode, onClose, isParentEpisodeCreation
}) => {
  const isEpisodeCreation = !episode || typeof episode.id === 'undefined';

  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const trialMode = useAppSelector(selectTrialMode);
  const myUser = useAppSelector(selectMyUser) as IMyUser;
  // ToDO
  const page = useAppSelector((state) => selectPage(state as any)) as IPage;
  const pageStaples = useAppSelector((state) => selectPageStaples(state as any));
  // End: ToDO
  const loading = useAppSelector(selectLoading);
  const statusCode = useAppSelector(selectStatusCode);
  const statusMessage = useAppSelector(selectStatusMessage);
  const episodeFactsWithNegativeId = useAppSelector(selectEpisodeFactsWithNegativeId);

  const { isArchived } = useCaseStatus();
  const { open, toggle } = useToggle();

  const { onCreate, onUpdate } = usePageEpisodesContext();

  const pagesNums = useMemo(() => {
    if ( !pageStaples ) return [];
    return pageStaples.map((page:IPage) => page.pageNum.toString());
  }, [pageStaples])

  const methods = useForm<IFormData>({
    defaultValues: {
      labels: episode?.labels || [],
      authorId: episode?.authorId ? episode.authorId.toString() : null,
      author: episode?.author || undefined,
      type: episode?.type || '',
      date: episode?.date ? parseDate(episode.date) : null,
      time: episode?.time ? parseTime(episode.time) : null,
      pageNum: episode?.pageNum ? episode.pageNum.toString() : null,
      notes: episode?.notes || '',
      preEvent: episode?.preEvent || false,
      showInTimeline: !episode || episode.showInTimeline ? true : false
    }
  });

  const { register, handleSubmit, formState:{ isSubmitted }, watch } = methods;

  const generateEpisodeData = useCallback((data:IFormData) => {
    const { authorId, labels, date, time, pageNum, notes, type, ...otherData } = data;
    const nextData:any = {...otherData, documentId: page.documentId };
    if ( labels.length ) nextData['labels'] = labels;
    if ( date ){
      nextData['date'] = dateToString(date);
      if ( time ) nextData['time'] = timeToString(time);
    }
    if ( pageNum && pagesNums.includes(pageNum) ){
      nextData['pageNum'] = Number(pageNum);
    } else {
      nextData['pageNum'] = page.pageNum;
    }
    if ( type ) nextData['type'] = type;
    if ( notes ) nextData['notes'] = notes;
    return nextData;
    // eslint-disable-next-line
  }, []);

  const onSubmit = handleSubmit((data:IFormData) => {
    if ( isAnyRequired ) return;

    const nextData = generateEpisodeData(data);

    if ( isEpisodeCreation ){
      asyncCreateEpisode(nextData);
    } else {
      if ( !episode.id ) return;
      nextData['version'] = episode.version;
      asyncUpdateEpisode(episode.id, nextData);
    }
  });

  const asyncCreateEpisode = async (data:any) => {
    try {
      if ( data.author?.name ){
        const validatedAuthor = await dispatch(validateAuthorAndType({ authorName: data.author.name, authorType: data.type })).unwrap();
        data['author'] = validatedAuthor;
        data['authorId'] = validatedAuthor.id;
      }

      const episode = await dispatch(createEpisode(data)).unwrap();
      if ( isParentEpisodeCreation ){
        asyncPatchPage(episode.id, episode.date, episode.time);
      }
      asyncCreateFacts(episode.id);

      if ( onClose ) onClose();

      onCreate(episode);
    } catch(error){}
  }

  const asyncUpdateEpisode = async (episodeId:number, data:any) => {
    try {
      if ( data.author?.name ){
        const validatedAuthor = await dispatch(validateAuthorAndType({ authorName: data.author.name, authorType: data.type })).unwrap();
        data['author'] = validatedAuthor;
        data['authorId'] = validatedAuthor.id;
      }

      const episode = await dispatch(updateEpisode({ episodeId, data })).unwrap();
      if ( episodeId === page.parentEpisodeId ){
        asyncPatchPage(episodeId, episode.date, episode.time);
      }

      onUpdate(episode);
    } catch(error){}
  }

  const asyncCreateFacts = async (episodeId:number) => {
    if ( !episodeFactsWithNegativeId || !episodeFactsWithNegativeId.length ) return;

    try {
      for ( let fact of episodeFactsWithNegativeId ){
        await dispatch(createFact({ ...fact, episodeId })).unwrap();
      }
      dispatch(EpisodeFactsActions.clearFactsWithNegativeId());
    } catch(error){}
  }

  // ToDO
  const asyncPatchPage = async (parentEpisodeId:number | undefined, date:string | undefined, time:string | undefined) => {
    // Patch page
    const nextPageData:any = {
      version: page.version,
      parentEpisodeId
    };
    if ( page.inWorkspace ){
      nextPageData['workspaceOrder'] = generateWorkspaceOrder(null, {
        workspaceOrder: page.workspaceOrder,
        date,
        time
      });
    }
    return new Promise((resolve) => {
      dispatch(PageActions.patchPage(page.documentId, page.pageNum, nextPageData));
      setTimeout(() => {
        resolve(`Page updated`)
      }, 500);
    });
  }

  useEffect(() => {
    if ( statusCode === 409 ){
      toggle();

      if ( episode && episode.id ){
        dispatch(MixpanelActions.handleCaseEpisodeUpdateConflict({ episodeId: episode.id }));
      }
    }
    // eslint-disable-next-line
  }, [statusCode]);

  const handleClick = () => {
    if ( typeof onClose !== 'undefined' ){
      onClose();
    } else {
      dispatch(EpisodesActions.setSelectedEpisodeId(null));
    }
    dispatch(EpisodeFactsActions.clearFactsWithNegativeId());
  }

  const handleCancel = () => {
    if ( open ) toggle();

    dispatch(EpisodesActions.setStatusError({
      statusCode: null,
      statusMessage: null
    }));
  }

  const onConfirm = async (data:IFormData) => {
    const nextData = generateEpisodeData(data);

    if ( !episode || !episode.id ) return;

    try {
      const { version } = await dispatch(getEpisode(episode.id)).unwrap();
      nextData['version'] = version;
      await asyncUpdateEpisode(episode.id, nextData);

      dispatch(EpisodesActions.setStatusError({
        statusCode: null,
        statusMessage: null
      }));

      if ( open ) toggle();
    } catch(error){}
  }

  const watchAuthor = watch('author');
  const watchType = watch('type');
  const watchDate = watch('date');

  const myRolePresenterOrClient = myUser.role === UserRoles.Presenter || myUser.role === UserRoles.Client;

  const isDisabled = Boolean((myRolePresenterOrClient || trialMode) || isArchived || (page.staple && page.staple.order !== 1));
  const isAnyRequired = !watchAuthor && !watchType && !watchDate;

  return (
    <Fragment>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <input {...register('labels') as any} type="hidden" />
          <input {...register('authorId') as any} type="hidden" />

          <EpisodeFormLabels episodeId={episode?.id} episodeVersion={episode?.version} isDisabled={isDisabled} />
          <EpisodeFormFacts isDisabled={isDisabled} />
          <EpisodeFormDates isDisabled={isDisabled} />
          <EpisodeFormAuthorType isDisabled={isDisabled} />
          {isSubmitted && isAnyRequired ? (
            <FormHelperText sx={{ mb: 2 }} error={true}>{t('components.pageDetailsEpisodes.shouldBeSpecified')}</FormHelperText>
          ) : null}
          <EpisodeFormDateTime isDisabled={isDisabled} />
          <EpisodeFormPageNum pagesNums={pagesNums} isDisabled={isDisabled} />
          <EpisodeFormNotes episodeId={episode?.id} isDisabled={isDisabled} />
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
            <EpisodeFormTogglers isDisabled={isDisabled} />
            {!isDisabled ? (
              <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                <Button
                  name={isEpisodeCreation ? 'Cancel create episode form dialog' : 'Cancel save episode form'}
                  color="primary"
                  size="small"
                  onClick={handleClick}
                >{t('labels.close')}</Button>
                <LoadingButton
                  loading={loading}
                  name={isEpisodeCreation ? 'Create episode form dialog' : 'Save episode form'}
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="small"
                  // disabled={authorsIsFetching}
                >{isEpisodeCreation ? t('components.pageDetailsEpisodes.createEpisode') : t('components.pageDetailsEpisodes.saveEpisode')}</LoadingButton>
              </Box>
            ) : null}
          </Box>
        </form>
      </FormProvider>
      {open ? (
        <ConfirmationDialog
          open={open}
          onClose={handleCancel}
          title={t('dialogs.episodeForm.confirmationDialogTitle')}
          content={
            <Fragment>
              {statusMessage ? (
                <Alert sx={{ mb: 4 }} color="info">{statusMessage}</Alert>
              ) : null}
              <Typography variant="body1">{t('dialogs.episodeForm.confirmationDialogContent')}</Typography>
            </Fragment>
          }
          loading={loading}
          onConfirm={handleSubmit(onConfirm)}
          confirmLabel={t('dialogs.episodeForm.confirmationDialogConfirmButton')}
          closeLabel={t('dialogs.episodeForm.confirmationDialogCloseButton')}
        />
      ) : null}
    </Fragment>
  )
}

export default EpisodeForm;
