import { forwardRef, useState, useEffect, ReactNode, HTMLAttributes } from 'react';
// Models
import IOption from 'app/models/Option';
// Mui
import {
  Autocomplete as MuiAutocomplete,
  TextField, TextFieldProps
} from '@mui/material';
// Icons
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';

type Props = TextFieldProps & {
  loading?: boolean;
  options: IOption[];
  onChange: (nextValue:number | string | null) => void;
  onInputChange?: (newValue:string) => void;
  renderOptions?: (props:HTMLAttributes<HTMLLIElement>, option:IOption) => ReactNode;
};

const Autocomplete = forwardRef<any, Props>(({
  loading = false, value, options, onChange, onInputChange, renderOptions = (props, option) => (
    <li {...props} key={option.id}>
      {option.props?.verified ? (
        <CheckCircleIcon sx={{ mr: 2 }} color="success" />
      ) : null}
      {option.name}
    </li>
  ), ...props
}, ref) => {
  const [ selectedOption, setSelectedOption ] = useState<IOption | null>(null);

  useEffect(() => {
    return () => {
      setSelectedOption(null);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( value && options ){
      const foundedOption:IOption | undefined = options.find((option:IOption) => option.id === value);
      setSelectedOption(foundedOption || null);
    } else {
      setSelectedOption(null);
    }
    // eslint-disable-next-line
  }, [value, options]);

  const handleChange = (_:any, nextOption:IOption | null) => {
    setSelectedOption(nextOption);
    onChange(nextOption?.id || null);
  }

  const handleInputChange = (_:any, newValue:string) => {
    if ( !onInputChange ) return;
    onInputChange(newValue);
  }

  return (
    <MuiAutocomplete
      ref={ref}
      value={selectedOption}
      loading={loading}
      options={options}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          fullWidth
          margin={props.margin || 'normal'}
        />
      )}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={renderOptions}
      freeSolo={false}
      fullWidth
    />
  )
});

export default Autocomplete;
