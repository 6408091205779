import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
import { SubscriptionStatus, UsageType } from 'app/store/Subscriptions/Subscriptions.types';
// Models
import { RootState } from 'app/store';
// Selectors
import { ISubscription, ISubscriptionItem, IService } from '../Subscriptions/Subscriptions.models';
// Utils
import { sortByCreationDate } from 'app/utilities/SortBy';

export const selectSubscriptions = (state:RootState) => state[Reducers.BillingSubscriptions].subscriptions;
export const selectFilter = (state:RootState) => state[Reducers.BillingSubscriptions].filter;

export const selectFilteredSubscriptions = createSelector(
  [
    selectSubscriptions,
    selectFilter
  ],
  ( subscriptions:ISubscription[], { status }) => {
    const sortedSubscriptions = [...subscriptions].sort(sortByCreationDate);
    if ( status === 'all' ) return sortedSubscriptions;
    return sortedSubscriptions.filter((subscription) => subscription.status === status);
  }
);

export const selectSubscriptionsWithAdditionalItems = createSelector(
  [ selectSubscriptions ],
  ( subscriptions:ISubscription[] ) => {
    return subscriptions
      .map((subscription) => {
        const items = subscription.status === SubscriptionStatus.Active
          ? (subscription.items || []).reduce((acc:ISubscriptionItem[], cur) => {
              if ( cur.additional && cur.usageType !== UsageType.Licensed ) acc.push(cur);
              return acc;
            }, [])
          : []
        ;
        return { ...subscription, items };
      }).filter((subscription) => (subscription.items || []).length > 0)
  }
);

export const selectHasSubscriptionWithAdditionalItems = createSelector(
  [ selectSubscriptionsWithAdditionalItems ],
  (subscriptions:ISubscription[]) => subscriptions.length > 0
);

export const selectSubscriptionItemWithCPTCodes = createSelector(
  [ selectSubscriptions ],
  ( subscriptions:ISubscription[] ) => {
    let subscriptionItem:ISubscriptionItem | undefined;
    for ( let subscription of subscriptions ){
      if ( subscription.status !== SubscriptionStatus.Active ) continue;
      // We do not allow to keep same service code in diferent subscriptions
      for ( let item of (subscription.items || []) ){
        if ( item.service.code === 'cptCodesAccess' ){
          subscriptionItem = item;
          break;
        }
      }
      if ( subscriptionItem ) break;
    }
    return subscriptionItem;
  }
);

export const selectServiceEntities = createSelector(
  [ selectSubscriptions ],
  ( subscriptions:ISubscription[] ) => {
    return subscriptions.reduce((acc:Record<number, IService>, cur) => {
      if ( (cur.items || []).length > 0 ){
        for ( let item of cur.items ){
          if ( !item.service || acc[item.service.id] ) continue;
          acc[item.service.id] = item.service;
        }
      }
      return acc;
    }, {})
  }
);

export const selectActiveSubscriptionServicesAsOptions = createSelector(
  [ selectSubscriptions ],
  ( subscriptions:ISubscription[] ) => {
    const activeSubscriptions = subscriptions.filter((subscription) => subscription.status === SubscriptionStatus.Active);

    const serviceEntities = activeSubscriptions.reduce((acc:Record<number, IService>, cur) => {
      if ( (cur.items || []).length > 0 ){
        for ( let item of cur.items ){
          if ( !item.service || acc[item.service.id] ) continue;
          acc[item.service.id] = item.service;
        }
      }
      return acc;
    }, {});

    return Object.keys(serviceEntities).map((key) => {
      const serviceEntity = serviceEntities[Number(key)];
      return ({ value: serviceEntity.id, label: serviceEntity.name })
    })
  }
);

export const selectActiveSubscriptionsAsOptions = createSelector(
  [ selectSubscriptions ],
  ( subscriptions:ISubscription[] ) => {
    return subscriptions
      .filter((subscription) => subscription.status === SubscriptionStatus.Active)
      .map((subscription) => ({ value: subscription.id, label: subscription.subscriptionPackage.name }))
  }
);
