import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import { ISubscription } from "app/store/Subscriptions/Subscriptions.models";
// Async
import { getSubscriptions } from './BillingSubscriptions.async';
import { SubscriptionStatus } from "../Subscriptions/Subscriptions.types";

interface IState {
  subscriptions: ISubscription[];
  filter: {
    status: SubscriptionStatus | 'all'
  }
};

const initialState:IState = {
  subscriptions: [],
  filter: {
    status: SubscriptionStatus.Active
  }
};

const slice = createSlice({
  name: Reducers.BillingSubscriptions,
  initialState,
  reducers: {
    setFilter: (state, action:PayloadAction<any>) => {
      const filter = action.payload;
      state.filter = Object.keys(state.filter).reduce((acc:any, cur:any) => {
        acc[cur] = (state.filter as any)[cur];
        if ( typeof filter[cur] !== 'undefined' ) acc[cur] = filter[cur];
        return acc;
      }, {});
    },
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers(builder) {
    // Get subscriptions
    builder.addCase(getSubscriptions.pending, (state) => {
      state.subscriptions = [];
    });
    builder.addCase(getSubscriptions.fulfilled, (state, action:PayloadAction<ISubscription[]>) => {
      state.subscriptions = action.payload;
    });
  },
});

export const BillingSubscriptionsActions = slice.actions;

export default slice.reducer;
