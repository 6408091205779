import { ChangeEvent, FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Types
import { ContentType } from 'app/types/ContentItem';
// Models
import IOption from 'app/models/Option';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import MixpanelActions from 'app/features/mixpanel/MixpanelActions';
// Selectors
import { selectTemplates } from 'app/store/Templates/Templates.selectors';
import { selectPresetsAsOptions } from 'app/store/ReportPresets/ReportPresets.selectors';
// Mui
import { Theme, Box } from '@mui/material';
// Components
import { Select } from 'app/components/Mui';
// Context
import { useReportPresetContext } from 'app/context/ReportPreset.context';
import { generateContentItem } from 'app/utilities/ContentItemUtils';

const PresetSelector:FC = () => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const templates = useAppSelector(selectTemplates);
  const presetsOptions = useAppSelector(selectPresetsAsOptions);

  const { setValue } = useFormContext();

  const { caseId, onPresentContent } = useReportPresetContext();

  const [ presetId, setPresetId ] = useState<string>('0');

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    if ( !presetsOptions || !templates ) return;

    const { value } = event.target;
    const valueToNum = Number(value);

    const foundedOption = presetsOptions.find((option:IOption) => option.id === valueToNum);
    const templateIds = !foundedOption ? [] : foundedOption.props.templateIds;
    const headerTemplateId = foundedOption?.props.headerTemplateId
      ? foundedOption.props.headerTemplateId.toString()
      : null
    ;
    const footerTemplateId = foundedOption?.props.footerTemplateId
      ? foundedOption.props.footerTemplateId.toString()
      : null
    ;
    const presentContent = templateIds.reduce((acc:number[], cur:number) => {
      const foundedTemplate = templates.find((template) => template.globalTemplateId && template.globalTemplateId === cur);
      acc = [...acc, foundedTemplate ? foundedTemplate.id : cur];
      return acc;
    }, []).map((id:number) => generateContentItem(ContentType.Template, id));

    onPresentContent(presentContent)

    setPresetId(value);

    if ( headerTemplateId ) setValue('headerTemplateId', headerTemplateId);
    if ( footerTemplateId ) setValue('footerTemplateId', footerTemplateId);

    dispatch(MixpanelActions.handleReportPresetSelected({ presetId: valueToNum }));
  }

  if ( !caseId ) return null;
  return (
    <Box sx={{
      borderBottom: (theme:Theme) => `1px solid ${theme.palette.divider}`,
      py: 4,
      px: 6
    }}>
      <Select
        label={t('labels.reportPresets')} name="presets" value={presetId}
        onChange={handleChange}
        options={[
          { id: 0, name: t('dialogs.reportPresetForm.blankReport') },
          ...(presetsOptions || [])
        ]}
        size="small"
        margin="none"
      />
    </Box>
  )
}

export default PresetSelector;
