import { FC, useMemo } from 'react';
// Types
import { Dimension } from 'app/store/ManagementBillingUsage/ManagementBillingUsage.types';
// Models
import { IUsage } from 'app/store/ManagementBillingUsage/ManagementBillingUsage.models';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Async
import { getUsage } from 'app/store/ManagementBillingUsage/ManagementBillingUsage.async';
// Selectors
import { selectUsages, selectParams, selectValidateDateRange } from 'app/store/ManagementBillingUsage/ManagementBillingUsage.selectors';
// Mui
import { Box, Toolbar, Typography } from '@mui/material';
// Components
import DataLoader from 'app/components/DataLoader';
// 
import CostExplorerDrawer from './CostExplorerDrawer';
import UsageGroupByService from './UsageGroupByService';
import UsageGroupByAccount from './UsageGroupByAccount';
import UsageGroupBySubscriptionPackage from './UsageGroupBySubscriptionPackage';
import UsageNoDataFound from './UsageNoDataFound';

const CostExplorerPage:FC = () => {
  // State
  const params = useAppSelector(selectParams);
  const validationErrors = useAppSelector(selectValidateDateRange);

  const formattedParams = useMemo(() => {
    const formattedStart = params['period.start'] ? params['period.start'].format('YYYY-MM-DD') : null;
    const formattedEnd = params['period.end'] ? params['period.end'].add(1, 'day').format('YYYY-MM-DD') : null;
    return {
      ...params,
      'period.start': formattedStart,
      'period.end': formattedEnd
    };
  }, [params]);

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
      <CostExplorerDrawer />
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden' }}>
        <Toolbar variant="dense">
          <Typography variant="h6">Cost Explorer</Typography>
        </Toolbar>
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 4, flexGrow: 1, overflow: 'hidden' }} react-action="scroll">
          <DataLoader
            asyncThunkFn={validationErrors ? undefined : getUsage}
            params={formattedParams}
            selector={selectUsages}
            noData={<UsageNoDataFound />}
          >
            {( data ) => (
              (() => {
                switch (params.dimensions) {
                  case Dimension.Service:
                    return <UsageGroupByService data={data as IUsage[]} />
                  case Dimension.Account:
                    return <UsageGroupByAccount data={data as IUsage[]} />
                  default:
                    return <UsageGroupBySubscriptionPackage data={data as IUsage[]} />
                }
              })()
            )}
          </DataLoader>
        </Box>
      </Box>
    </Box>
  )
}

export default CostExplorerPage;
