import CaseEvents from './Case.events';
import TemplateEvents from './Template.events';
import ReportPresetEvents from './ReportPreset.events';
import AIChatEvents from './AIChat.events';

const MixpanelEvents = {
  ...CaseEvents,
  ...TemplateEvents,
  ...ReportPresetEvents,
  ...AIChatEvents
};

export default MixpanelEvents;
