import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import IOption from 'app/models/Option';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { SubscriptionsActions } from 'app/store/Subscriptions/Subscriptions.slice';
// Selectors
import { selectAccountsAsOptions } from 'app/store/AccountsManagement/AccountsManagement.selectors';
import { selectPackagesAsOptions } from 'app/store/BillingPackages/BillingPackages.selectors';
import { selectParams } from 'app/store/Subscriptions/Subscriptions.selectors';
// Mui
import { Box, Typography } from '@mui/material';
// Components
import { Autocomplete } from 'app/components/Autocomplete';
import { Select } from 'app/components/Mui';

const SubscriptionsFilter:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const accountOptions:IOption[] | null = useAppSelector(selectAccountsAsOptions);
  const packageOptions:IOption[] | null = useAppSelector(selectPackagesAsOptions);
  const params = useAppSelector(selectParams);

  const handleChange = (field:keyof typeof params, value:string) => {
    dispatch(SubscriptionsActions.setParams({ [field]: value }));
  }

  return (
    <Box sx={{ display: 'flex', gap: 4 }}>
      <Autocomplete
        label="Company account" value={params.accountId}
        onChange={(newValue) => handleChange('accountId', newValue as string)}
        options={accountOptions || []}
        renderOptions={(props, option) => (
          <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.id}>
            <Typography variant="subtitle1">{option.name}</Typography>
            {option.subname ? (
              <Typography variant="caption">{option.subname}</Typography>
            ) : null}
          </li>
        )}
        margin="none"
        size="small"
      />
      <Autocomplete
        label="Subscription package" value={params.subscriptionPackageId}
        onChange={(newValue) => handleChange('subscriptionPackageId', newValue as string)}
        options={packageOptions || []}
        renderOptions={(props, option) => (
          <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.id}>
            <Typography variant="subtitle1">{option.name}</Typography>
            {option.subname ? (
              <Typography variant="caption">{option.subname}</Typography>
            ) : null}
          </li>
        )}
        margin="none"
        size="small"
      />
      <Select
        label={t('labels.status')} name="status" value={params.status}
        onChange={(event:ChangeEvent<HTMLInputElement>) => handleChange('status', event.target.value)}
        options={[
          { id: 'all', name: t('labels.all') },
          { id: 'active', name: 'Active' },
          { id: 'inactive', name: 'Inactive' },
        ]}
        margin="none"
        size="small"
      />
    </Box>
  )
}

export default SubscriptionsFilter;