import { FC, Fragment, useEffect, useState } from 'react';
// Models
import IInsuranceCase from 'app/models/Case';
// Types
import { AuditOperations } from 'app/store/Audit/Audit.types';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getInsuranceCase, patchInsuranceCase } from 'app/store/Cases/Cases.async';
import { createAuditRecord } from 'app/store/Audit/Audit.async';
// Actions
import { CasesActions } from 'app/store/Cases/Cases.slice';
// Selectors
import { setCloudfrontCookies } from 'app/store/Content/Сontent.async';
import { selectInsuranceCase, selectLoading } from 'app/store/Cases/Cases.selectors';
import { selectMyUser } from 'app/store/Users/Users.selectors';
// Components
import Dialog from 'app/components/Dialog';
import QuillEditor from 'app/components/QuillEditor';
import { Button, LoadingButton } from 'app/components/Mui';
import { Loader } from 'app/components/Utilities';
// i18next
import { useTranslation } from 'react-i18next';
import CasePushAlert from 'app/components/CasePushAlert';

type Props = {
  open: boolean;
  onClose: () => void;
  insuranceCaseId?: number;
};

const CaseNotesDialog:FC<Props> = ({
  // Props
  open, onClose, insuranceCaseId
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser = useAppSelector(selectMyUser);
  const insuranceCase:IInsuranceCase | null = useAppSelector(selectInsuranceCase);
  const loading = useAppSelector(selectLoading);

  const [ notes, setNotes ] = useState('');

  useEffect(() => {
    if ( insuranceCaseId ){
      dispatch(getInsuranceCase(insuranceCaseId));
      dispatch(setCloudfrontCookies({}));
    }

    return () => {
      if ( insuranceCaseId ){
        dispatch(CasesActions.setInitialField('insuranceCase'));
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( insuranceCase && insuranceCase.notes ) setNotes(insuranceCase.notes);
    // eslint-disable-next-line
  }, [insuranceCase]);

  const handleChange = (nextValue:string) => {
    setNotes(nextValue);
  }

  const handleSave = async (closeDialog = false) => {
    if ( !insuranceCase ) return;

    try {
      const nextData = { notes, version: insuranceCase.version };
      await dispatch(patchInsuranceCase({ insuranceCaseId: insuranceCase.id, data: nextData })).unwrap();

      await dispatch(createAuditRecord({
        operation: AuditOperations.CaseNotesUpdate,
        data: {
          caseId: insuranceCase.id,
          userName: myUser?.name || ' '
        }
      })).unwrap();

      if ( closeDialog ) onClose();
    } catch (error) {}
  }
  
  const isDisabled = (!insuranceCase?.notes && !notes) || insuranceCase?.notes === notes;

  const actions = (
    <Fragment>
      <Button
        name="Cancel case notes dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name="Save case notes dialog"
        onClick={() => handleSave()}
        loading={loading}
        variant="contained"
        color="primary"
        disabled={isDisabled}
      >{t('labels.save')}</LoadingButton>
      <LoadingButton
        name="Save and close case notes dialog"
        onClick={() => handleSave(true)}
        loading={loading}
        variant="contained"
        color="primary"
        disabled={isDisabled}
      >{t('labels.saveAndClose')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      title={t('dialogs.caseNotes.caseNotes', { caseName: insuranceCase?.name })}
      actions={actions}
    >
      <CasePushAlert caseId={insuranceCaseId || insuranceCase?.id} type="notes" />
      {!insuranceCase ? (
        <Loader />
      ) : (
        <Fragment>
          {/* Notes */}
          <QuillEditor
            id="case-notes-quill-editor"
            label={t('labels.notes')} name="notes" value={notes}
            onChange={handleChange}
            height={480}
          />
        </Fragment>
      )}
    </Dialog>
  )
}

export default CaseNotesDialog;