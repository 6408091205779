import { FC, Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
// Types
import { StepTasks, StepTaskLabels } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.types';
// Models
import { IWorkflowStep } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.models';
// Mui
import { Theme, Box, Typography } from '@mui/material';
// Utilities
import { isRequired, isMin, isMax, isEmailValid, isMaxLength } from 'app/utilities/Validations';
// 
import { useWorkflowContext } from './Workflow.context';

type Props = {
  step: IWorkflowStep;
}

const StepBlock:FC<Props> = ({
  // Props
  step
}) => {
  const { register, formState:{ errors } } = useFormContext();
  const { selectedStep, onSelectStep, getStepFieldIndex } = useWorkflowContext();

  const fieldIndex = getStepFieldIndex(step.id);

  const handleClick = () => {
    onSelectStep(step);
  }

  // As on submit validation of hidden field not work
  // we should add hidden input with validation rules
  const renderInputsWithRules = () => {
    switch (step.task) {
      case StepTasks.RunPrompt:
        return (
          <Fragment>
            <input {...register(`steps[${fieldIndex}].input.parameters.promptId`, {
              // We used `isRequired` not `true` to throw an message
              required: isRequired
            })} type="hidden" />
            <input {...register(`steps[${fieldIndex}].input.parameters.coverage`, {
              min: isMin(0),
              max: isMax(100)
            })} type="hidden" />
          </Fragment>
        );
      case StepTasks.CreateDocument:
        return (
          <input {...register(`steps[${fieldIndex}].input.parameters.collectionId`, {
            required: isRequired
          })} type="hidden" />
        );
      case StepTasks.CreateReport:
        return (
          <input {...register(`steps[${fieldIndex}].input.parameters.watermark.text`, {
            maxLength: isMaxLength(21)
          })} type="hidden" />
        );
      case StepTasks.SendEmail:
        return (
          <input {...register(`steps[${fieldIndex}].input.parameters.to`, {
            required: isRequired,
            pattern: isEmailValid

          })} type="hidden" />
        );
      default:
        break;
    }
  };

  const isStepSelected = selectedStep && selectedStep.id === step.id;
  const isStepInvalid = errors.steps && (errors.steps as any)[fieldIndex];

  return (
    <Fragment>
      {renderInputsWithRules()}
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: 'white',
          borderRadius: 1,
          p: 4,
          '&:hover': {
            bgcolor: (theme:Theme) => theme.palette.grey[100],
            cursor: 'pointer'
          },
          '&::before': {
            content: '""',
            borderRadius: 1,
            border: (theme:Theme) => `2px solid ${
              isStepSelected
              ? theme.palette.primary.main
              : isStepInvalid
                ? theme.palette.error.main
                : 'transparent'
            }`,
            boxShadow: (theme:Theme) => `0 0 4px ${
              isStepSelected
              ? theme.palette.primary.main
              : isStepInvalid
                ? theme.palette.error.main
                : 'transparent'
            }`,
            position: 'absolute',
            top: 0, left: 0, right: 0, bottom: 0,
            pointerEvents: 'none'
          }
        }}
        onClick={handleClick}
      ><Typography variant="body1">{StepTaskLabels[step.task]}</Typography></Box>
    </Fragment>
  )
}

export default StepBlock;
