import { FC, Fragment } from 'react';
// Models
import { ISubscription } from 'app/store/Subscriptions/Subscriptions.models';
// Mui
import {
  Theme,
  Paper, Box, Typography, Divider,
  Table, TableRow, TableCell, TableBody,
  TableHead
} from '@mui/material';
// Components
import { InfoBlock } from 'app/components/Utilities';
// Hooks
import useLongDateTme from 'app/hooks/useLongDateTime';
// Utils
import { toNumberWithCommas, statusThemeColor } from 'app/utilities/Utilities';

type Props = {
  subscription: ISubscription;
}

const SubscriptionItem:FC<Props> = ({
  // Props
  subscription
}) => {
  const { subscriptionPackage } = subscription;

  const currentPeriodEnd = useLongDateTme({
    dateValue: subscription.currentPeriodEnd,
    local: true
  });

  const createdOn = useLongDateTme({
    dateValue: subscription.createdOn,
    local: true
  });

  return (
    <Paper sx={{ p: 4 }} variant="outlined">
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <Typography
          sx={{ flexGrow: 1 }}
          variant="subtitle1"
        >{subscriptionPackage.name}</Typography>
        <Typography
          variant="body2"
          sx={{
            textTransform: 'capitalize',
            color: (theme:Theme) => statusThemeColor(subscription.status, theme)
          }}
        >{subscription.status}</Typography>
      </Box>
      {subscriptionPackage.description ? (
        <Typography color="CaptionText" variant="body2">{subscriptionPackage.description}</Typography>
      ) : null}
      {createdOn || currentPeriodEnd ? (
        <Fragment>
          <Divider sx={{ borderColor: (theme:Theme) => theme.palette.divider, my: 4 }} />
          <Box sx={{ display: 'flex', gap: 4 }}>
            {createdOn ? (
              <InfoBlock label="Created On" value={createdOn} />
            ) : null}
            {currentPeriodEnd ? (
              <InfoBlock label="Next Bill Date" value={currentPeriodEnd} />
            ) : null}
          </Box>
        </Fragment>
      ) : null}
      {subscription.items && subscription.items.length !== 0 ? (
        <Table sx={{
          // bgcolor: (theme:Theme) => theme.palette.primary.light,
          // border: (theme:Theme) => `1px solid ${theme.palette.divider}`,
          mt: 4
        }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell sx={{ width: '20%' }} align="right">QTY</TableCell>
              <TableCell sx={{ width: '20%' }} align="right">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscription.items.map((subscriptionItem, index) => (
              <TableRow key={`subscriptionItem-${index}-${subscription.id}`}>
                <TableCell>{subscriptionItem.service.name}</TableCell>
                <TableCell align="right">{typeof subscriptionItem.quantity === 'undefined' ? '-' : subscriptionItem.quantity}</TableCell>
                <TableCell align="right">{`$${toNumberWithCommas(subscriptionItem.price)}`}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : null}
    </Paper>
  )
}

export default SubscriptionItem;