import { FC, ReactNode, createContext, useContext, useCallback, useMemo, useState } from 'react';
// Types
import FileTypes from "app/types/FileTypes";
import FileFormats from "app/types/FileFormats";
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectFilesErrors } from "app/store/DMSBatches/DMSBatches.selectors";
// Actions
import { DMSUploadsActions } from 'app/store/DMSUploads/DMSUploads.slice';
// 
import useUploadFilesUtils from './useUploadFilesUtils';

export interface IFileItem {
  id: string;
  file: File;
  type: FileTypes;
  fileFormat: FileFormats;
  fileError: string | null;
  comment: string;
  collectionId?: number;
};

type ContextType = {
  fileItems: IFileItem[];
  onAddFileItem: (file:File) => void;
  onRemoveFileItem: (fileItemId:string) => void;
  onUpdateFileItem: (fileItemId:string, data:any) => void;
  updateCollectionsInFileItems: (fileFormat:FileFormats, collectionId:number) => void;

  sortedFileItems: IFileItem[];
}

const Context = createContext<ContextType | undefined>(undefined);

type Props = {
  isSubmitted: boolean;
  children: ReactNode;
};

const UploadFilesProvider:FC<Props> = ({
  // Props
  isSubmitted, children
}) => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const filesErrors = useAppSelector(selectFilesErrors);

  const { generateFileItem } = useUploadFilesUtils();

  const [ fileItems, setFileItems ] = useState<IFileItem[]>([]);

  const getFileError = useCallback((fileError:any) => {
    const errorMap: Record<string, string> = {
      'sameName': 'Document with same document name already selected',
      'sameChecksum': `Same document is already selected as ${fileError.duplicateFileIndexes.map((i: number) => {
        const fileItem = fileItems[i];
        if (!fileItem || !fileItem.file) return '';
        return fileItem.file.name;
      }).filter((name: string) => name).join(', ')}`
    };
    return errorMap[fileError.errorType] || fileError.errorMessage || 'Undefined error';
    // eslint-disable-next-line
  }, [filesErrors, fileItems]);

  const fileErrorMap = useMemo(() => {
    if ( !filesErrors ) return {};
    return fileItems.reduce((acc:Record<string, string>, cur:IFileItem, index:number) => {
      const fileError = filesErrors[index];
      if ( fileError ){
        acc[cur.id] = getFileError(fileError);
      }
      return acc;
    }, {});
    // eslint-disable-next-line
  }, [filesErrors]);

  const sortedFileItems = useMemo(() => {
    if ( !isSubmitted ) return fileItems;

    const encountered: Record<string, number[]> = {};
    const copyFileItems = [...fileItems];
    return copyFileItems.map((fileItem, index) => {
      const { file } = fileItem;
      if ( encountered[file.name] ){
        encountered[file.name].push(index);
        const duplicateFileIndexes = encountered[file.name].filter((idx) => idx !== index);
        fileItem.fileError = `Duplicate file name found. Also selected: ${duplicateFileIndexes.map(i => fileItems[i].file.name).join(', ')}`;
      } else {
        encountered[file.name] = [index];
      }
      const fileError = fileErrorMap[fileItem.id];
      if ( fileError ) return {...fileItem, fileError };
      return fileItem;
    }).sort((a, b) => {
      if ( b.fileError && !a.fileError ) return 1;
      if ( !b.fileError && a.fileError ) return -1;
      if ( b.collectionId && !a.collectionId ) return -1;
      if ( !b.collectionId && a.collectionId ) return 1;
      return 0;
    });
  }, [isSubmitted, fileItems, fileErrorMap]);

  const onAddFileItem = useCallback((file:File) => {
    setFileItems((prevState) => {
      const fileItem = generateFileItem(file, prevState); // use prevState here to get the most recent state
      if ( !fileItem ) return prevState;
      return [...prevState, fileItem];
    });
  }, [generateFileItem]);

  const onRemoveFileItem = useCallback((fileItemId:string) => {
    setFileItems((prevFiles) => prevFiles.filter((fileItem) => fileItem.id !== fileItemId));
    dispatch(DMSUploadsActions.removePreSignedUrl(fileItemId));
    // eslint-disable-next-line
  }, []);

  const onUpdateFileItem = useCallback((fileItemId:string, data:any) => {
    setFileItems((prevState) => prevState.map((fileItem) => (
      fileItem.id === fileItemId ? { ...fileItem, ...data } : fileItem
    )));
  }, []);

  const updateCollectionsInFileItems = useCallback((fileFormat:FileFormats, collectionId:number) => {
    setFileItems((prevState) => prevState.map((fileItem) => {
      if ( !fileItem.collectionId && fileItem.fileFormat === fileFormat ) {
        return { ...fileItem, collectionId };
      }
      return fileItem;
    }));
  }, []);

  return (
    <Context.Provider value={{
      fileItems,
      onAddFileItem,
      onRemoveFileItem,
      onUpdateFileItem,
      updateCollectionsInFileItems,
      sortedFileItems
    }}>{children}</Context.Provider>
  )
}

export default UploadFilesProvider;

export const useUploadFilesContext = ():ContextType => {
  const context = useContext(Context);
  if ( !context ) throw new Error('useUploadFilesContext must be used within a UploadFilesProvider');
  return context;
}
